const phoneNo = '+919930709000'
export const phoneNumber = "tel:" + phoneNo
export const whatsapp = "https://web.whatsapp.com/send?phone=" + phoneNo
export const googlePlay = "https://play.google.com/store/apps/details?id=com.zenonco.cancer"
export const appStore = "https://apps.apple.com/in/app/zenonco-io-cancer-care/id1625128857"
export const twitter = "https://twitter.com/ZenOncoIO"
export const instagram = "https://www.instagram.com/zenoncoio/"
export const facebook = "https://www.facebook.com/ZenOnco.ioOfficial"
export const linkedin = "https://in.linkedin.com/company/zenonco-io"
export const youtube = "https://www.youtube.com/c/ZenOncoIo"
export const deepLinkAPP = "https://zenco.page.link/5wHg"