import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  DoctorTalkFilterlModel } from "../../models/story-models";

const initialDoctorTalkFilterState: DoctorTalkFilterlModel = {
    status: 0,
    cancer_category: [],
    message: '',
    topic_list: [],
    cancer_type: [],
    metadatas: [] 
}

const doctorTalkFilterSlice = createSlice({
    name: 'doctorTalkFilter',
    initialState: initialDoctorTalkFilterState,
    reducers: {
        setSDoctorTalkFilterData(state, action: PayloadAction<DoctorTalkFilterlModel>) {
            state.status = action.payload?.status;
            state.cancer_category = action.payload?.cancer_category;
            state.message = action.payload?.message;
            state.topic_list = action.payload?.topic_list;
            state.cancer_type = action.payload?.cancer_type;
            state.metadatas = action.payload?.metadatas;
        }
    }
})
export default doctorTalkFilterSlice;