import React, { Fragment } from "react";
import { Box, Button, Radio, Typography, Menu, MenuItem } from "@mui/material";
import "./addressBar.css";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppDispatch, } from "../../../hooks/redux-hooks";
import { deleteAddress, getAddress } from "../../../store/product/product-actions";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { productAddressPage } from "../../../values/paths";


export default function AddressBar(Props: { translation: any, datas?: any; handleRadio?: any; selectedId?: any, totalQuantity?: any, totalPrice?: any }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useAppDispatch();
    const cookies = new Cookies();
    const userID = cookies.get('userId')
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    function handleClose() {
        setAnchorEl(null);
    };
    function handleDelete() {
        if (userID !== undefined) {
            let data = {
                userId: userID,
                id: Props?.datas?.id
            }
            dispatch(deleteAddress(data));
            handleClose()
            setTimeout(() => {
                dispatch(getAddress(userID));
            }, 1000)
        }
    }
    return (
        <Fragment>
            <Box className="AddressBar-mainBox">
                <Radio
                    checked={Props?.datas.id.toString() === Props.selectedId ? true : false}
                    onClick={Props.handleRadio}
                    value={Props?.datas.id}
                    className="bar-RadioBtn" color="primary" />
                <Box className="redioTo-MenuBox">
                    <Typography className="Bar-name-Numbertxt">
                        {Props.datas?.first_name} {Props.datas?.last_name}
                    </Typography>
                    <Typography className="bar-Address-txt">
                        {Props.datas?.address}
                    </Typography>
                </Box>
                <Button
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className="Bar-menuBtn">
                    <MoreVertIcon fontSize="small" />
                </Button>
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <MenuItem>
                    <Link to={productAddressPage} state={{
                        info: Props.datas,
                        edit: true,
                        totalQuantity: Props.totalQuantity,
                        totalPrice: Props.totalPrice
                    }} className="EditNavigate">
                        {Props?.translation?.Edit}
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleDelete}>{Props?.translation?.Delete} </MenuItem>
            </Menu>
        </Fragment>
    );
}