import React, { Fragment } from "react";
import { Box, Typography, Button, Divider, useMediaQuery } from "@mui/material";
import "./productListView.css";
import AppImages from "../../../images/AppImages";
import Cookies from "universal-cookie";

export default function ProductListView(props: { translation: any, quantityCart?: any, setQuantityCart?: any, listData?: any, deleteData?: any, updateCartItem?: any }) {
    // const [quantity, setQuantity] = useState(props.listData?.qunatity);
    let totalPrice = props?.listData?.product_item?.sale_price * props.listData?.qunatity;
    const cookies = new Cookies();
    const userID = cookies.get('userId');
    const maxWidth700 = useMediaQuery("(min-width:700px)");


    const increaQuantity = () => {
        // props.setQuantityCart(props.quantityCart + 1);
        updateCart(props.listData?.qunatity + 1)
    }
    const updateCart = (quantity: number) => {
        if (userID !== undefined) {
            let data = {
                id: props?.listData?.id,
                userId: userID,
                product_id: props?.listData?.product_item?.id,
                qunatity: quantity,
                price: props?.listData?.product_item?.price,
                reguler_price: props?.listData?.product_item?.reguler_price,
                sale_price: props?.listData?.product_item?.price,
                total_price: totalPrice,
            }
            props?.updateCartItem(data);
        }
    }

    const decreasQuantity = () => {
        // props.setQuantityCart(props.quantityCart - 1);
        updateCart(props.listData?.qunatity - 1);
    }

    const deleteCartItem = (value: number) => {
        props?.deleteData(value)
    }
    return (
        <Fragment>
            <Box className="Main-ListViewBox">
                <Box className="List-name-imgBox">
                    <img src={props?.listData?.image} alt="" className="ListView-img" />
                    <Box className="nameAndQuantityBox">
                        <Typography className="ListHeadProduct-txt">
                            {props?.listData?.product_item?.product}
                        </Typography>
                        <Typography className="ListProduct-quantity-txt">
                            {props?.listData?.product_item?.productQuantity}
                        </Typography>
                    </Box>
                    {!maxWidth700 && <Button className="delete-mobile-Btn" disableRipple onClick={() => deleteCartItem(props?.listData?.productItemId)}>
                        <img src={AppImages.Delete} alt="x" className="list-view-DeletIcon" />
                    </Button>}
                </Box>
                <Box className="List-name-imgBox1">
                    <Typography className="ListD-PriceTxt">
                        {props?.translation?.Rs} {props?.listData?.product_item?.sale_price}
                    </Typography>
                    <Box className="Listquantity-btn-Box">
                        <Button disabled={props.listData?.qunatity < 2} className={props.listData?.qunatity > 1 ? "Listplus-minusBtn1" : "Listplus-minusBtn"} onClick={decreasQuantity}> - </Button>
                        <Typography className="quantityTotal-txt"> {props.listData?.qunatity} </Typography>
                        <Button className="Listplus-minusBtn1" onClick={increaQuantity}>
                            <Typography>+</Typography></Button>
                    </Box>
                    <Typography className="List-PriceTxt"> {props?.translation?.Rs} {props?.listData?.product_item?.sale_price * props.listData?.qunatity}</Typography>
                    {maxWidth700 && <Button disableRipple className="delete-mobile-Btn" onClick={() => deleteCartItem(props?.listData?.productItemId)}>
                        <img src={AppImages.Delete} alt="x" className="list-view-DeletIcon" />
                    </Button>}
                </Box>
            </Box>
            <Divider className="DVDList" />
        </Fragment>
    );
}