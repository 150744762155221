import React, { Fragment, memo, useState } from 'react'
import './healingJourney.css'
import { Avatar, Box, Typography } from '@mui/material'
import AppImages from '../../../images/AppImages'
import Cookies from 'universal-cookie';
import TreatmentModel from '../../Common-Component/Common-Modals/TreatmentModel/treatmentModel';

function HealingJounrney(Props: { homePage: any, handleAlert?: any, translation: any, loading: any }) {
  const [modal, setModal] = useState(false);
  const cookies = new Cookies();
  const [open, setOpen] = useState(false);

  function hadleCloseModal() {
    setModal(false);
  }
  const hadleOpenModal = () => {
    if (cookies.get('token') === undefined) {
      setModal(true);
    } else {
      setOpen(true)
      setTimeout(() => {
        setOpen(false);
      }, 2000)
    }
  }
  return (
    <Fragment>
      <Box className='hjourney-mainbox'>
        <Box>
          <img onClick={hadleOpenModal} className='hjourney-img' src={AppImages?.CardMobileImg} alt={Props?.translation?.HealingJourneys} loading="lazy" />
        </Box>
        <Box className='hjourney-subbox'>
          <Box className='hjourney-subbox1' >
            <Box className='hjourney-innerbox'>
              <Avatar className='hjourney-avatar' src={AppImages.PatientGood} alt='' />
              <Box>
                <Typography className='hjourney-fitythousand'>{Props?.translation?.FiftyThousand}</Typography>
                <Typography className='hjourney-desp'>{Props?.translation?.PatientsTreated}</Typography></Box></Box>
            <Box className='hjourney-innerbox'>
              <Avatar className='hjourney-avatar' src={AppImages.Community} alt='' />
              <Box className='onelakhs-box'>
                <Typography className='hjourney-fitythousand' >{Props?.translation?.oneLac}</Typography>
                <Typography className='hjourney-desp' >{Props?.translation?.CommunityMembers}</Typography>
              </Box></Box></Box>
          <Box className='hjourney-subbox1'>
            <Box className='hjourney-innerbox'>
              <Avatar src={AppImages.IncreaseLife} className='hjourney-avatar' alt='' />
              <Box>
                <Typography className='hjourney-fitythousand'>{Props?.translation?.Seventy}</Typography>
                <Typography className='hjourney-desp'>{Props?.translation?.IncreaseQuality}</Typography></Box></Box>
            <Box className='hjourney-innerbox'>
              <Avatar src={AppImages.Satisfaction} className='hjourney-avatar' alt='' />
              <Box>
                <Typography className='hjourney-fitythousand' >{Props?.translation?.NintyEight}</Typography>
                <Typography className='hjourney-satisfaction' >{Props?.translation?.Patientsatisfaction}</Typography>
              </Box></Box>
            {Props.loading && <TreatmentModel translation={Props?.translation} homePage={Props.homePage} handleAlert={Props.handleAlert} setModal={setModal}
              modal={modal} handleCloseModal={hadleCloseModal} />}
          </Box>
        </Box></Box></Fragment>
  );
}
export default memo(HealingJounrney)

