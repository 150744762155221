import {OTPModel} from "../../models/doctor-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialOTPState:OTPModel={
    status: 0,
    otp: '',
    message: '',
    data: { id : 0 },
}

const otpSlice=createSlice({
    name:'OTP',
    initialState:initialOTPState,
    reducers:{
        setOTPData(state,action:PayloadAction<OTPModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
            state.otp = action.payload?.otp;
        }
    }
})
export default otpSlice;