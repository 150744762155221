import React from "react";
import "./carouselButtons.css";
import { Button } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface carouselDetails {
    carouselState: any;
    lastItem: any;
    previous: any
    next: any;
}
export default function CarouselButton({ carouselState, lastItem, previous, next }: carouselDetails) {
    return (
        <div className="Survivorscardsbtn-Box">
            {carouselState?.currentSlide !== 0 && <Button aria-label='swipeLeft' disableRipple className="Survivorscardsbtn-prev" onClick={previous} >
                <NavigateBeforeIcon className="expertCButtonicon" />
            </Button>}
            {lastItem === null || lastItem ? <Button aria-label='swipeRight' disableRipple className="Survivorscardsbtn-next" onClick={next}>
                <NavigateNextIcon className="expertCButtonicon" />
            </Button> : null}
        </div>
    )
}