
const language = sessionStorage.getItem('language');
const languageConditions = language === null || language === ""
export const homePage = languageConditions ? "/" : `/${language}`
export const aboutPage = languageConditions ? "/about-us" : `/${language}/about-us`
export const eventsPage = languageConditions ? "/cancer/events" : `/${language}/cancer/events`
export const contactPage = languageConditions ? '/contact-us' : `/${language}/contact-us`
export const doctorDetailsPage = languageConditions ? '/doctors/:city/:doctorName' : `/${language}/doctors/:city/:doctorName`
export const doctorDetailsPageSlug = (slug: any) => { return languageConditions ? `/doctors/${slug}` : `/${language}/doctors/${slug}` }
export const eventsDetailsPage = languageConditions ? "/cancer/events/:name" : `/${language}/cancer/events/:name`
export const eventsDetailsPageSlug = (slug: any) => { return languageConditions ? `/cancer/events/${slug}` : `/${language}/cancer/events/${slug}` }
export const blogListPage = languageConditions ? "/cancer" : `/${language}/cancer`
export const blogDetailsPage = languageConditions ? "/cancer/:slug" : `/${language}/cancer/:slug`
export const blogDetailsPageSlug = (slug: any) => { return languageConditions ? `/cancer/${slug}` : `/${language}/cancer/${slug}` }
export const doctorTalkPage = languageConditions ? '/doctor-talk' : `/${language}/doctor-talk`
export const doctorListPage = languageConditions ? "/doctors-list" : `/${language}/doctors-list`
export const storiesPage = languageConditions ? "/stories" : `/${language}/stories`
export const queriesPage = languageConditions ? '/queries' : `/${language}/queries`
export const thankyouPage = languageConditions ? '/thankyou' : `/${language}/thankyou`
export const termsConditionPage = languageConditions ? '/terms-conditions' : `/${language}/terms-conditions`
export const privacyPolicyPage = languageConditions ? '/privacy-policy' : `/${language}/privacy-policy`
export const productListPage = languageConditions ? '/products' : `/${language}/products`
export const productDetailsPage = languageConditions ? "/products/:name" : `/${language}/products/:name`
export const productDetailsPageSlug = (slug: any) => { return languageConditions ? `/products/${slug}` : `/${language}/products/${slug}` }
export const productsCartPage = languageConditions ? "/product-cart" : `/${language}/product-cart`
export const selectAddressPage = languageConditions ? "/select-address" : `/${language}/select-address`
export const productAddressPage = languageConditions ? "/products-address" : `/${language}/products-address`
export const orderDetailsPage = languageConditions ? "/order-details" : `/${language}/order-details`
export const sharkTankPage = languageConditions ? "/sharkTank" : `/${language}/sharkTank`
export const searchPage = languageConditions ? '/search' : `/${language}/search`
export const zioperPage = languageConditions ? '/ziopar' : `/${language}/ziopar`
export const zioperInnerPage = languageConditions ? '/ziopar-InnerPage' : `/${language}/ziopar-InnerPage`
export const chatbotInnerPage = languageConditions ? '/chatbot-InnerPage' : `/${language}/chatbot-InnerPage`
export const downloadAppPage = languageConditions ? "/downloadApp" : `/${language}/downloadApp`
