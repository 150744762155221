import React, { Fragment, } from "react";
import "./couponandPayment.css";
import { Box, } from "@mui/material";
import PaymentMethod from "../PaymentBox/paymentBox";

export default function CouponandPayment(Props: { translation: any, Address?: any; continue?: any; details?: any; cartData?: any, totalQuantity?: any; totalPrice?: any }) {

    return (
        <Fragment>
            <Box className="CouponandPaymentBox">
                <PaymentMethod translation={Props?.translation} Address={Props.Address} continue={Props.continue} details={Props.details} cartData={Props.cartData} totalPrice={Props.totalPrice} totalQuantity={Props.totalQuantity} />
            </Box>
        </Fragment>
    );
}