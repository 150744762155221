import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { EventDetailModel } from "../../models/event-models";


const initialEventDetailState:EventDetailModel={
    status: 0,
    message: '',
    data: [],
    metadatas: [] 
}

const eventDetailSlice=createSlice({
    name:'eventDetail',
    initialState:initialEventDetailState,
    reducers:{
        setEventDetailData(state,action:PayloadAction<EventDetailModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
            state.metadatas = action.payload?.metadatas;
        }
    }
})
export default eventDetailSlice