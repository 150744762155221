import React from "react";
import "./contact.css";
import { Box, Typography } from "@mui/material";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";


interface Translate {
    translation: any
}

export default function Contact({ translation }: Translate) {
    return (
        <TopHeading>
            <Box className="MainContactUs-Box">
                <Typography className="ContactUs-Text">
                    {translation?.ContactUs}
                </Typography>
                <Typography className="ContactUs-Consultation-Text">
                    {translation?.ConsultationToWellness}
                </Typography>
            </Box>
        </TopHeading>
    );
}