
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductAddressModel } from "../../models/product-models";

const initialStoryState: ProductAddressModel = {
    status: 0,
    message: '',
    data: [],
}

const productAddressSlice = createSlice({
    name: 'productAddress',
    initialState: initialStoryState,
    reducers: {
        setProductAddress(state, action: PayloadAction<ProductAddressModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default productAddressSlice;