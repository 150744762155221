import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { createOrderModel } from "../../models/product-models";

const initialAppointmentState:createOrderModel={
    status: 0,
    message: '',
}

const appointmentSlice=createSlice({
    name:'doctorList',
    initialState:initialAppointmentState,
    reducers:{
        createAppointment(state,action:PayloadAction<createOrderModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
        }
    }
})
export default appointmentSlice;