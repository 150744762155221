import React from "react";
import "./queriesHead.css";
import { Box, Typography, Button } from "@mui/material";
import AppImages from "../../../images/AppImages";
import { whatsapp, phoneNumber } from "../../../constants/AppConstants";

interface Translate {
    translation: any
}
export default function QueriesHead({ translation }: Translate) {
    return (
        <React.Fragment>
            <Box className="QueriesHead-Box">
                <Typography className="CancerFind-Text">
                    {translation?.CancerCan} {translation?.FindAnswers}
                </Typography>
                <Typography className="queries-txt">
                    {translation?.yourQueries}
                </Typography>
                <Box className="QueriesBtns-Box">
                    <a href={whatsapp} target="_blank" rel="preconnect noreferrer" className='FQAChatus-btn-btn'>
                        <Button disableRipple variant="contained" className='FQAChatus-btn-btn'>
                            <img src={AppImages.Whapsapp} alt={translation?.WhatsappUs} />
                            <Typography>{translation?.WhatsappUs}</Typography>
                        </Button>
                    </a>
                    <a href={phoneNumber} className='serviceCallus-btn'>
                        <Button
                            className="QueriesCall-btn"
                            disableRipple >
                            <img src={AppImages.CallUsWhite} alt={translation?.CallUs} />
                            <Typography className="QueriesCall-btn-text2">{translation?.CallUs}</Typography>
                        </Button>
                    </a>
                </Box>
            </Box>
        </React.Fragment>
    );
}