import {ThankYourModel} from "../../models/doctor-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialThankYouState:ThankYourModel={
    status: 0,
    message: '',
    background_image: "",
    stories: [],
    metadatas: []
}

const thankyouSlice=createSlice({
    name:'thankyou',
    initialState:initialThankYouState,
    reducers:{
        setThankYouData(state,action:PayloadAction<ThankYourModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.background_image = action.payload?.background_image;
            state.stories = action.payload?.stories;
            state.metadatas = action.payload?.metadatas;
        }
    }
})
export default thankyouSlice;