import React from "react";
import "./eventsBlog.css";
import { Box } from "@mui/material";
import { EventDetailDataModel } from "../../../models/event-models";
import { storyDataModel } from "../../../models/story-models";
import EventBlogBox from "../EventBlogbox/eventBlogBox";

export default function EventsBlog(Props: { translation: any, blogs?: any, storyData?: Array<storyDataModel>; pastEventData?: Array<EventDetailDataModel>; eventBlog?: Boolean; pastEvents?: Boolean; dark?: Boolean; title?: Boolean }) {
    let data = Props.storyData || Props.pastEventData
    return (
        <Box className={Props?.pastEvents ? "All-EventsVideo-Box1" : Props?.blogs ? "All-EventsVideo-Box" : "All-EventsVideo-Box2"}>
            {data?.map((items: {
                [x: string]: any; id: React.Key | null | undefined; image: string | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
            }, index: any) => {
                let user = items?.user || items?.author_detail
                return (
                    <EventBlogBox translation={Props?.translation} title={Props.title} key={items.id} items={items} eventBlog={Props.eventBlog} user={user} index={index} pastEvents={Props.pastEvents} dark={Props.dark} />
                );
            })}
        </Box>
    );
}