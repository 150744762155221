
import Api from './Api';
import { FAQ_API, ZIOPAR_API } from '../constants/ApiName'

const config = {
    headers: {}
};

export default {
    async getFAQData(data: any) {
        var response = await Api().get(FAQ_API + data, config);
        console.log("Faq data Response----", response)
        return response.data;

    },
    async getZioparData() {
        var response = await Api().get(ZIOPAR_API, config);
        console.log("Faq Zioper Response----", response)
        return response.data;
    },
}