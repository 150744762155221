
import { createTheme } from "@mui/material/styles";


const theme = createTheme({

    palette: {
        secondary: {
            main: "#f87f2f",
        },
        primary:{
main:"#00229F"
        },
        background: {
            default: "white",
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paperWidthMd: {
                    borderRadius: "24px",
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                colorPrimary: "#f57625"
            }
        },
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    color: "#f57625"
                },
                track: {
                    color: '#f57625',
                },
                rail: {
                    color: 'black',
                    backgroundColor: "#bababa",
                    height: 4,
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                colorPrimary: "#f57625"
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                previousNext: {
                    padding: "23px 15px 23px 15px",
                    fontSize: "14px",
                },
                sizeLarge: {
                    margin: "0px",
                    borderRadius: "0px",
                    padding: "23px 20px 23px 20px",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "FontMedium",
                    color: "#6b7280",
                },
                sizeSmall: {
                    margin: "0px",
                    borderRadius: "0px",
                    padding: "23px 12px 23px 12px",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "FontMedium",
                    color: "#6b7280",
                },
            
            }
        },
    }

});
export default theme;