import React, { memo } from 'react'
import './yourjourneysub.css'
import { Box, Typography } from '@mui/material'
import AppImages from '../../../images/AppImages'
import useMediaQuery from '@mui/material/useMediaQuery';
import JourneyPoints from "./journeyInfo.json";
import { Translate } from '@mui/icons-material';

interface Translate {
    translation: any
}
function YourJourneySub({ translation }: Translate) {
    const morethen600 = useMediaQuery('(min-width:601px)');
    return (
        <Box className="yourJounry-MainBox">
            <Box className="yourJounry-txtMainBox">
                <Typography className="yourJounry-heading">{translation?.YourJourneywith}</Typography>
                <Typography className="yourJounry-heading-subtext">{translation?.Increasechancesofcure}</Typography>
            </Box>
            <Box className="yourJounry-txtMainBox2">
                {!morethen600 &&
                    <Box className='yourJounry-img-box'>
                        <img className='yourJounry-img' src={AppImages.SideEffect} alt="yourJounry" />
                    </Box>}
                <Box className="subtext-despcrition600">
                    {JourneyPoints.journeyPoints.map((items, index) => (
                        <Box key={items.id} className="yourJounry-txtbox">
                            <img className='yourJounry-tik' alt='Potint' src={AppImages.Check} />
                            <Typography className="yourJounry-heading-subtexts">
                                {translation?.[items.point]}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                {morethen600 && <Box className='yourJounry-img-box'>
                    <img className='yourJounry-img' src={AppImages.SideEffect} alt="yourJounry" />
                </Box>}</Box></Box>);
}
export default memo(YourJourneySub);

