import React from "react";
import { Box, Typography } from "@mui/material";
import "./advisory.css";
import SubDocter from "../../Common-Component/subConsultDoctor/subDocter";
import { advisoryModel } from "../../../models/about-models";

interface consultDoctorsList {
    doctorsList: Array<advisoryModel>
    translation: any
}
export default function Advisory({ doctorsList, translation }: consultDoctorsList) {
    return (
        <Box className="Main-AdvisoryBox">
            <Box className="Alltext-Box">
                <Typography className="Advisory-Text">
                    {translation?.Advisory}
                </Typography>
                <Typography className="Advisory-description-Text">
                    {translation?.Policy_card}
                </Typography>
            </Box>
            <Box className="DoctorsList-Box">
                <SubDocter translation={translation} advisoryList={doctorsList} advisory={true} />
            </Box>
        </Box>
    );
}