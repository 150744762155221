import { userDetailModel } from "../../models/doctor-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialPayState: userDetailModel = {
        status:'',
        data: [],
        message: ''
}

const userDetailSlice = createSlice({
    name: 'USER DEATIL',
    initialState: initialPayState,
    reducers: {
        setUserDetailData(state, action: PayloadAction<userDetailModel>) {
            state.status = action.payload?.status;
            state.data = action.payload?.data;
            state.message = action.payload?.message;
        }
    }
})
export default userDetailSlice;