
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/styles";
export const ExpandIcon = styled(ExpandMoreIcon)({
    color: "black",
    width: "30px",
    marginRight: "10px",
    fontSize: "30px",
    "&.MuiSelect-iconOpen": {
        width: "30px",
        marginRight: "10px",
        color: "black",
        fontSize: "30px",
    },
});

export const selectExpand = styled(ExpandMoreIcon)({
    color: "#919294",
    width: "24px",
    marginRight: "7px",
    fontSize: "30px",
    "&.MuiSelect-iconOpen": {
        width: "24px",
        marginRight: "7px",
        color: "#919294",
        fontSize: "30px",
    },
});


