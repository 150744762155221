
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoryDataModel } from "../../models/story-models";

const initialStoryState: StoryDataModel = {
    status: 0,
    data: []
}

const storySlice = createSlice({
    name: 'story',
    initialState: initialStoryState,
    reducers: {
        setStoryData(state, action: PayloadAction<StoryDataModel>) {
            state.status = action.payload?.status;
            state.data = action.payload?.data;
        }
    }
})
export default storySlice;