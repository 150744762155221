import React from "react";
import "./howitStarted.css";
import { Box, Link, Typography } from "@mui/material";
import AppImages from "../../../images/AppImages";

interface Translat {
    translation: any
}
export default function HowitStarted({ translation }: Translat) {
    return (
        <Box className="MainGrey-box">
            <div className="outer-div">
                <Box>
                    <Typography className="howitStarted-text">
                        <span className="How-it-text">{translation?.Howit}</span> {translation?.started}
                    </Typography>
                    <div className="descriptionStarted-Text">
                        {translation?.Ourjourney} <Link href="https://www.linkedin.com/in/dimplezenlove/" className="description-underLine" target="_blank" rel="noreferrer">{translation?.DimpleParmar}</Link>
                        {translation?.LostHer} <Link href="https://www.linkedin.com/in/niteshprajapat/" className="description-underLine" target="_blank" rel="noreferrer"> {translation?.Nitesh}</Link>
                        {translation?.shefounded} <Link href="https://www.linkedin.com/company/lovehealscancerofficial/" target="_blank" className="description-underLine" rel="noreferrer">{translation?.LoveHealsCancer}</Link>{translation?.inMarch}
                        <Link href="https://www.linkedin.com/in/kishanshahzen/" className="description-underLine" target="_blank" rel="noreferrer"> {translation?.KishanShah}</Link> {translation?.wasVolunteering}
                        <Link href="https://www.linkedin.com/company/zenonco-io/" className="description-underLine" target="_blank" rel="noreferrer">{translation?.ZenOncoio}</Link>
                        {translation?.worldsFirst}
                    </div>
                </Box>
            </div>
            <div className="outer-div">
                <Box className="AllImg-Box">
                    <Box className="images-boxes">
                        <img src={AppImages.AboutUs1} alt="images" className="First-image1" />
                        <img src={AppImages.AboutUs2} alt="images" className="Second-image2" />
                    </Box>
                    <Box className="images-boxes">
                        <img src={AppImages.AboutUs3} alt="images" className="Third-image3" />
                        <img src={AppImages.AboutUs4} alt="images" className="Fourth-image4" />
                    </Box>
                </Box>
            </div>
        </Box>
    );
}