import React from "react";
import "./requestAppointment.css";
import { Box, Typography } from "@mui/material";
import AppImages from "../../../../images/AppImages";

interface SubmitDetails {
    translation: any
}
export default function RequestSubmit({ translation }: SubmitDetails) {
    return (
        <Box className="RequestModal-mainBox">
            <Box className="submitedImagBox">
                <img src={AppImages.RecoveryImg} className="submitedImag" alt={translation?.ThankYou} />
            </Box>
            <Typography className="Rquest-thanks-txt">
                {translation?.ThankYou}
            </Typography>
            <Box>
                <Typography className="Rquest-thanksSub-txt">
                    {translation?.ForReachingouttous}.
                </Typography>
                <Typography className="Rquest-thanksSub-txt">
                    {translation?.OurWellnessCounselors}.
                </Typography >
            </Box>
        </Box >
    )
}