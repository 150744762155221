import React from "react";
import { Box, Typography, Button, Badge } from "@mui/material";
import { Link } from "@mui/material";
import "./360degree.css";
import { phoneNumber, whatsapp } from '../../../constants/AppConstants';
import AppImages from "../../../images/AppImages";
import { downloadAppPage } from "../../../values/paths";

interface Translate {
    translation: any
    loading: any
}
interface ButtonsDetails {
    handleCart: any
    closeDrawer: any
    darkTheme: any
    count: any
    loading: any
    translation: any
    drawerBtn?: any
}
export function  TalkBtns({ loading, translation }: Translate) {
    return (
        <Box className="Buttons-box">
            <Link href={loading ? phoneNumber : ""} className="download-btn" rel="preconnect dns-prefetch" >
                <Typography className="dwnld-text">
                    {translation?.TalktousNow}
                </Typography> </Link>
            <Link href={loading ? downloadAppPage : ""} className="call-Us-btn" target="_blank" rel="preconnect noreferrer">
                <Typography className="call-text">
                    {translation?.Downloadapp}
                </Typography></Link>
        </Box>
    );
}
export function BadgeWhatsapBtn({ handleCart, closeDrawer, darkTheme, count, loading, translation, drawerBtn }: ButtonsDetails) {
    return (
        <>
            <Button disableRipple className="badge-btn" onClick={() => {
                handleCart()
                closeDrawer()
            }}>
                <Badge className={drawerBtn ? "badgeColor1" : "badgeColor"} color="secondary" badgeContent={count} showZero
                    sx={{ "& .MuiBadge-badge": { fontSize: 9, color: darkTheme && !drawerBtn ? "white" : "black" } }}>
                    <img src={darkTheme && !drawerBtn ? AppImages?.whiteCart : AppImages.mobileCart} className="cartIcon-img" alt={translation?.cart} />

                </Badge>
            </Button>
            <Link href={loading ? whatsapp : ""} className={drawerBtn ? "mobilebar-Box1" : "callMobileLink"} rel="preconnect noreferrer">
                <img src={darkTheme && !drawerBtn ? AppImages?.callMobileimgWhite : AppImages?.callMobileimg} alt="WhatsUp" className="callMobileImg" />
            </Link>
        </>
    );
}