import { Box } from '@mui/material'
import React from 'react'
import Header from '../../components/Common-Component/Header/header'
import Footer from '../../components/Common-Component/Footer/footer'
import BookConsultation from '../../components/Common-Component/BookConsultation/bookConsultation'
import MostSearched from '../../components/Common-Component/MostSearched/mostSearched'
import './orderDetailsPage.css'
import AddressHead from '../../components/Common-Component/AddressHead/addressHead'
import OrderDetails from '../../components/OrderDetailsPage-Component/OrderDetails/orderDetails'


interface GlobalTranslat {
  translation: any
}
export default function OrderDetailsPage({ translation }: GlobalTranslat) {
  return (
    <>
      <Box className="OrderDetail-Header">
        <Header darkTheme={true} translation={translation}/>
      </Box>
      <AddressHead translation={translation}/>
      <OrderDetails translation={translation}/>
      <BookConsultation translation={translation}/>
      <Box className="Footer">
        <Footer translation={translation}/>
        <MostSearched translation={translation}/>
        <br />
      </Box>
    </>
  );
}