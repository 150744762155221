import React, { memo, useEffect, useState } from "react";
import { Box, Link, Typography, Divider, IconButton } from "@mui/material";
import "./mobileFooter.css";
import AppImages from "../../../images/AppImages";
import { googlePlay, appStore, twitter } from "../../../constants/AppConstants";
import { facebook, youtube, instagram, linkedin } from "../../../constants/AppConstants";
import { aboutPage, blogListPage, doctorListPage, doctorTalkPage, privacyPolicyPage, queriesPage, storiesPage, termsConditionPage, zioperPage } from "../../../values/paths";

interface Translate {
    translation: any,
}
export default function MobileFooter({ translation }: Translate) {
    return (
        <Box className="MfooterMain-box">
            <Box>
                <img src={AppImages?.FooterLogo} alt="Logo" />
            </Box>
            <Box className="helplineNumber-box">
                <Box>
                    <Typography className="helpline-txt">{translation?.ForCancerCareRelated}</Typography>
                    <Typography className="helpline-numbertxt">{translation?.PhoneNumber}</Typography>
                </Box>
                <Box>
                    <Typography className="helpline-txt">{translation?.ForCareerRelated}</Typography>
                    <Typography className="helpline-numbertxt">{translation?.CareerNo}</Typography>
                </Box>
            </Box>
            <Box className="AllLinkBtn-box">
                <Box className="leftRightLink-box">
                    <Link href={doctorListPage} className="Linknavigate-txt">
                        • {translation?.Doctors}
                    </Link>
                    <Link href={aboutPage} className="Linknavigate-txt">
                        • {translation?.About}
                    </Link>
                    <Link href={queriesPage} className="Linknavigate-txt">
                        • {translation?.FAQs}
                    </Link>
                    <Link href={storiesPage} className="Linknavigate-txt">
                        • {translation?.Stories}
                    </Link>
                </Box>
                <Box className="leftRightLink-box">
                    <Link href={blogListPage} className="Linknavigate-txt">
                        •  {translation?.Blogs}
                    </Link>
                    <Link target="_blank" rel="noreferrer" href="https://www.naukri.com/zenheal-wellness-jobs-careers-5269768" className="Linknavigate-txt">
                        • {translation?.Career}
                    </Link>
                    <Link href={zioperPage} className="Linknavigate-txt">
                        • {translation?.Generatereport}
                    </Link>
                    <Link href={doctorTalkPage} className="Linknavigate-txt">
                        • {translation?.DoctorsInterview}
                    </Link>
                </Box>
            </Box>
            <Box className="download-app-box">
                <a href={googlePlay} target="_blank" rel="noreferrer" >
                    <img src={AppImages.playStore} alt="playstore" className="Stores-btns-box" /></a>
                <a href={appStore} target="_blank" rel="noreferrer">
                    <img src={AppImages.appStore} alt="Appstore" className="Stores-btns-box" /></a>
            </Box>
            <Box className="Ngo_SocialMedia-Box">
                <Box className="SocialMedia_Main-Box">
                    <Typography className="socialMedia_heading-txt">{translation?.StayConnected}</Typography>
                    <Box className="SocialMedia-Box">
                        <a href={youtube} target="_blank" rel="noreferrer" className="socialMedia-link">
                            <IconButton disableRipple>
                                <img src={AppImages.YouTube} alt="Youtube" className="Youtube-iconSize" />
                            </IconButton>
                        </a>
                        <a href={facebook} target="_blank" rel="noreferrer" className="socialMedia-link1">
                            <IconButton disableRipple>
                                <img src={AppImages.FaceBookIcon} alt="FaceBook" className="Facebook-iconSize1" />
                            </IconButton>
                        </a>
                        <a href={instagram} target="_blank" rel="noreferrer" className="socialMedia-link1">
                            <IconButton disableRipple>
                                <img src={AppImages.InstaIcon} alt="Instagram" className="Insta-iconSize" />
                            </IconButton>
                        </a>

                        <a href={linkedin} target="_blank" rel="noreferrer" className="socialMedia-link">
                            <IconButton disableRipple>
                                <img src={AppImages.LinkedinIcon} alt="LinkedIn" className="Insta-iconSize" />
                            </IconButton>
                        </a>
                        <a href={twitter} target="_blank" rel="noreferrer" className="socialMedia-link">
                            <IconButton disableRipple>
                                <img src={AppImages.TwitterIcon} alt="twitter" className="Youtube-iconSize" />
                            </IconButton>
                        </a>
                    </Box>
                </Box>
                <Box className="NGO-Box">
                    <Typography className="socialMedia_heading-txt">{translation?.NGO}</Typography>
                    <Box className="SocialMedia-Box">
                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                            <IconButton disableRipple>
                                <img src={AppImages.LoveHeals} alt="LoveHealsCancer" className="LoveHeals-iconSize" />
                            </IconButton>
                        </a>
                    </Box>
                </Box>
            </Box>
            <Box className="Privacy_sitemap-Box">
                <Link className="terms_sitemap-Link" href={termsConditionPage} target="_blank">
                    {translation?.TermsConditions}
                </Link>
                <Divider orientation="vertical" className="Privacy-SitemapDvd" variant="middle" />
                <Link className="terms_sitemap-Link" href={privacyPolicyPage} target="_blank">
                    {translation?.PrivacyPolicy}
                </Link>
                <Divider orientation="vertical" className="Privacy-SitemapDvd" variant="middle" />
                <Link className="terms_sitemap-Link" href="/sitemap.xml" target="_blank">
                    {translation?.Sitemap}
                </Link>
            </Box>
            <Box className="copyRight_addressBox">
                <Typography className="copyRight_address-txt">
                    {translation?.CopyrightTxt}
                </Typography>
            </Box>
        </Box>
    )
}