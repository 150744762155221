import React, { Fragment, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import "./selectAddress.css";
import AddressBar from "../AddressBar/addressBar";
import CouponandPayment from "../../Common-Component/CouponandPayment/couponandPayment";
import { Link } from "react-router-dom";
import { GETAddressDataModel } from "../../../models/product-models";
import { productAddressPage } from "../../../values/paths";


export default function SelectAddress(props: { translation: any, addressData: Array<GETAddressDataModel>, initiateMethod: any, totalQuantity: any, totalPrice: any, setSelectedAddress: any }) {
    const [selectedId, setSelectedId] = useState("");
    const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        let selectedAddressData = props?.addressData?.filter(item => item.id.toString() === event.target.value)
        if (selectedId === event.target.value) {
            setSelectedId("");
            props.setSelectedAddress({})
        } else {
            props.setSelectedAddress(selectedAddressData[0])
            setSelectedId(event?.target?.value);
        }
    }
    return (
        <Fragment>
            <Box className="MainAddressSelect-Box">
                <Box className="Address-leftBox">
                    <Box className="addressBars-Box">
                        {props?.addressData?.map((data: any, index: any) => (
                            <Fragment key={data?.id}>
                                <AddressBar translation={props?.translation} totalPrice={props.totalPrice} totalQuantity={props.totalQuantity} datas={data} handleRadio={handleRadio} selectedId={selectedId} />
                            </Fragment>
                        ))}
                    </Box>
                    <Box className="Address-selectbtnBox">
                        <Link className="add-Address-Btn" to={productAddressPage} state={{
                            totalQuantity: props.totalQuantity,
                            totalPrice: props.totalPrice
                        }}>
                            <Typography>{props?.translation?.Addnewaddress}</Typography>
                        </Link>
                        <Button className={selectedId === "" || selectedId === undefined ? "DisabledAddressContinue-btn" : "AddressContinue-btn"} disabled={selectedId === "" || selectedId === undefined} onClick={() => props.initiateMethod(props.totalPrice)}>
                            <Typography className="AddressContinue-txt">{props?.translation?.Continue} </Typography>
                        </Button>
                    </Box>
                </Box>
                <Box className="payment-mainBox">
                    <CouponandPayment translation={props?.translation} totalPrice={props.totalPrice} totalQuantity={props.totalQuantity} Address={true} />
                </Box>
            </Box>
        </Fragment>
    );
}