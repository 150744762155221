import React, { useState } from "react";
import { Avatar, Box, Button, Card, Skeleton, Typography, useMediaQuery } from "@mui/material";
import "./feedbackCards.css";
import { reviewModel } from "../../../models/home-models";
import Carousel from "react-multi-carousel";
import Responsive from "../../Common-Component/responsive.json";
import { ButtonGroupProps } from 'react-multi-carousel/lib/types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface reviewsData {
    Reviews: Array<reviewModel>
    onLoadMore: Function
    isLoadMore?: boolean
    translation: any
}

export default function FeedbackCards({ translation, Reviews, onLoadMore, isLoadMore }: reviewsData) {
    const [showMore, setShowMore] = useState(false)
    const [button, setButton] = useState(0)
    const handleShowMore = (i: any) => {
        setButton(i)
        setShowMore(true)
        if (showMore && button === i) {
            setShowMore(false)
        }
    }
    const closeShowMore = () => {
        setShowMore(false)
    }
    const mobileview = useMediaQuery("(max-width:600px)");
    const ButtonGroup = ({ next, previous, goToSlide, carouselState, ...rest }: ButtonGroupProps) => {
        const totalSlide = carouselState?.slidesToShow && carouselState?.currentSlide ?
            carouselState?.slidesToShow + carouselState?.currentSlide : null
        const lastItem = totalSlide !== null && carouselState?.totalItems ? totalSlide < carouselState?.totalItems : null
        const handleNavigation = (prop: any) => {
            if (prop === next) {
                if (next !== undefined) {
                    next()
                }
            } else {
                if (previous !== undefined) {
                    previous()
                }
            }
            closeShowMore()
        }
        return (
            <>
                {carouselState?.slidesToShow !== undefined &&
                    <>
                        {carouselState?.slidesToShow < 3 ?
                            <Box className="FeedBackexpert-Box">
                                {carouselState?.currentSlide !== 0 && <Button aria-label='swipeLeft' disableRipple className="FeedBackCButton-prev" onClick={() => handleNavigation(previous)}>
                                    <NavigateBeforeIcon className="expertCButtonicon" />
                                </Button>}
                                {lastItem === null || lastItem ? <Button aria-label='swipeRight' disableRipple className="FeedBackCButton-next" onClick={() => handleNavigation(next)}>
                                    <NavigateNextIcon className="expertCButtonicon" />
                                </Button> : null}
                            </Box> : null
                        }</>}
            </>
        );
    };
    return (
        <React.Fragment>
            <Box className="box-members-say">
                <Box className="Submain-box">
                    <Typography className="MembersSay-text">{translation?.WhatourPatientssay}</Typography>
                    {mobileview ? <Box className="FeedBackCarouselBox">
                        {Reviews?.length ?
                            <Carousel ssr responsive={Responsive.responsive} className="caroselClass" customButtonGroup={<ButtonGroup />}
                                arrows={false} renderButtonGroupOutside >
                                {Reviews?.length > 0 && Reviews?.map((items, index) =>
                                    <Card key={items?.id} className="Card-background">
                                        <Box>
                                            <Typography
                                                style={{ zIndex: 0, position: "relative" }}
                                                className={showMore && button === index ? "AllCards-card-text" : "AllCards-card-text1"}>
                                                {items?.text}
                                            </Typography>
                                        </Box>
                                        <Box className='morelessbtn-mainbox'>
                                            <Button tabIndex={-1} disableRipple onClick={() => handleShowMore(index)} className="morelessbtn">
                                                {showMore && button === index ?
                                                    <Typography className="morelesstext">{translation?.readLess}</Typography> :
                                                    <Typography className="morelesstext">{translation?.readMore}</Typography>}
                                            </Button>
                                        </Box>
                                        <Box className="AllCards-box-name">
                                            {/* <Avatar src={items?.image} className="Review_image" alt={items?.name} /> */}
                                            <img src={items?.image} className="Review_image" alt={items?.name} loading="lazy" />
                                            <Typography className="allcard-owername">{items?.name}</Typography>
                                        </Box></Card>)}
                            </Carousel> : <Skeleton variant="rounded" animation="wave" className="skeletonRect" />}
                    </Box> :
                        <Box className="AllCards-box">
                            {Reviews?.length > 0 && Reviews?.map((items, index) =>
                                <Card key={items?.id} className="Card-background" >
                                    <Box>
                                        <Typography
                                            style={{ zIndex: 0, position: "relative" }}
                                            className={showMore && button === index ? "AllCards-card-text" : "AllCards-card-text1"}>
                                            {items?.text}
                                        </Typography></Box>
                                    <Box className='morelessbtn-mainbox'>
                                        <Button disableRipple onClick={() => handleShowMore(index)} className="morelessbtn" >
                                            {showMore && button === index ?
                                                <Typography className="morelesstext">{translation?.readLess}</Typography> :
                                                <Typography className="morelesstext">{translation?.readMore}</Typography>}
                                        </Button></Box>
                                    <Box className="AllCards-box-name">
                                        <Avatar src={items?.image} className="Review_image" alt={items?.name} />
                                        <Typography className="allcard-owername">{items?.name}</Typography>
                                    </Box></Card>)}
                        </Box>}
                    {isLoadMore ?
                        <Button disableRipple className="ViewAll-btn" onClick={() => onLoadMore()}>
                            <Typography className="ViewAll-text">
                                {translation?.Loadmore}
                            </Typography>
                        </Button> : null}
                </Box></Box>
        </React.Fragment>
    );
}