import React from "react";
import "./downloadApp.css";
import { Box, Typography } from "@mui/material";
import AppImages from "../../images/AppImages";
import { googlePlay, appStore } from "../../constants/AppConstants";
interface Translate {
    translation: any
}

export default function DownloadAppComponent({ translation }: Translate) {
    return (
        <Box className="downloadPage_main-Box">
            <Box className="downloadOrange-Box">
                <Box>
                    <img src={AppImages.DownloadLogo} className="downloadLogoImg" alt="" />
                    <Typography className="cancerCare-txt">
                        {translation?.CancerCareApp}
                    </Typography>
                    <Typography className="yourCompanionJourny-txt">{translation?.Yourcompanion}</Typography>
                    <Box className="Appbtn-txtBox">
                        <Typography className="AppfromStore-txt">{translation?.DownloadAppfromstore}</Typography>
                        <Box className="Downloadpage-AppPlay-store">
                            <a href={googlePlay} target="_blank" rel="noreferrer" className="Downloadpage-PlayStore-btn">
                                <img src={AppImages.GooglePlaystore} alt={translation?.Playstore} className="Downloadpage-Store-btns-box" /></a>
                            <a href={appStore} target="_blank" rel="noreferrer" className="Downloadpage-AppStore-btn">
                                <img src={AppImages.AppStore} alt={translation?.Appstore} className="Downloadpage-Store-btns-box2" /></a>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box className="text-mobileimgBox">
                    <Box width="100%">
                        <Box className="text-lineBox">
                            <Box className="lineBox" />
                            <Box>
                                <Typography className="top-txt">
                                    Increase
                                </Typography>
                                <Typography className="bottom-txt">
                                    immunity
                                </Typography>
                            </Box>
                        </Box>
                        <br />
                        <Box className="text-lineBox">
                            <Box className="lineBox" />
                            <Box>
                                <Typography className="top-txt">
                                    Manage
                                </Typography>
                                <Typography className="bottom-txt">
                                    side effects
                                </Typography>
                            </Box>
                        </Box>
                        <br />
                        <Box className="text-lineBox">
                            <Box className="lineBox" />
                            <Box>
                                <Typography className="top-txt">
                                    Connect with
                                </Typography>
                                <Typography className="bottom-txt">
                                    survivors
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <img src={AppImages.downloadImg} className="mobileimg" />
                </Box>
            </Box>
        </Box>
    );
}