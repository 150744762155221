import React, { Fragment } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import "./addressHead.css";
import TopHeading from "../TopHeadingComponent/topHeading";


interface Translate {
    translation: any
}
export default function AddressHead({ translation }: Translate) {
    const maxWidth500 = useMediaQuery("(max-width:500px)");
    return (
        <Fragment>
            <TopHeading>
                <Box className="AddressHead-Main-Box">
                    {maxWidth500 ?
                        <>
                            <Typography className="AddressHead-heading-txt">{translation?.cart} </Typography>
                            <Typography className="AddressHeadS-heading-txt">{translation?.ADDRESS}</Typography>
                            <Typography className="AddressHead-heading-txt">
                                {translation?.PAYMENT}
                            </Typography>
                        </> :
                        <>
                            <Typography className="AddressHead-heading-txt">{translation?.cart} · · · · · · · · · · · · · · ·</Typography>
                            <Typography className="AddressHeadS-heading-txt">{translation?.ADDRESS}</Typography>
                            <Typography className="AddressHead-heading-txt">
                                · · · · · · · · · · · · · · · {translation?.PAYMENT}
                            </Typography>
                        </>
                    }
                </Box>
            </TopHeading>
        </Fragment>
    );
}