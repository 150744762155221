import React from "react";
import "./loveHeals.css";
import { Box, Typography } from "@mui/material";
import AppImages from "../../../images/AppImages";

interface Translate {
    translation: any
}
export default function LoveHeals({ translation }: Translate) {
    return (
        <Box className="LoveHeals-mainBox">
            <Box className="LoveHeals-box">
                <img src={AppImages.LoveText} alt={translation?.Love} className="Love-TextImage" />
                <Typography className="Heals-Text">
                    {translation?.Heals}
                </Typography>
            </Box>
            <Box className="All-numbersText-box" >
                <Box className="Years-to-Cities">
                    <Box className="Years-to-Cities-subbox">
                        <Typography className="Text100">
                            {translation?.Hundread}+
                        </Typography>
                        <Typography className="care-team-advisorText">
                            {translation?.Careteam}
                        </Typography></Box>
                    <Box><Typography className="Text5">
                        {translation?.Five}<span className="text-style-1">{translation?.yrs}</span>
                    </Typography>
                        <Typography className="experience-in-caregivingText">
                            {translation?.Experienceincare}
                        </Typography></Box>
                </Box>
                <Box className="Years-to-Cities">
                    <Box className="Years-to-Cities-subbox1">
                        <Typography className="Text7200">
                            {translation?.SevntyTwoTh}+
                        </Typography>
                        <Typography className="people-guidedText">
                            {translation?.PeopleGuided}
                        </Typography>
                    </Box>
                    <Box className="Box200-Cities">
                        <Typography className="Text200">
                            {translation?.TwoHundread}+
                        </Typography>
                        <Typography className="cities-Text">
                            {translation?.Cities}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}