
import Api from './Api';
import { EVENT_DETAIL_NEW, FUTURE_EVENTS, PAST_EVENTS, REGISTEREVENT } from '../constants/ApiName'
import Api2 from './Api2';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const config = {
    headers:{}
};

const tokenConfig = {
    headers: {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
};

export default{
    async getFutureEvents(){
        var response= await Api().get(FUTURE_EVENTS, config);
        console.log("Future events Response----", response)
        return response.data;
    },

    async getPastEvents(page: Number){
        var response= await Api().get(PAST_EVENTS + page, config);
        console.log("Past events Response----", response)
        return response.data;
    },

    async getEventDetail(data: any){
        var response= await Api().post(EVENT_DETAIL_NEW,data, config);
        console.log("Event Detail Response----", response)
        return response.data;
    },
    async registerEvent(data: any,token:any){
        const tokenConfigToken = {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('token') || token
            }
        };
        var response= await Api2().post(REGISTEREVENT,data, tokenConfigToken);
        console.log("Event register Response----", response)
        return response.data;
    }
}