
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductDetailModel } from "../../models/product-models";

const initialStoryState: ProductDetailModel = {
    status: 0,
    message: '',
    data: [],
    similar_product: [],
}

const productSlice = createSlice({
    name: 'productDetail',
    initialState: initialStoryState,
    reducers: {
        setProductDetail(state, action: PayloadAction<ProductDetailModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
            state.similar_product = action.payload?.similar_product;
        }
    }
})
export default productSlice;