import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import {  RegisterEventModel } from "../../models/event-models";


const initialRegisterEventState:RegisterEventModel={
    status: 0,
    message: '',
    data: []
}

const registerEventSlice=createSlice({
    name:'registerEvent',
    initialState:initialRegisterEventState,
    reducers:{
        setRegisterEventData(state,action:PayloadAction<RegisterEventModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default registerEventSlice