import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./faqPage.css";
import Header from "../../components/Common-Component/Header/header";
import QueriesHead from "../../components/QueriesPage-Component/QueriesHead/queriesHead";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import QueriesAnswer from "../../components/QueriesPage-Component/QueriesAnswers/queriesAnswers";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { getFAQData, getZioparData } from "../../store/faq/faq-actions";
// import UserQueries from "../../components/Common-Component/UserQueries/userQueries";
import Seo from "../../components/Common-Component/SEO/seo";
import { OnlyAlphabets } from "../../values/helper";

interface GlobalTranslat {
    translation: any
}
export default function QueriesPage({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const faqData = useAppSelector(state => state?.faq);
    // const zioparData = useAppSelector(state => state?.ziopar);
    const [searchFAQ, setSearchFAQ] = useState('');

    useEffect(() => {
        dispatch(getZioparData())
    }, []);

    useEffect(() => {
        dispatch(getFAQData(searchFAQ))
    }, [searchFAQ]);

    const onChangeSearch = (e: any) => {
        setSearchFAQ(e.target.value.replace(OnlyAlphabets, ''))
    }
    return (
        <React.Fragment>
            <Seo translation={translation}
                metaData={faqData.metadatas} />
            <Box className="Header-Top-Box">
                <Box className="faqPage-header">
                    <Header darkTheme={true} translation={translation}/>
                </Box>
                <Box>
                    <QueriesHead translation={translation} />
                </Box>
            </Box>
            <QueriesAnswer translation={translation} queries_answers={faqData.data} onChangeSearch={onChangeSearch} searchFAQ={searchFAQ} />
            <BookConsultation translation={translation}/>
            <Box className="Footer">
                <Footer translation={translation}/>
                <MostSearched translation={translation} />
                <br />
            </Box>
        </React.Fragment>
    );
}