import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { FutureEventModel } from "../../models/event-models";


const initialFutureEventState:FutureEventModel={
    status: 0,
    message: '',
    event_future_data: [],
    metadatas: []
}

const fultureEventSlice=createSlice({
    name:'futureEvent',
    initialState:initialFutureEventState,
    reducers:{
        setFutureEventData(state,action:PayloadAction<FutureEventModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.event_future_data = action.payload?.event_future_data;
            state.metadatas = action.payload?.metadatas;
        }
    }
})

export default fultureEventSlice