import React, { useEffect, useState, useRef } from "react";
import { Box, Button, TextField, Typography, InputAdornment } from "@mui/material";
import "./messageBox.css";
import AppImages from "../../../images/AppImages";
import { useAppDispatch, useAppSelector, } from "../../../hooks/redux-hooks";
import { getChatBotAnswer } from "../../../store/home/home-actions";

interface Translate {
    translation: any
}
export default function MessageBox({ translation }: Translate) {
    const [sendMessageText, setSendMessageText] = useState('');
    const [messages, setMessages] = useState<any>([]);
    const [refresh, setRefresh] = useState(false);
    const chatBotData = useAppSelector(state => state?.chatBotAns);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch()

    const sendMessage = (e: any) => {
        setSendMessageText(e.target?.value);
    }
    const onSendMessage = () => {
        if (sendMessageText.length > 0) {
            let data = {
                set_question: sendMessageText
            }
            messages.push(
                { text: sendMessageText, isFromSender: true }
            );
            setMessages(messages);
            dispatch(getChatBotAnswer(data));
            setSendMessageText('');
            setLoading(true);
        }
    }
    const div1 = useRef<HTMLElement | null>(null);
    useEffect(() => {
        if (chatBotData.data.length) {
            messages.push(
                { text: chatBotData.data, isFromSender: false }
            );
            setMessages(messages);
            setRefresh(!refresh);
            setLoading(false);
        }
    }, [chatBotData.data]);

    useEffect(() => {
        if (div1.current !== null) {
            div1?.current?.scrollIntoView({ behavior: "auto", block: "end" })
        }
    }, [messages.length]);
    return (
        <Box className="ChatbotBox">
            <Box className="ChatHeadinfo_Box">
                <img src={AppImages.ChatDp} className="expertDP" />
                <Box>
                    <Typography className="expertHeadName">{translation?.CancerCoachZena}</Typography>
                    <Typography className="expertInfo-txt">
                        <span className="Chatbot_point">•</span>
                        {translation?.Online}</Typography>
                </Box>
            </Box>
            <Box className="chattingMain-Box">
                {messages.length > 0 && messages.map((data: any, index: any) => (
                    <Box ref={div1} key={index} className={data.isFromSender ? "SendMsg-Box" : "ReplyMsg-Box"}>
                        <Typography className={data.isFromSender ? "senderName-txt" : "ReplyName-txt"}>{data.isFromSender ? "You" : "Cancer Coach Zena"}</Typography>
                        <Typography className={data.isFromSender ? "sendMesg-txt" : "ReplyMesg-txt"}>{data.text}</Typography>
                    </Box>
                ))}
                {loading && <Box ref={div1} className="ReplyMsg-Box">
                    <Box className="ReplyMesg-txt">
                        <div className="loading">...</div></Box>
                </Box>}
            </Box>
            <Box className="chatInput_sendBox">
                <TextField
                    multiline
                    autoComplete='off'
                    className="ChatBot-holder-Box"
                    value={sendMessageText}
                    onChange={sendMessage}
                    placeholder={translation?.Enterdetailsinfield}
                    sx={{ "& .MuiOutlinedInput-root": { "& > fieldset": { border: "none", borderColor: "transperant !important" }, } }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <Button disableRipple className="ChatSendBtn" onClick={onSendMessage}>
                                <img src={AppImages.SendBtn} alt="send" />
                            </Button>
                        </InputAdornment>,
                        disableUnderline: true, className: "Chatinput-inputBox",
                    }}
                />
            </Box>
        </Box>
    );
}