import { useEffect } from "react";

export const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export function calculateDiscount(original_price: any, sale_price: any, text?: any) {
    if (original_price === undefined) return '0';
    else if (sale_price === undefined) return '0';
    else {
        let disAmount = Math.floor(((original_price - sale_price) / original_price) * 100);
        if (disAmount === 0) {
            return ''
        } else {
            return '(' + Math.floor(((original_price - sale_price) / original_price) * 100) + "% " + text + ')';
        }
    }
}
export const OnlyNumbers = /[^0-9]/g
export const OnlyAlphabets = /[^a-zA-Z\s]/g
export const AccpetOnly = /^[a-zA-Z]+$/;
export const handleAlert = (setAlert: any) => {
    setAlert(true)
    setTimeout(() => {
        setAlert(false)
    }, 2000)
}
export const BackPage = () => {
    window.history.go(-1)
}
export const fetchTranslations = async (language: any, innerPage?: boolean) => {
    try {
        const response = await fetch(language === null || language === "" ? "../../commonTxt.json" : `../../../${language}/commonTxt.json`);
        const data = await response.json();
        console.log('commonTxt.json', response);
        ; return data
    } catch (error) {
        console.error('Error fetching translations:', error);
    }
};