import React, { useState } from "react";
import "./blogsComponent.css";
import { Box, Typography, Button, MenuItem, FormControl, InputLabel, Select, Autocomplete, TextField } from "@mui/material";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import { ExpandIcon } from "../../Common-Component/ExpandIcon/expandIcon";
import EventsBlog from "../../Common-Component/EventsBlog/eventsBlog";
import { storyDataModel, cancerCategoryModel } from "../../../models/story-models";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useMediaQuery from '@mui/material/useMediaQuery';

interface story {
    totalData: Array<storyDataModel>
    cancerCategory: Array<cancerCategoryModel>
    topicList: Array<string>
    handleMorePages: Function
    pageNo: number
    filteredOption: any
    setFilteredOption: any
    setPage: any
    loadMore: any
    storyData: Array<storyDataModel>
    translation: any
}
export default function Blogs({ translation, filteredOption, loadMore, setPage, setFilteredOption, totalData, pageNo, handleMorePages, cancerCategory, topicList, storyData }: story) {
    const [selected, setSelected] = useState(true);
    function handleAll() {
        setSelected(!selected);
        setFilteredOption({ cancer_type: "", topic: "" });
        setPage(1);
    }
    function handleCloseAll() {
        setSelected(false);
    }
    const handleFilterderOptions = (name: any, name1: any) => (e: any) => {
        setFilteredOption({ [name]: e?.target?.value, [name1]: "" })
        setPage(1)
        if (filteredOption?.cancer_type !== "" || filteredOption?.topic !== "") {
            handleCloseAll()
        }
    };
    const downloadcompo = useMediaQuery('(min-width:601px)');
    return (
        <React.Fragment>
            <Box>
                <TopHeading>
                    <Box className="BlogsHeading-MainBox">
                        <Typography className="Blogs-Text">
                            {translation?.Blogs}
                        </Typography>
                        <Typography className="Queries-Text">
                            {translation?.yourQueries}
                        </Typography>
                        <Box className="Blog-btn-ChipsBox">
                            <Box className="allTo-category-Box">
                                <Button disableRipple className={selected ? "selected-BlogBtns" : "BlogAll-btn"} onClick={handleAll}>
                                    <Typography className={selected ? "selected-Blogtxt" : "BlogAll-txt"}>{translation?.All}</Typography>
                                </Button>
                                {downloadcompo && <Box className="topic-filterBox">
                                    <FormControl size="small" onClick={handleCloseAll} >
                                        <InputLabel shrink={false} className="Blog-txt" >
                                            {filteredOption?.cancer_type ? "" : translation?.Category} </InputLabel>
                                        <Select
                                            value={filteredOption?.cancer_type}
                                            onChange={handleFilterderOptions("cancer_type", "Topic")}
                                            variant="standard" disableUnderline className={filteredOption?.cancer_type ? "Blog-Chip1" : "Blog-Chip"}
                                            IconComponent={ExpandIcon}>
                                            {cancerCategory?.map((items, index) => (
                                                items?.name?.length > 0 && <MenuItem key={items?.id} value={items?.id}>{items?.name}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Box>}
                            </Box>
                            {topicList && <Box className="Blog-ChipsBox">
                                <Box className="topic-filterBox">
                                    <Autocomplete
                                        popupIcon={<KeyboardArrowDownIcon className="auto-keyboardIcon" />}
                                        componentsProps={{ popper: { style: { width: 'max-content', zIndex: 1 } } }}
                                        options={topicList}
                                        getOptionLabel={(option) => filteredOption?.topic ? option : ""}
                                        noOptionsText={translation?.NoresultsFound}
                                        renderOption={(props, option) => (
                                            <Box key={option}>
                                                {option?.length > 0 && <li  {...props} key={option}>
                                                    {option?.length > 0 && option}
                                                </li>}
                                            </Box>)}
                                        className="Topic-chip"
                                        onChange={(event: any, newValue: any) => {
                                            setFilteredOption({ topic: newValue, cancer_type: "" });
                                            handleCloseAll()
                                        }}
                                        renderInput={(params) => <TextField className="autotxtField-inputBox" variant="standard" placeholder={translation?.Topic} {...params}
                                            InputProps={{ ...params.InputProps, disableUnderline: true, style: { fontSize: "18px", fontFamily: "FontSemiBold", } }}
                                            sx={{
                                                input: {
                                                    color: 'black',
                                                    "&::placeholder": { opacity: 1, }
                                                },
                                            }} />} />
                                </Box>
                            </Box>}
                        </Box>
                    </Box>
                </TopHeading>
            </Box>
            <Box className="mainBlogs-Box">
                <EventsBlog blogs={true} translation={translation} dark={false} title={true} eventBlog={false} storyData={pageNo === 1 ? storyData : totalData} />
            </Box>
            {loadMore && <Box className="Next-BtnBox">
                <Button disableRipple className="Next-btn" onClick={() => handleMorePages()}>
                    <Typography className="Next-btnTxt">{translation?.NextPage}</Typography></Button>
            </Box>}
        </React.Fragment>
    );
}
