import React, { Fragment, useEffect, useState } from "react";
import { Box, } from "@mui/material";
import "./productsCart.css";
import ProductListView from "../ProductListView/productListView";
// import PaymentMethod from "../../Common-Component/PaymentBox/paymentBox";
import CouponandPayment from "../../Common-Component/CouponandPayment/couponandPayment";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { deleteCart, getCart, updateCart } from "../../../store/product/product-actions";
import Cookies from "universal-cookie";

interface Translate {
    cart: any
    translation: any
}
export default function ProductsCart({ cart, translation }: Translate) {
    const dispatch = useAppDispatch();
    const cartData = useAppSelector(state => state?.cartList);
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0);
    const [quantityCart, setQuantityCart] = useState(0);
    const cookies = new Cookies();
    const userID = cookies.get('userId')
    useEffect(() => {
        if (userID !== undefined) {
            getCartData(userID)
        }
    }, [])
    const getCartData = (id: number) => {
        dispatch(getCart(id));
    }
    const deleteData = (value: number) => {
        let data = {
            id: value,
            userId: userID
        }
        dispatch(deleteCart(data));
        setTimeout(() => {
            getCartData(userID)
        }, 1000)
    }
    const updateCartData = (data: any) => {
        dispatch(updateCart(data, cookies.get('token')));
        setTimeout(() => {
            getCartData(userID)
        }, 1200)
    }

    useEffect(() => {
        const totalItems = cartData?.data?.map((items: any, index: any) => (items?.qunatity));
        const totalprices = cartData?.data?.map((items: any, index: any) => (items?.product_item?.sale_price))
        const totalQuantity = totalItems?.reduce((pv: any, cv: any) => pv + cv, 0)
        const product = [];
        for (let i = 0; i < totalItems?.length; i++) {
            product?.push(totalItems[i] * totalprices[i]);
        }
        const totalPrice = product?.reduce((pv: any, cv: any) => pv + cv, 0)
        setQuantity(totalQuantity);
        setPrice(totalPrice)
    }, [cartData])
    return (
        <Fragment>
            <Box className="Main-ListCartBox">
                <Box className="ListandPaymentBox">
                    <Box className="fullList-box">
                        {cart?.length > 0 ? cart?.map((items: any, index: any) => (
                            <Fragment key={index}>
                                <ProductListView translation={translation} quantityCart={quantityCart} setQuantityCart={setQuantityCart} listData={items} deleteData={deleteData} updateCartItem={updateCartData} />
                            </Fragment>
                        )) : <div className="Data-Not-Found">{translation?.Nocart}</div>}
                    </Box>
                    <Box>
                        <CouponandPayment translation={translation} totalPrice={price} totalQuantity={quantity} />
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
}