import React, { useEffect, useState } from "react";
import "./videoBox.css";
import { Box, Skeleton } from "@mui/material";
import { homeVideoModel } from "../../../models/home-models";
import ReactPlayer from "react-player";
import { videoLink } from "../../../utils/commonFunctions";

interface VideoUrl {
  Video: Array<homeVideoModel>,
  isFromAbout?: any
  loading?: any;
}
export default function VideoBox({ loading, Video, isFromAbout }: VideoUrl) {
  return (
    <>
      {loading ?
        <Box className="video-view">
          {Video?.map((items, index) => (
            <ReactPlayer config={{ file: { attributes: { preload: "auto" } } }} url={!isFromAbout ? videoLink(items.url) : items.url} key={items.id} controls
              width="100%" height="100%" />
          ))}
        </Box> : <Skeleton animation="wave" variant="rounded" width="100%" height="150px" />}
    </>
  );
}
