import React, { Suspense } from "react";
import { Box } from "@mui/material";
import "./terms_conditionPage.css";
import Header from "../../components/Common-Component/Header/header";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
const TermsCondition = React.lazy(() => import('../../components/Common-Component/Terms-Conditions/termsConditions'));

interface GlobalTranslat {
    translation: any
}
export default function TermsandConditions({ translation }: GlobalTranslat) {
    return (
        <React.Fragment>
            <Box className="ConditionHeader">
                <Header darkTheme={true} translation={translation}/>
            </Box>
            <Box>
                <Suspense fallback={<div>{translation?.Loading}</div>}>
                    <TermsCondition translation={translation}/>
                </Suspense>
            </Box>
            <BookConsultation translation={translation}/>
            <Box className="Footer">
                <Footer translation={translation}/>
                <MostSearched translation={translation}/>
                <br />
            </Box>
        </React.Fragment>
    );
}
