import React, { useEffect } from "react";
import "./EventsDetailsPage.css";
import { Box, } from "@mui/material";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import Header from "../../components/Common-Component/Header/header";
import AboutEvent from "../../components/EventsDetailsPage-Component/AboutEvents/aboutEvents";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { eventDetailData } from '../../store/event/event-actions';
import { useLocation } from "react-router";
import Seo from "../../components/Common-Component/SEO/seo";

interface GlobalTranslat {
    translation: any
}
export default function EventDetailsPage({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const eventDetail = useAppSelector(state => state?.eventDetail);
    const location = useLocation();

    //NOTE: Life cycle Methods
    useEffect(() => {
        const storyLink = window.location.href.split("cancer/events/")[1];
        if (location.state?.id) {
            let data = {
                eventId: location.state?.id
            }
            dispatch(eventDetailData(data));
        }
        else {
            let data = {
                slug: storyLink
            }
            dispatch(eventDetailData(data));
        }
    }, []);

    //NOTE: UI Implementation
    return (
        <Box className="MainDetailsBox">
            <Seo translation={translation} metaData={eventDetail.metadatas} />
            <Box className="Header-Top-Box">
                <Box className="details-Header">
                    <Header darkTheme={true} translation={translation}/>
                </Box>
            </Box>
            <Box>
                <AboutEvent translation={translation} eventDetails={eventDetail?.data} />
            </Box>
            <BookConsultation translation={translation}/>
            <Box className="Footer">
                <Footer translation={translation}/>
                <MostSearched translation={translation}/>
                <br />
            </Box>
        </Box>
    );
}
