import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import "./360degree.css";
import VideoBox from "../../Common-Component/VideoBox/videoBox";
import { homeVideoModel } from "../../../models/home-models";
import { newsModel } from "../../../models/home-models";
import ConnectForm from "../../Common-Component/ConnectForm/connectForm";
import { TalkBtns } from "./talkBtns";
// import AppImages from "../../../images/AppImages";
// import NewsPartner from "../../Common-Component/NewsPartners/newsPartners";
interface VideoUrl {
    Video: Array<homeVideoModel>
    NewsPartners: Array<newsModel>
    translation?: any
    onPressSubmit?: any
    setConnectDetails: any
    connectDetails: any
    setSubmited: any;
    submited: any;
    loading: any;
}
function Degree360({ Video, NewsPartners, loading, translation, onPressSubmit, setConnectDetails, connectDetails, setSubmited, submited }: VideoUrl) {
    return (
        <Box className="main-Box">
            <Box className="Videoand360-Box">
                <Box className="AllTxt-BtnBox">
                    <Typography className="degree-360">
                        {!translation?.cancermoreeffective ? "Make cancer treatment more effective" : translation?.cancermoreeffective}
                    </Typography>
                    <Typography className="Improve">
                        {!translation?.oncologycancercoachsupport ? "Through integrative oncology & 24/7 cancer coach support" : translation?.oncologycancercoachsupport}
                    </Typography>
                    <Box className="banner1000Box">
                        <Box className="banneBoxrWidth">
                            <TalkBtns loading={loading} translation={translation} />
                            <ConnectForm loading={loading} setConnectDetails={setConnectDetails} connectDetails={connectDetails}
                                setSubmited={setSubmited} submited={submited} translation={translation} onPressSubmit={onPressSubmit} />
                        </Box>
                    </Box>
                </Box>
                <Box className="video_banner-Box">
                    <Box className="Video-Box">
                        <VideoBox Video={Video} loading={loading} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default memo(Degree360);
