import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  StoryFilterlModel } from "../../models/story-models";

const initialStoryFilterState: StoryFilterlModel = {
    status: 0,
    cancer_category: [],
    message: '',
    topic_list: [],
    metadatas: [] 
}

const storyFilterSlice = createSlice({
    name: 'storyFilter',
    initialState: initialStoryFilterState,
    reducers: {
        setStoryFilterData(state, action: PayloadAction<StoryFilterlModel>) {
            state.status = action.payload?.status;
            state.cancer_category = action.payload?.cancer_category;
            state.message = action.payload?.message;
            state.topic_list = action.payload?.topic_list;
            state.metadatas = action.payload?.metadatas;
        }
    }
})
export default storyFilterSlice;