import React, { useEffect, useState, Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";
import "./productsDetailsPage.css";
import Header from "../../components/Common-Component/Header/header";
import ProductDetailsHead from "../../components/ProductsDetails-Component/productDetailsHead/productsDetailsHead";
import MostSearched from '../../components/Common-Component/MostSearched/mostSearched';
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { fetchProductDetail, fetchProductReview } from "../../store/product/product-actions";
import { useLocation } from "react-router";


interface GlobalTranslat {
    translation: any
}
const ProductDetails = React.lazy(() => import('../../components/ProductsDetails-Component/ProductsDetails/productDetails'));
export default function ProductsDetailsPage({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const productDetailData = useAppSelector(state => state?.productDetail);
    const [page, setPage] = useState(1);
    const [alert, setAlert] = useState(false);
    const product = useLocation();
    useEffect(() => {
        let data = {
            id: product?.state?.id,
            page: page
        }
        const productLink = window.location.href.split("products/")[1];
        if (product?.state?.id !== undefined) {
            let data = {
                product_id: product?.state?.id,
            }
            dispatch(fetchProductDetail(data));
        } else {
            let data = {
                product_slug: productLink,
            }
            dispatch(fetchProductDetail(data));
        }
        dispatch(fetchProductReview(data));
        window.scrollTo(0, 0);
    }, [product?.state?.id]);

    const HandleAlert = () => {
        setAlert(true)
        setTimeout(() => {
            setAlert(false)
        }, 2000)
    }

    return (
        <React.Fragment>
            <Box className="Products-Header">
                <Header alert={alert} darkTheme={true} translation={translation} />
            </Box>
            <ProductDetailsHead translation={translation} productDetails={productDetailData?.data} />
            <Suspense fallback={<CircularProgress color="secondary" className="loader-position-DR" />}>
                <ProductDetails translation={translation} handleAlert={HandleAlert} productDetails={productDetailData?.data} similarProduct={productDetailData?.similar_product} />
            </Suspense>
            <BookConsultation translation={translation} />
            <Box className="Footer">
                <Footer translation={translation} />
                <MostSearched translation={translation} />
                <br />
            </Box>
        </React.Fragment>
    )
}
