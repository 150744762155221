import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import "./downloadApp.css";
import AppImages from "../../../images/AppImages";
import Popover from '@mui/material/Popover';
import { googlePlay, appStore, } from '../../../constants/AppConstants'
import { downloadAppPage } from "../../../values/paths";


interface Translate {
    translation: any
}
export default function DownloadApp({ translation }: Translate) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    function handleClose() {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <React.Fragment>
            <Box className="DownloadApp-Box">
                <Box className="downloadApp-textBox">
                    <Typography className="DownloadApp-Text">
                        {translation?.DownloadAppcare}
                    </Typography>
                    <Typography className="helpyou-Text">
                        {translation?.OurinhouseTeam}
                    </Typography>
                    <Button disableRipple href={downloadAppPage} target="_blank" className="dwnld-Btn">
                        <Typography className="dwnld-txt">
                            {translation?.Downloadnow}
                        </Typography>
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className="popover-margin"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                        <Box className="Download-AppPlay-store">
                            <a href={googlePlay} target="_blank" rel="noreferrer" className="Download-PlayStore-btn" >
                                <img src={AppImages.GooglePlaystore} alt={translation?.Playstore} className="Footer-Store-btns-box" />
                            </a>
                            <a href={appStore} target="_blank" rel="noreferrer" className="Download-AppStore-btn" >
                                <img src={AppImages.AppStore} alt={translation?.Appstore} className="Footer-Store-btns-box" />
                            </a>
                        </Box>
                    </Popover>
                </Box>
                <Box className="dwnld-App-imageBox">
                    <img src={AppImages.DownLoadApp2} alt={translation?.Downloadapp} className="dwnldApp-imgsize" />
                </Box>
            </Box>
        </React.Fragment>
    );
}