import axios from 'axios';

const apiVersion = 'api/v3/'

const sessionLanguage = sessionStorage.getItem("language");
const mainLanguage = sessionLanguage === "" || sessionLanguage === null ? "" : `${sessionLanguage}/`
console.log("sessionLanguage--->",mainLanguage)
export default ()=>{
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL + mainLanguage + apiVersion
    })
}