import React, { Fragment } from "react";
import { Box, Button, Typography, Breadcrumbs, Avatar, CircularProgress } from "@mui/material";
import "./consultationHead.css";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import { Link } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { doctorInfoModel, hospitalDescModel } from "../../../models/doctor-models";
import { BackPage } from "../../../values/helper";
import { homePage } from "../../../values/paths";

interface DoctorDetails {
    doctorInfo: Array<doctorInfoModel>
    doctorHospital: Array<hospitalDescModel>
    load: any
    translation: any
}

export default function ConsultationHead({ translation, load, doctorInfo, doctorHospital }: DoctorDetails) {
    return (
        <Fragment>
            <TopHeading>
                <Box className="ConsultInfo-MainBox">
                    {load ? <CircularProgress color="secondary" className="loader-position-DR" /> : <Box className="Navigation-Box">
                        <Breadcrumbs separator={<NavigateNextIcon className="Navigation-Seprator" />}>
                            <Link href={homePage} className="Navigation-Labels">{translation?.Home}</Link>
                            <Button onClick={() => BackPage()} className="Navigation-Labels">{translation?.consultDoctor}</Button>
                            {doctorInfo?.map((name, index) => (
                                <Button key={name?.doctorName} className="Navigation-Labels">{name.doctorName}</Button>))}
                        </Breadcrumbs>
                        {doctorInfo?.map((info, index) => (
                            <Box key={info?.doctorName} className="Doctors-ProfileBox">
                                <Avatar src={info.doctorImage} alt={info?.doctorName} className="doctor-infoimage" />
                                <Box className="Dr-all-infoBox">
                                    <Typography className="doctor-infoname">{(info?.doctorName)}</Typography>
                                    <Box className="Information-BadgeBox">
                                        {info?.appt_category?.categoryName && <Button disabled className="PaediatricOnco-Badge">
                                            <Typography className="Badges-Text">{(info?.appt_category?.categoryName)}</Typography>
                                        </Button>}
                                        {info?.expYears && <Button disabled className="years55-Badge">
                                            <Typography className="Badges-Text">{info?.expYears} {translation?.Years}</Typography>
                                        </Button>}
                                    </Box>
                                    <Typography className="BreastCancer-Text">{info?.educationSummary}</Typography>
                                    <Box className="HospitalsInfo-Box">
                                        <Button aria-label="hospital-Name" className="ChetpetChennai-Text">{doctorHospital[0]?.zen_hospital?.name}</Button>
                                        {doctorHospital?.length >= 1 && <Typography className="VSHospitals-Text">
                                            {doctorHospital[1]?.zen_hospital?.name} {doctorHospital?.length > 2 && doctorHospital?.length - 2 + translation?.more}
                                        </Typography>}
                                    </Box>
                                </Box>
                            </Box>))}
                    </Box>}
                </Box>
            </TopHeading>
        </Fragment>
    )
}