import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import Messages from './Messages';
import MessageInput from './MessageInput';
import './chatbotPage.css';


//const socket = io('http://localhost:4000');
function ChatbotPage() {
  const [isConnected, setIsConnected] = useState(false);
  /*
    useEffect(() => {
      const newSocket = io(`http://localhost:4000`);
      setSocket(newSocket);
      return () => newSocket.close();
    }, []);
  */
  // useEffect(() => {
  //   const newSocket = io(`http://localhost:4000`);
  //   setSocket(newSocket);
  // }, [setSocket])
  //const socket = io('http://localhost:4000');
  //const socket = io('https://preprod-react.zenonco.io');
  const socket = io('http://preprod-react.zenonco.io/api/webapi/get_chat_reply');  //node api url where to send our request call
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      console.log('breakpoint__1');
    }
    function onDisconnect() {
      //setIsConnected(false);
      console.log('breakpoint__2');
    }
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  return (
    <div className="App">
      <header className="app-header">
        React Chat
      </header>
      {isConnected ? (
        <div className="chat-container">
          <Messages socket={socket} />
          <MessageInput socket={socket} />
        </div>
      ) : (
        <div>Not Connected</div>
      )}
    </div>
  );
}

export default ChatbotPage;