import React, { Suspense } from 'react'
import { Box, CircularProgress } from '@mui/material'
// import AddressAdd from '../../components/ProductAddressPage-component/AddressAdd/addressAdd'
import Header from '../../components/Common-Component/Header/header'
import Footer from '../../components/Common-Component/Footer/footer'
// import CouponandPayment from '../../components/Common-Component/CouponandPayment/couponandPayment'
import BookConsultation from '../../components/Common-Component/BookConsultation/bookConsultation'
import MostSearched from '../../components/Common-Component/MostSearched/mostSearched'
import AddressHead from '../../components/Common-Component/AddressHead/addressHead'
import './productaddressPage.css'
import { useLocation } from 'react-router'


const AddressAdd = React.lazy(() => import('../../components/ProductAddressPage-component/AddressAdd/addressAdd'))
interface GlobalTranslat {
  translation: any
}
export default function ProductAddressPage({ translation }: GlobalTranslat) {
  const location = useLocation();
  const totalQuantity = location?.state?.totalQuantity
  const totalPrice = location?.state?.totalPrice
  const addressData = location?.state?.info
  const edit = location?.state?.edit
  return (
    <>
      <Box className="ProductsAddress-Header">
        <Header darkTheme={true} translation={translation} />
      </Box>
      <AddressHead translation={translation} />
      <Suspense fallback={<CircularProgress color="secondary" className="loader-position-DR" />}>
        <AddressAdd translation={translation} totalPrice={totalPrice} totalQuantity={totalQuantity} data={addressData} edit={edit} />
      </Suspense>
      <BookConsultation translation={translation} />
      <Box className="Footer">
        <Footer translation={translation} />
        <MostSearched translation={translation} />
        <br />
      </Box>
    </>
  )
}

