import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import "./selectAddressPage.css";
import Header from "../../components/Common-Component/Header/header";
import MostSearched from '../../components/Common-Component/MostSearched/mostSearched';
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import SelectAddress from "../../components/SelectAddressPage-Component/SelectAddress/selectAddress";
import AddressHead from "../../components/Common-Component/AddressHead/addressHead";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { GETAddressDataModel, createOrderModel } from "../../models/product-models";
import { getAddress, initiateRazorPay, createOrder } from "../../store/product/product-actions";
import Cookies from "universal-cookie";
import useRazorpay from "react-razorpay";
import { useLocation } from "react-router";
import { useNavigate } from 'react-router';
import { thankyouPage } from "../../values/paths";

interface GlobalTranslat {
    translation: any
}

export default function SelectAddressPage({ translation }: GlobalTranslat) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const Razorpay = useRazorpay();
    const [addressData, setAddressData] = useState<Array<GETAddressDataModel>>([]);
    const [selectedAddress, setSelectedAddress] = useState<GETAddressDataModel>();
    const addressListData = useAppSelector(state => state?.addressList);
    const deleteAddress = useAppSelector(state => state?.deleteAddress);
    const razorPayData = useAppSelector(state => state?.inititeRazorPay);
    const createOrderRes: createOrderModel = useAppSelector(state => state?.createOrder);
    const cookies = new Cookies();
    const userID = cookies.get('userId')
    const location = useLocation();
    const totalQuantity = location?.state?.totalQuantity
    const totalPrice = location?.state?.totalPrice
    useEffect(() => {
        if (userID !== undefined) {
            dispatch(getAddress(userID));
        }
    }, [deleteAddress]);

    useEffect(() => {
        setAddressData(addressListData.data)
    }, [addressListData.data])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const createOrderData = (data: any) => {
        const obj = {
            additionalInfo: "only for",
            addressId: selectedAddress?.id,
            payment_id: data.razorpay_payment_id,
            entity: "payment",
            amount: data?.data?.amount?.toString(),
            currency: "INR",
            status: 1,
            order_id: data?.data?.id,
            captured: 1,
            contact_no: selectedAddress?.numberShipTo,
            error_code: "ss",
            sub_total: data?.data?.amount?.toString(),
        }
        console.log("razorPay data", obj)
        dispatch(createOrder(obj));
    }
    useEffect(() => {
        if (razorPayData.razorpay_config.length > 1) {
            onPaymentClick()
            createOrderData(razorPayData)
        }
    }, [razorPayData])

    const handlePaymentSuccess = (reponse: any) => {
        console.log('Payment successful! Product Page --', reponse);
        navigate(thankyouPage, { replace: true })
        navigate(0);
    }

    const onPaymentClick = () => {
        const options = {
            key: razorPayData.razorpay_config[1]?.value,
            amount: razorPayData?.data?.amount?.toString(),
            currency: "INR",
            name: "ZenOnco.io",
            description: "",
            image: 'https://zenonco-web-image.s3.ap-south-1.amazonaws.com/assets/images/Logo.svg',
            order_id: razorPayData?.data?.id,
            prefill: {
                name: cookies.get('name'),
                email: cookies.get('Email'),
                contact: cookies.get('number'),
            },
            handler: (response: any) => {
                handlePaymentSuccess(response);
            },
            notes: {
                address: "",
            },
            theme: { color: '#104462' }
        };
        const rzp1 = new Razorpay(options);
        rzp1.open();
    }
    const initiateMethod = (amount: number) => {
        let data = {
            amount: amount,
            currency: 'INR'
        }
        dispatch(initiateRazorPay(data));
        console?.log("amount--->", amount)
    }
    console.log("creatOrderRes--->", createOrderRes)

    return (
        <>
            <Box className="SelectAddress-Header">
                <Header darkTheme={true} translation={translation} />
            </Box>
            <AddressHead translation={translation} />
            <SelectAddress translation={translation} totalPrice={totalPrice} totalQuantity={totalQuantity} addressData={addressData} initiateMethod={initiateMethod} setSelectedAddress={setSelectedAddress} />
            <BookConsultation translation={translation} />
            <Box className="Footer">
                <Footer translation={translation} />
                <MostSearched translation={translation} />
                <br />
            </Box>
        </>
    );
}