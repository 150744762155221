import React, { Fragment } from 'react'
import './wonAwards.css'
import Exportcounsult from '../../Common-Component/CousultExpDoctor/exportconsult'
import { Box, Typography, Skeleton } from '@mui/material'

export default function WonAwards(Props: { awards?: any, awardlist?: any, translation: any }) {
  return (
    <Fragment>
      <Box className='WonAwards-carosualbox'>
        <Box className="Awards-Text-box">
          <Typography className="WonAwards-Text">
            {Props?.translation?.AwardsForZen}
          </Typography></Box>
        {Props.awardlist?.length ? <Exportcounsult translation={Props?.translation} doctorsList={Props.awards} awardlist={Props.awardlist} /> : <Skeleton variant="rounded" animation="wave" className="skeletonRect" />}
      </Box></Fragment>
  );
}

