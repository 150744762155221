import React from "react";
import "./whatsAppBtn.css";
import { Button } from "@mui/material";
import AppImages from "../../../images/AppImages";
import { whatsapp } from "../../../constants/AppConstants";

interface animated {
    animation: any
}

export default function WhatsappBtn({ animation }: animated) {
    return (
        <Button href={whatsapp} disableRipple className={"WhatsApp-StickyBtn"} target="_blank" rel="preconnect noreferrer">
            <img src={AppImages?.whatsappButton} alt="WhatsApp" className="WhatsApp-Stickyimg" />
        </Button>
    );
}