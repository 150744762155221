import React, { Fragment } from 'react'
import './exportcounsult.css'
import { Typography, Button, Box, useMediaQuery } from '@mui/material'
import carousel from './export.json'
import { ButtonGroupProps } from 'react-multi-carousel/lib/types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Carousel from 'react-multi-carousel';
import SearchResponsive from "../../HowWeCanHelp-Components/searchResponse.json";
import { doctorDetailsPageSlug, eventsDetailsPageSlug, productDetailsPageSlug } from '../../../values/paths';

function Exportcounsult(Props: { translation: any, aboutPage?: any, searchPage?: any, doctorsList?: any, doctordata?: any, productlist?: any, doctordatass?: any, eventListdata?: any, awardlist?: any, centerList?: any, careCenters?: any }) {
  const data = Props.doctorsList
  const mornthen600 = useMediaQuery('(min-width:601px)');
  const ButtonGroup = ({ next, previous, goToSlide, carouselState, ...rest }: ButtonGroupProps) => {
    const totalSlide = carouselState?.slidesToShow !== undefined && carouselState?.currentSlide !== undefined ?
      carouselState?.slidesToShow + carouselState?.currentSlide : null
    const lastItem = totalSlide !== null && carouselState?.totalItems ? totalSlide < carouselState?.totalItems : null
    return (
      <>
        {carouselState?.slidesToShow !== undefined &&
          <>
            {carouselState?.slidesToShow < 3 ||
              Props.productlist?.length > 3 || Props.awardlist?.length > 3 || Props.doctordata?.length > 3 || Props.eventListdata?.length > 3 || Props.doctordatass?.length > 3 ?
              <div className={Props.awardlist ? "expert-Box2" : Props?.searchPage ? "searchpagebtn-box" : Props.careCenters ? "careCenterbtn-Box" : "expert-Box"}>
                {carouselState?.currentSlide !== 0 && <Button tabIndex={-1} aria-label='swipeLeft' disableRipple className={Props.awardlist ? "expertCButton-prev2" : Props?.searchPage ? "searchpage-prev" : Props.centerList ? "centerList-prev" : "expertCButton-prev"} onClick={previous}>
                  <NavigateBeforeIcon className="expertCButtonicon" />
                </Button>}
                {lastItem === null || lastItem ? <Button tabIndex={-1} aria-label='swipeRight' disableRipple className={Props.awardlist ? "expertCButton-nextawd2" : Props?.searchPage ? "searchpage-next" : Props.centerList ? "centerList-next" : "expertCButton-next"} onClick={next}>
                  <NavigateNextIcon className="expertCButtonicon" />
                </Button> : null}
              </div>
              : null
            }
          </>
        }
      </>
    );
  };

  return (
    <Fragment>
      <div aria-hidden="false">
        {data?.length > 0 &&
          <Carousel
            ssr
            responsive={Props.awardlist ? carousel.responsive2 : Props?.searchPage ? SearchResponsive.responsive : Props.centerList ? carousel.responsive3 : carousel.responsive}
            renderButtonGroupOutside
            customButtonGroup={<ButtonGroup />}
            arrows={false}>
            {
              data?.map((doctor?: any, index?: number) => (
                <Box tabIndex={-1} aria-hidden="false" key={doctor?.id} className={Props.eventListdata ? "expert-boxsd" : Props.productlist ? "expert-boxmd" : Props.awardlist ? "expert-boxawrd" : Props?.searchPage ? "expert-boxmd" : Props.centerList ? "centerListCard-box" : "expert-box"}>
                  {
                    Props.doctordata ?
                      <>
                        <img tabIndex={-1} src={doctor?.doctorImage} className="expert-image" alt={Props?.translation?.expertImage} loading="lazy" />
                        <Typography className="expert-nametext">{doctor?.doctorName}</Typography>
                        <Typography className='expert-typetext'>{doctor?.designation}</Typography>
                        <Box className='expert-pricebox'>
                          <Typography className='expert-rsymbol'>{Props.translation?.['₹']}</Typography>
                          <Typography className='expert-rs'>{doctor?.fee}</Typography>
                        </Box>
                        <Box className='expert-pricebox'>
                          <Button tabIndex={-1} disableRipple className='expert-consultbtn' href={doctorDetailsPageSlug(doctor?.slug)}>
                            <Typography className="expert_consultbtntext">{Props.translation?.Consult}</Typography></Button>
                        </Box>
                      </> : Props.productlist ?
                        <>
                          <img src={doctor?.image} className="expert-imagemd" alt={Props?.translation?.ProductsImages} loading="lazy" />
                          <Typography className="expert-nametextmd">{doctor?.name}</Typography>
                          <Typography className='expert-typetextmd'>{doctor?.productQuantity}</Typography>
                          <Box className='medicines-typetext'>
                            <Box className='expert-pricebox'>
                              <Typography className='expert-rsymbolmd'>{Props.translation?.['₹']}</Typography>
                              <Typography className='medicines-rss'>{doctor?.price}</Typography>
                            </Box>
                            <Box className='expert-pricebox'>
                              <Typography className='expert-rsymbolmd2'>{Props.translation?.['₹']}</Typography>
                              <Typography className='medicines-rs'>{doctor?.regular_price}</Typography>
                            </Box>
                          </Box>
                          <Box className='expert-pricebox'>
                            <Button tabIndex={-1} href={productDetailsPageSlug(doctor?.slug)} disableRipple className='medicines-consultbtn' >
                              <Typography className="expert_consultbtntext">{Props.translation?.Buynow}</Typography></Button>
                          </Box>
                        </> : Props.doctordatass ?
                          <>
                            <img src={doctor?.doctorImage} className="expert-image" alt={Props?.translation?.DrImage} loading="lazy" />
                            <Typography className="expert-nametext2">{doctor?.doctorName}</Typography>
                            <Typography className={Props?.searchPage ? 'searchPage-typetext2' : 'expert-typetext2'}>{doctor?.designation} | {doctor?.expYears} {Props.translation?.years}</Typography>
                            <Box className='expert-pricebox' >
                              <Box className='expert-rs2'>{doctor?.sub_district} |
                                <Typography className='expert-rsymbol2'>{Props.translation?.['₹']}</Typography>
                                {doctor?.fee} </Box>
                            </Box>
                            <Box className='expert-pricebox'>
                              <Button tabIndex={-1} disableRipple className='expert-consultbtn' href={doctorDetailsPageSlug(doctor?.slug)}  >
                                <Typography className="expert_consultbtntext">{Props.translation?.Consult}</Typography></Button>
                            </Box>
                          </> : Props.eventListdata ?
                            <>
                              <img src={doctor?.image} className="expert-imagesd" loading="lazy" alt={Props?.translation?.EventImage} />
                              <Typography className='expert-nametextsd'>{doctor?.name}</Typography>
                              <Box className='expert-pricebox'>
                                <Button tabIndex={-1} disableRipple className='expert-consultbtnsd' href={eventsDetailsPageSlug(doctor?.slug)}>
                                  <Typography className="expert_consultbtntext">{Props.translation?.join}</Typography></Button>
                              </Box>
                            </> : Props.awardlist?.length > 0 ?
                              <>
                                <a tabIndex={-1} href={doctor?.url} target="_blank" className='expert-linkawd' rel="noreferrer">
                                  <img src={doctor?.image} className={index !== undefined ? index < 2 ? "expert-imageawd1" : "expert-imageawd" : "expert-imageawd"} alt="" loading="lazy" />
                                  {!Props?.aboutPage && <img src={doctor?.logo} className='expert-brandlogo' alt="" loading="lazy" />}
                                  <Typography className='expert-nametextawd'>{Props?.aboutPage ? doctor?.text : doctor?.name}</Typography>
                                </a>
                              </> : !mornthen600 ?
                                <>
                                  {
                                    Props.centerList ?
                                      <Box>
                                        <Box key={doctor?.id} className={index === 1 ? "visitCenters-Box1" : index === 2 ? "visitCenters-Box2" : "visitCenters-Box"}>
                                          <Box className="center_image-Box">
                                            {Props.careCenters?.centerImages?.img1?.map((img: any, i: number) => (
                                              index === i && <img key={img?.image} src={require((`../../../images/centerImages/${img.image}`))} className="center-img1" alt={doctor?.sub_district} />))}
                                          </Box>
                                          <Box className="icon_address-Box">
                                            {Props.careCenters?.centerImages?.icons?.map((doctor: any, ind: number) => (index === ind &&
                                              <img key={ind} className="city-icon" src={require((`../../../images/centerImages/${doctor?.icn}`))} alt="" loading='lazy' />))}
                                          </Box>
                                          <Typography className="cancer_Center-txt">{doctor?.sub_district} {Props.translation?.CancerCenter}</Typography>
                                          <Typography className={index === 1 ? "area_denmark-txtdark" : "area_denmark-txt"}>{doctor?.clinic}</Typography>
                                          <a tabIndex={-1} target="_blank" href={doctor?.gmap} className="view_map-Btn" rel="noreferrer">
                                            <Typography className="view_map-txt">{Props.translation?.ViewonMap}</Typography>
                                          </a>
                                        </Box>
                                      </Box>
                                      : null
                                  }
                                </> : null
                  }
                </Box>
              ))
            }
          </Carousel>
        }
      </div>
    </Fragment>
  );
}
export default Exportcounsult;
