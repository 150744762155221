import React, { Fragment, useEffect, useState } from 'react'
import MyReport from '../homePage-Component/MyReport/myReport'
import { Box } from '@mui/material'
import './zioperHead.css'
import TopHeading from '../Common-Component/TopHeadingComponent/topHeading'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { getZioparData } from '../../store/faq/faq-actions'
interface Translate {
  translation: any
  loading: any
}
export default function ZioparHead({ loading, translation }: Translate) {
  const dispatch = useAppDispatch();
  const zioparData = useAppSelector(state => state?.ziopar);
  useEffect(() => {
    dispatch(getZioparData());
  }, []);
  return (
    <Fragment>
      <TopHeading>
        <Box className='ziopar-mainbox'>
          <MyReport loading={loading} translation={translation} zioparPage={true} cancer_stage={zioparData?.cancer_stage}
            cancer_type={zioparData?.cancer_type}
            disease={zioparData?.other_disease}
            performance={zioparData?.performance}
            treatment={zioparData?.prior_treatment}
            symptoms={zioparData?.symptoms} />
        </Box>
      </TopHeading>
    </Fragment>
  );
}
