import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { DoctorTalkFilterlModel, StoryDataModel, StoryDetailModel, StoryFilterlModel } from '../../models/story-models'
import storySlice from './story-slice'
import storyService from '../../service/storyService';
import storyDetailSlice from './storyDetail-slice'
import storyFilterSlice from './storyFilter-slice'
import doctorTalkFilterSlice from './doctortalkFilter-slice'

export const storyActions = storySlice.actions
export const storyDetailActions = storyDetailSlice.actions
export const storyFilterActions = storyFilterSlice.actions
export const doctorTalkFilterActions = doctorTalkFilterSlice.actions

export const fetchStoryData = (url: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: StoryDataModel = await storyService.getStory(url);
        dispatch(storyActions.setStoryData(response))
    }
}

export const fetchStoryDetailData = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    console.log("actionStoryData---->", data);
    return async (dispatch, getState) => {
        const response: StoryDetailModel = await storyService.getDetailStory(data);
        dispatch(storyDetailActions.setStoryDetailData(response))
    }
}

export const fetchStoryFilterlData = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: StoryFilterlModel = await storyService.getStoryDropdown();
        dispatch(storyFilterActions.setStoryFilterData(response))
    }
}

export const fetchDoctorTalkFilterlData = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: DoctorTalkFilterlModel = await storyService.getDoctorTalkDropdown();
        dispatch(doctorTalkFilterActions.setSDoctorTalkFilterData(response))
    }
}