import React from "react";
import "./survivorsStories.css";
import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import EventBlogBox from "../../Common-Component/EventBlogbox/eventBlogBox";
import Response from "../thankyouResponsive.json";
import { storyModel } from "../../../models/doctor-models";
// import SearchResponsive from "../../ThankYouPage-Component/thankyouResponsive.json";
import { ButtonGroupProps } from 'react-multi-carousel/lib/types';
import CarouselButton from "../../Common-Component/carouselButtons/carouselButtons";

interface Storiesdata {
    survivorStories: Array<storyModel>
    translation: any
}

export default function SurvivorStories({ translation, survivorStories }: Storiesdata) {
    const ButtonGroup = ({ next, previous, goToSlide, carouselState, ...rest }: ButtonGroupProps) => {
        const totalSlide = carouselState?.slidesToShow && carouselState?.currentSlide ?
            carouselState?.slidesToShow + carouselState?.currentSlide : null
        const lastItem = totalSlide !== null && carouselState?.totalItems ? totalSlide < carouselState?.totalItems : null
        return (
            <>
                {survivorStories.length > 3 &&
                    <CarouselButton carouselState={carouselState} lastItem={lastItem} previous={previous} next={next} />
                }
            </>
        )
    }
    return (
        <Box className="survivorsMain-box">
            <Typography className="heading-Survivor-txt">{translation?.CancerSurvivorStories}</Typography>
            <Box className="allBlogs-carouselBox">
                <Carousel
                    ssr
                    renderButtonGroupOutside
                    customButtonGroup={<ButtonGroup />}
                    arrows={false}
                    className="all-Survivorscards"
                    responsive={Response.responsive}>
                    {survivorStories.map((items, index) => (
                        <Box key={items.id} className="cardHoverBox">
                            <EventBlogBox translation={translation} index={index} survivorsPage={true} items={items} />
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </Box>
    );
}