import React, { memo } from "react";
import "./visitCenters.css";
import { Box, Link, Typography } from "@mui/material";
import careCenters from "./centersimg.json";
import { careCenterModel } from "../../../models/home-models";
import useMediaQuery from '@mui/material/useMediaQuery';
import Exportcounsult from '../../Common-Component/CousultExpDoctor/exportconsult'

interface centreCenterList {
    centerList: Array<careCenterModel>
    translation: any
}

function VisitCenters({ centerList, translation }: centreCenterList) {
    const moreThan600 = useMediaQuery('(min-width:601px)');
    return (
        <div className="Centers_div">
            <Box className="vicitCenterHeading_main-Box">
                <Typography className="ViciteCenter-heading">{translation?.VisitourCenter}</Typography>
                <Box className="visitCenter-statebox">
                    {centerList.map((item, index) => (
                        <Typography key={item?.id} className="City-name">{item.sub_district} {index !== 2 && "|"}</Typography>))}
                </Box>
                <Typography className="Info-name">{translation?.Anti_Supplements_Onco}</Typography>
                {moreThan600 && <><Box className="visitCenters_main-Box">
                    {centerList?.map((items, index) => (
                        <Box key={items?.id} className={index === 1 ? "visitCenters-Box1" : index === 2 ? "visitCenters-Box2" : "visitCenters-Box"}>
                            <Box className="center_image-Box">
                                {careCenters?.centerImages?.img1?.map((img, i) => (
                                    index === i && <img key={i} src={require((`../../../images/centerImages/${img.image}`))} className="center-img1" alt={items?.sub_district} />))}
                            </Box>
                            <Box className="icon_address-Box">
                                {careCenters?.centerImages?.icons?.map((icons, ind) => (index === ind &&
                                    <img key={icons?.icn} src={require((`../../../images/centerImages/${icons?.icn}`))} className="city-icon" alt={items?.sub_district} />))}
                            </Box>
                            <Typography className="cancer_Center-txt">{items?.sub_district} {translation?.CancerCenter}</Typography>
                            <Typography className="area_denmark-txt">{items?.clinic}</Typography>
                            <Link target="_blank" href={items?.gmap} className="view_map-Btn">
                                <Typography className="view_map-txt">{translation?.ViewonMap}</Typography></Link>
                        </Box>))}
                </Box></>}
            </Box>
            {!moreThan600 && <Exportcounsult translation={translation} doctorsList={centerList} centerList={centerList} careCenters={careCenters} />}
        </div>
    );
}
export default memo(VisitCenters);