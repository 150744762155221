import React, { useEffect, useState } from "react";
import { Box, Button, Typography, useMediaQuery, Badge, Alert, Menu, MenuItem, Link } from "@mui/material";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import "./header.css"
import AppImage from '../../../images/AppImages';
import { whatsapp } from '../../../constants/AppConstants'
import MobileHeader from "../MobileHeader/mobileHeader";
import { useAppSelector, useAppDispatch } from "../../../hooks/redux-hooks";
import { getCart } from "../../../store/product/product-actions";
import TreatAndOtp from "../Common-Modals/TreatModelwithOTP/TreatOtp";
import languages from "./languges.json";
import { aboutPage, blogListPage, doctorListPage, homePage, productListPage, productsCartPage, searchPage } from "../../../values/paths";

interface IToolbarProps {
  darkTheme?: boolean;
  cartPage?: any;
  alert?: any;
  journey?: any;
  apointment?: any;
  translation: any;
  loading?: any
}
export default function Header(Props: IToolbarProps) {
  const moreThan1000 = useMediaQuery("(min-width:1000px)");
  const [modal, setModal] = useState(false);
  const [badge, setBadge] = useState(0);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const cartData = useAppSelector(state => state?.cartList);
  const dispatch = useAppDispatch();
  const userID = cookies.get('userId');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  let language = sessionStorage?.getItem("language");
  const urlLanguage = window.location.pathname.split("/");

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const pathConditions = (newPath: any, selectedLanguage: any, nonEngPaths: any) => {
    if (urlLanguage[1].length > 2) {
      const InnerPagepath = window.location.pathname.replace(urlLanguage[1], `${selectedLanguage}/${urlLanguage[1]}`);
      const cleardInnerpath = InnerPagepath.replace("/", "")
      if (urlLanguage[2]?.length > 1 && selectedLanguage !== "") {
        window.location.replace(`/${cleardInnerpath}`);
      } else {
        window.location.replace(`${cleardInnerpath}`);
      }
    } else {
      if (selectedLanguage === "" || selectedLanguage === null) {
        const lanEngPath = newPath.replace("//", "")
        const finalEngPath = lanEngPath == "/" ? `${lanEngPath}` : `/${lanEngPath}`
        window.location.replace(finalEngPath);
      } else {
        window.location.replace(`/${nonEngPaths}`);
      }
    }
  }

  const handleMenuItemClick = (selectedLanguage: any) => {
    setAnchorEl(null)
    const newPath = window.location.pathname.replace(urlLanguage[1], selectedLanguage)
    const nonEngPaths = newPath.replace("/", "")
    sessionStorage.setItem("language", selectedLanguage);
    pathConditions(newPath, selectedLanguage, nonEngPaths);
    console.log("selectedLanguage--->", selectedLanguage);

  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function hadleCloseModal() {
    setModal(false);
  }

  useEffect(() => {
    if (cartData?.data?.length === undefined) {
      setBadge(0);
    } else {
      setBadge(cartData?.data?.length);
    }
  }, [cartData]);
  useEffect(() => {
    if (userID !== undefined) {
      dispatch(getCart(userID));
    }
    console.log("Splitedpath---->", urlLanguage);
  }, []);

  const handleCart = (type: any) => {
    if (cookies.get('token') === undefined) {
      navigate(productListPage);
      window.scrollTo(0, 0)
    } else {
      if (cartData?.data?.length === undefined || cartData?.data?.length === 0) {
        navigate(productListPage);
      } else {
        navigate(productsCartPage);
      }
      window.scrollTo(0, 0)
    }
  }

  return (
    <React.Fragment>
      {!moreThan1000 ?
        <MobileHeader loading={Props.loading} anchorEl={anchorEl} handleClose={handleClose} handleMenuItemClick={handleMenuItemClick} CountryLaguage={languages.CountryLaguage} open={open} handleClickListItem={handleClickListItem} language={language} translation={Props.translation} darkTheme={Props.darkTheme} cartPage={Props.cartPage} handleCart={handleCart} count={badge} />
        :
        <>
          <Box className={Props.darkTheme ? "Navbar-Box1" : "Navbar-Box"}>
            <Link href={homePage} className="LogoPosition-Box">
              <img src={Props.darkTheme ? AppImage.FooterLogo : AppImage.DarkLogo} alt={Props.translation?.ZenOcoio} className="ZenOnco-Logo" /></Link >
            <Box className="Allbutton-Box">
              <Button disableRipple className={Props.darkTheme ? "textType-btn-dark" : "textType-btn-light"} href={homePage} >{Props.translation?.Home}</Button>
              <Button disableRipple className={Props.darkTheme ? "textType-btn-dark" : "textType-btn-light"} href={doctorListPage}>{Props.translation?.Doctors}</Button>
              <Button disableRipple className={Props.darkTheme ? "textType-btn-dark" : "textType-btn-light"} href={aboutPage}>{Props.translation?.About}</Button>
              <Button disableRipple className={Props.darkTheme ? "textType-btn-dark" : "textType-btn-light"} href={blogListPage}>{Props.translation?.Blogs}</Button>
              <Button disableRipple className={Props.darkTheme ? "textType-btn-dark" : "textType-btn-light"} href={productListPage}>{Props.translation?.Products}</Button>
              <Button disableRipple className={Props.darkTheme ? "textType-btn-dark" : "textType-btn-light"} id="selectedLanguag" onClick={handleClickListItem}>
                {Props.translation?.Language}
              </Button>
              <Button disableRipple className={Props.darkTheme ? "Cart-btn-dark" : "Cart-btn-light"} onClick={handleCart}>
                <Badge className={Props.darkTheme ? "badgeColor" : "badgeColor1"} color="secondary" badgeContent={badge} showZero
                  sx={{ "& .MuiBadge-badge": { fontSize: 9, color: Props.darkTheme ? "white" : "black" } }}>
                  <ShoppingCartOutlinedIcon /></Badge>
              </Button>
              <Button disableRipple className={Props.darkTheme ? "Search-btn-dark" : "Search-btn-light"} href={searchPage}>
                <img src={Props.darkTheme ? AppImage.SearchIcon : AppImage.searchHead} alt={Props.translation?.SearchPage} className={Props.darkTheme ? "search-nav1" : "search-nav"} />
              </Button>
              <a href={whatsapp} className="AHeadLink" target="_blank" rel="preconnect noreferrer">
                <Button disableRipple variant="contained" className="Watss-up-btn">
                  <img src={AppImage.Whapsapp} alt="" className="header-whatsappicon" />
                  <Typography className="HeaderWhatsapp-Txt">{Props.translation?.WhatsApp}</Typography>
                </Button>
              </a>
            </Box>
          </Box>
          <Menu
            id="selectedLanguag"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'select Language',
              role: 'listbox',
            }}>
            {languages.CountryLaguage.map((option, index) => (
              <MenuItem
                key={option.code}
                onClick={(event) => handleMenuItemClick(option.laguage)}>
                <Box className="languageBox">
                  <img width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt={option.code} />
                  <Typography>{option.label}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Menu>
        </>}
      {Props.alert &&
        <Box className="Alert-Succes-header">
          <Alert severity="success">
            <Typography className="Alert-Succes-txt">{Props.journey ? Props.translation?.JourneyAddedSucessfully : Props.apointment ? Props.translation?.ApointmentbookedSuccessfully : Props.translation?.ItemaddedSucessfully}</Typography>
          </Alert>
        </Box>}
      <TreatAndOtp translation={Props.translation} setModal={setModal} handleCloseModal={hadleCloseModal} modal={modal} />
    </React.Fragment>
  );
}
