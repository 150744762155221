import { CONFIRMOTPModel } from "../../models/doctor-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialOTPState: CONFIRMOTPModel = {
    status: 10,
    data: {
        data: {
            data: {
                id: 0
            }, 
            token: ""
        }
    }
}

const confirmOtpSlice = createSlice({
        name: 'CONFIRMOTP',
        initialState: initialOTPState,
        reducers: {
            setConfirmOTPData(state, action: PayloadAction<CONFIRMOTPModel>) {
                state.status = action.payload?.status;
                state.data = action.payload?.data;
            }
        }
    })
export default confirmOtpSlice;