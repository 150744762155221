import { Box } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../components/Common-Component/Header/header'
import SearchHeading from '../../components/HowWeCanHelp-Components/searchHeading/searchHeading'
import SearchResult from '../../components/HowWeCanHelp-Components/searchResult/searchResult'
import BookConsultation from '../../components/Common-Component/BookConsultation/bookConsultation';
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import "./search.css";
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { fetchGlobalSearch } from '../../store/home/home-actions'
interface GlobalTranslat {
  translation: any
}
export default function SearchPage({ translation }: GlobalTranslat) {
  const [selectedButton, setSelectedButton] = useState(null)
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const globalSearchData = useAppSelector(state => state?.globalSearch);
  useEffect(() => {
    dispatch(fetchGlobalSearch(searchText));
  }, [searchText]);

  const handleTabBtn = (index: any) => {
    setSelectedButton(index)
    if (selectedButton === index) {
      setSelectedButton(null);
    }
  }
  const handleSearchText = (e: any) => {
    setSearchText(e.target.value)

  }
  return (
    <Fragment>
      <Box className="SearchPage-header">
        <Header darkTheme={true} translation={translation}/>
      </Box>
      <Box>
        <SearchHeading translation={translation} searchText={searchText} handleSearchText={handleSearchText} handleTabBtn={handleTabBtn} selectedButton={selectedButton} oncologistLength={globalSearchData?.data?.doctor_data?.length} eventsLength={globalSearchData?.data?.events?.length} medecineLength={globalSearchData?.data?.productData?.length}
          blogsLength={globalSearchData?.data?.Blog_data?.length} expertsLength={globalSearchData?.data?.ask_doctor_data?.length} />
      </Box>
      <SearchResult translation={translation} selectedButton={selectedButton} oncologistList={globalSearchData?.data?.doctor_data} eventList={globalSearchData?.data?.events} medicineList={globalSearchData?.data?.productData}
        blogsList={globalSearchData?.data?.Blog_data} expertsList={globalSearchData?.data?.ask_doctor_data} />
        <BookConsultation translation={translation}/>
      <Box className="Footer">
        <Footer translation={translation}/>
        <MostSearched translation={translation}/>
        <br />
      </Box>
    </Fragment>
  );
}
