import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Tab, Divider, Alert } from "@mui/material";
import AppImages from "../../../../images/AppImages";
import "./connectUsDialog.css";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Carousel from "react-multi-carousel";
import { ButtonGroupProps } from 'react-multi-carousel/lib/types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Responsive from "../../modalResponsive.json";
import Cookies from 'universal-cookie';

export default function ConnectUsDialog(Props: { translation: any, handleDone: any, setPage?: any, setRefresh?: any, refresh?: any }) {
    const [value, setValue] = useState("0");
    const [selectedTime, setSelectedTime] = useState(0);
    const [selectedDate, setSelectedDate] = useState(0);
    const [audio, setAudio] = useState(false);
    const [video, setVideo] = useState(false);
    const [audioVideo, setAudioVideo] = useState(-1);
    const [error, setError] = useState(false);
    const cookies = new Cookies();
    const [dateSlots, setDateSlots] = useState<any>([]);
    const [timeSlots, setTimeslots] = useState<any>([]);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }
    function handleAudio() {
        setAudio(true)
        setVideo(false)
        setAudioVideo(1)
    }
    function handleVideo() {
        setAudio(false)
        setVideo(true)
        setAudioVideo(2)
    }
    function handleDone() {
        if (audioVideo === -1) {
            setError(true)
        } else {
            let data = {
                name: cookies.get('name'),
                phone: cookies.get('number').toString(),
                scheduledAt: selectedDate === 0 && selectedTime === 0 ? Intl.DateTimeFormat('en', { dateStyle: "short", }).format(new Date(dateSlots[0])).replaceAll("/", "-") + ' ' + Intl.DateTimeFormat('en', { timeStyle: "medium", hourCycle: "h24" }).format(new Date(timeSlots[0])).toString() :
                    Intl.DateTimeFormat('en', { dateStyle: "short" }).format(new Date(dateSlots[selectedDate])).replaceAll("/", "-") + ' ' + Intl.DateTimeFormat('en', { timeStyle: "medium", hourCycle: "h24" }).format(new Date(timeSlots[selectedTime])).toString(),
                call_mode: audioVideo
            }
            setError(false)
            Props.handleDone(data);
        }
    }
    useEffect(() => {
        const newDate = new Date();
        const nextDate = new Date(newDate);
        const Length = [0, 1, 1, 1, 1, 1, 1]
        const Dates = Length.map((items) => nextDate.setDate(nextDate.getDate() + items));
        setDateSlots(Dates)
    }, []);
    useEffect(() => {
        setTimeslots([])
        const newDate = new Date();
        let time = newDate.getHours();
        var hours = Math.floor(time % 24) + 1;
        const start = new Date();
        start.setHours(hours, 0, 0); //9 AM
        const end = new Date();
        end.setHours(21, 0, 0); //9 PM
        let timeSlotsValue = []
        while (start <= end) {
            start.setMinutes(start.getMinutes() + 30);
            timeSlotsValue.push(start.setMinutes(start.getMinutes()));
        }
        setTimeslots(timeSlotsValue);
    }, []);
    const ButtonGroup = ({ next, previous, goToSlide, carouselState, ...rest }: ButtonGroupProps) => {
        const totalSlide = carouselState?.slidesToShow && carouselState?.currentSlide ?
            carouselState?.slidesToShow + carouselState?.currentSlide : null
        const lastItem = totalSlide !== null && carouselState?.totalItems ? totalSlide < carouselState?.totalItems : null
        return (
            <div className="ConnectC-Box">
                {carouselState?.currentSlide !== 0 && <Button disableRipple className="ConnectCButton" onClick={previous} >
                    <NavigateBeforeIcon className="ConnectCButtonicon" />
                </Button>}
                {lastItem === null || lastItem ? <Button disableRipple className="ConnectCButton1" onClick={next}>
                    <NavigateNextIcon className="ConnectCButtonicon" />
                </Button> : null}
            </div>
        );
    };
    const timeSlotSelction = (index: number) => {
        const newDate = new Date();
        let time = newDate.getHours();
        var hours = Math.floor(time % 24) + 1;
        let timeSlotsValue = []
        setTimeslots([]);
        if (index === 0) {
            const start = new Date();
            start.setHours(hours, 0, 0); //9 AM
            const end = new Date();
            end.setHours(21, 0, 0); //9 PM
            while (start <= end) {
                start.setMinutes(start.getMinutes() + 30);
                timeSlotsValue.push(start.setMinutes(start.getMinutes()));
            }
            setTimeslots(timeSlotsValue);
        } else {
            const start = new Date();
            start.setHours(9, 0, 0); //9 AM
            const end = new Date();
            end.setHours(21, 0, 0); //9 PM
            while (start <= end) {
                start.setMinutes(start.getMinutes() + 30);
                timeSlotsValue.push(start.setMinutes(start.getMinutes()));
            }
            setTimeslots(timeSlotsValue);
        }
        Props.setRefresh(!Props.refresh)
    }
    const handleSelectSlots = (index: any) => {
        setSelectedDate(index)
        timeSlotSelction(index)
    }
    return (
        <Box className="ContactUSModal-MainBox">
            <Typography className="ContactUSModal-text">{Props.translation?.Pleaseoptions}</Typography>
            <Box className="Blue-TimeBox">
                <Box className="ContactUsModal-Time-Box">
                    <Box className="ContactUsModal-Now-Box">
                        <Typography className="Select-time-Txt">{Props.translation?.SelectSlots}</Typography>
                        <TabContext value={value}>
                            <TabList TabIndicatorProps={{ className: "TabsIndicatorBook" }} onChange={handleChange} allowScrollButtonsMobile scrollButtons="auto" variant="scrollable" className="ContactUsModal-Monthly-box">
                                {dateSlots?.map((items: any, index: any) => (
                                    <Tab disableRipple className="ContactUsModalTabsBox" onClick={() => handleSelectSlots(index)} key={items} label={
                                        <Box className="timeSlotflex">
                                            <Box className="ContactUsModal-Daily-Box">
                                                <Typography className="Mon-Text">
                                                    {Intl.DateTimeFormat('en', { month: "short" }).format(new Date(items))} {Intl.DateTimeFormat('en', { day: "numeric" }).format(new Date(items))}, <br />
                                                    {Intl.DateTimeFormat('en', { weekday: "short" }).format(new Date(items))}</Typography>
                                            </Box>
                                            <Divider orientation="vertical" className="Line-49" />
                                        </Box>} value={index.toString()} tabIndex={index} />))}
                            </TabList>
                            {dateSlots?.map((item: any, ind: any) => (
                                <Box key={item} className="width100">
                                    {ind.toString() === value &&
                                        <TabPanel value={value} className="ContactUsModal-SlotBox" tabIndex={0}>
                                            <Carousel ssr renderButtonGroupOutside customButtonGroup={<ButtonGroup />} responsive={Responsive.responsive}
                                                className="ContactUsModalCarousel"
                                                arrows={false}>
                                                {timeSlots?.map((items: any, index: any) => (
                                                    index === 0 && ind === 0 ?
                                                        <Button disableRipple key={items} onClick={() => setSelectedTime(index)}
                                                            className={selectedTime === index ? "Done-ContactUsModalChip" : "ContactUsModal-Done"}>
                                                            <Typography className={selectedTime === index ? "ContactUsModalChip-text" : "ContactUsModal-text1"} >
                                                                {Props.translation?.NOW}
                                                            </Typography>
                                                        </Button> :
                                                        <Button disableRipple key={items} onClick={() => setSelectedTime(index)}
                                                            className={selectedTime === index ? "ContactUsModalS-timeChip" : "ContactUsModal-Chip1"}>
                                                            <Typography className={selectedTime === index ? "ContactUsModalChip-text" : "ContactUsModal-text1"} >{
                                                                Intl.DateTimeFormat('en', { hour: '2-digit', minute: '2-digit' }).format(new Date(items))
                                                            }</Typography>
                                                        </Button>))}
                                            </Carousel>
                                        </TabPanel>}</Box>))}
                        </TabContext>
                    </Box>
                </Box>
            </Box>
            <Box className="ContactUSModal-box">
                <Button disableRipple onClick={handleAudio} className={audio ? "SContactUsModalbtn" : "ContactUsModal-btn"}>
                    <img src={AppImages.Whapsapp} className="ContactUsModal-btnI" alt="" />
                    <Typography className='ContactUsModal-btn-text'>{Props.translation?.VideoCall}</Typography> </Button>
                <Button disableRipple onClick={handleVideo} className={video ? "SContactUsModalbtn" : "ContactUsModal-btn"}>
                    <img src={AppImages.CallusBlack} className="callIcon-btnimg" alt="" />
                    <Typography className='ContactUsModal-btn-text'>{Props.translation?.AudioCall}</Typography></Button>
            </Box>
            <Box className="Consult-Done-btnBox">
                <Button disableRipple className="Consult-Done-btn" onClick={handleDone}>
                    <Typography className="Consult-Done-text">
                        {Props.translation?.Done}
                    </Typography>
                </Button>
                {error && audioVideo === -1 && <Alert severity="error">
                    {Props.translation?.Pleaseconnectus}
                </Alert>}
            </Box>
        </Box>);
}