import React, { memo, useState } from 'react';
import './savinglives.css'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import AppImages from '../../../images/AppImages'
import { aboutPage, homePage } from '../../../values/paths';

interface Translate {
    translation: any
}
function SavingLives({ translation }: Translate) {
    const [showMore, setShowMore] = useState(false)
    function handleMoreLess() {
        setShowMore(!showMore)
    }
    const mobileview = useMediaQuery("(max-width:600px)");
    return (
        <Box className='SavingLives-mainbox'>
            <Box className="SavingLives-headimgtextbox">
                <Typography className='SavingLives-headingtext'>
                    {translation?.Fromlosinghusband} {translation?.tosavinglives}
                </Typography></Box>
            <Box className='SavingLives-mainbox2'>
                <Box className='SavingLives-imgbox'>
                    <img src={AppImages.CollagesPic} className='SavingLives-img' alt={translation?.SavingLives} loading='lazy' />
                </Box>
                <Box className='SavingLives-textbox'>
                    <Box width="fit-content">
                        {mobileview && <img className='SavingLives-coma' src={AppImages.doubleopen} alt={translation?.SavingLives} loading='lazy' />}
                        {mobileview ? <Typography className={showMore ? 'SavingLives-introductiontext' : "SavingLives-introductiontext1"}>
                            <br />{translation?.Ourjourneystarte1}...<span onClick={handleMoreLess} className='TreatmentIndia-readmorebtn'>{showMore ? "" : translation?.readMore}</span>
                            <br />
                            {translation?.Ourjourneystarte2}<br />
                            <span className='SavingLives-comass'><img className='SavingLives-coma1' src={AppImages.doubleclose} alt={translation?.SavingLives} /></span>
                            <span onClick={handleMoreLess} className='TreatmentIndia-readmorebtn'>{showMore ? translation?.readLess : " "}</span>
                        </Typography> :
                            <Typography className='SavingLives-introductiontext'>
                                <img className='SavingLives-coma' src={AppImages.doubleopen} alt={translation?.SavingLives} loading='lazy' />
                                <br />{translation?.Ourjourneystartedin2018}<br />
                                <span className='SavingLives-comass'><img className='SavingLives-coma1' src={AppImages.doubleclose} alt={translation?.SavingLives} /> </span>
                            </Typography>}
                        <Typography className='SavingLives-ownername'>
                            {translation?.DimpleParmar}<a className='SavingLives-linkicon' href='https://www.linkedin.com/in/dimplezenlove/' target="_blank" rel="noreferrer">
                                <img className='SavingLives-linkiconimg' src={AppImages.LinkedIn} alt={translation?.SavingLives} /></a>
                            & {translation?.KishanShah}<a className='SavingLives-linkicon' href='https://www.linkedin.com/in/kishanshahzen/' target="_blank" rel="noreferrer">
                                <img className='SavingLives-linkiconimg' src={AppImages.LinkedIn} alt={translation?.SavingLives} loading='lazy' /></a>
                        </Typography>
                        <Typography className='SavingLives-extrainfo'>
                            {translation?.Founders} <a href={homePage} className='SavingLives-extrainfo1' target="_blank">{translation?.ZenOncoio}</a>
                            <a href='https://lovehealscancer.org/' className='SavingLives-extrainfo1' target="_blank" rel="noreferrer">{translation?.LoveHealsCancer}</a>
                        </Typography><br />
                        <Button href={aboutPage} className='SavingLives-button'>
                            <Typography className='SavingLives-buttontex'>
                                {translation?.Knowmore}
                            </Typography></Button>
                    </Box></Box></Box></Box>
    );
}
export default memo(SavingLives);
