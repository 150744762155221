/**
 * @format
 * @flow
 */
import CallBlack from './CallbtnBlack.webp';
import StorieHead from "./Stories.png";
import CallOrange from "./callOrange.svg";
import Whapsapp from './WhatsappBtn.webp';
import OrangeArrow from "./ArrowOrange.webp";
import WhiteArrow from "./arrow-forward-white.svg";
import GooglePlaystore from "./GooglePlay.webp";
import AppStore from "./AppStore.webp";
import PlayButton from "./playArrow.webp";
import Caregiver from "./Caregiver.png";
import Patient from "./Patient.png";
import ShcoolImage from "./InformationIcons/school.svg";
import MemberShipBadge from "./InformationIcons/military-tech.svg";
import AwardsBadge from "./InformationIcons/social-leaderboard.svg";
import CouponIcon from "./InformationIcons/Vector.png";
import Features from "./InformationIcons/noun-features.png";
import Informations from "./InformationIcons/Information.svg";
import Edit from "./Edit.png";
import Ingredient from "./InformationIcons/Isolation_Mode.png";
import HowtoUse from "./InformationIcons/noun-medicine.png";
import Delete from "./InformationIcons/Group.png";
import ExperienceBadge from "./InformationIcons/work.svg";
import AboutUs1 from "./AboutUsImages/aboutUs1.webp";
import AboutUs2 from "./AboutUsImages/aboutUs2.webp";
import AboutUs3 from "./AboutUsImages/aboutUs3.webp";
import AboutUs4 from "./AboutUsImages/aboutUs4.webp";
import LoveText from "./LoveImage.png";
import InstaIcon from "./SocialMediaIcons/instaIcon.webp";
import LinkedinIcon from "./SocialMediaIcons/LinkedinIcon.webp";
import TwitterIcon from "./SocialMediaIcons/TwitterIcon.webp";
import YouTube from "./SocialMediaIcons/YoutubeIcon.webp";
import FaceBookIcon from "./SocialMediaIcons/FaceBookIcon.webp";
import SideEffect from "./SideEffect.webp";
import ClockIcon from "./Clock Icon.png";
import LocationIcon from "./location_on.png";
import Coupon from "./InformationIcons/coupan.png";
import LoveHealsCancer from "./LoveHealsCancer.png";
import DownLoadApp2 from "./downLoadApp.webp";
import DownLoadApp from "./downLoadApp2.webp";
import DoctorImage from "./DoctorImage.webp";
import CallUsWhite from "./callUsWhite.svg";
import DefaultBlog from "./defaultBg.png";
import DoctorSmall from "./DoctorImageSmall.webp";
import ModalIcon from "./ModalIcon.png";
import ModalImg from "./Modal-img.webp";
import FooterLogo from "./FooterLogo.webp";
import DarkLogo from "./LogoDark.webp"
import DarkLogoMobile from "./LogoDarkMobile.webp";
import EventHead from "./Event.jpg";
import circleLogo from "./zenBackImageMobile.webp";
import Privacy_Policy from "./privacy-policy.svg";
import Terms_condition from "./terms-condition.webp";
import UserQueryImg from "./queriesImg.webp";
import ProductIcon from "./InformationIcons/employee 1.png";
import Founder from "./founderImage.webp";
import CallWhite from "./callWhite.svg";
import CallUsIcon from './Callus.png';
import CallusBlack from "./CallBlack.png";
import PaySecurely from './Pay securely by Credit or Debit card or Internet Banking through Razorpay.png'
import maleDoctor from "./MaleDoctor.webp";
import Confirm from "./Group 86836.png";
import Thumbnail from "./maxresdefault.webp";
import BangloreCenter1 from "./centerImages/BangloreCenter1.webp";
import BangloreCenter2 from "./centerImages/BangloreCenter2.webp";
import BangloreCenter3 from "./centerImages/BangloreCenter3.webp";
import Check from "./tickcircle.svg";
import orangetik from './tik.svg'
import AiDoctor from "./aiDoctor.webp";
import MyReport from "./report.svg";
import CollagesPic from './Collage.webp';
import doubleopen from './exclamation-open.svg'
import doubleclose from './exclamation-close.svg'
import Linkedin from './linkedin.png'
import Community from './Community.webp'
import IncreaseLife from './InformationIcons/IncreaseLife.svg'
import Satisfaction from './Patient satisfaction.svg'
import PatientGood from './Patients.svg'
import SearchIcon from './search.webp'
import SearchBarIcon from './SearchBarIcon.webp'
import reportIcon from './Reports-Icons.png'
// import sharkTankMobile from './sharktank-mobile.svg'
import sharkTankMobile from './SharkTanks-mobile.png'
import StafPatiantImgOrange from "./staffOrange.webp" ;
import CardMobileImg from "./Journey.webp";
import callMobileimg from "./callMobile.webp";
import callMobileimgWhite from "./callMobileWhite.webp";
import searchHead from "./SearchHead.webp";
import reportMobileImg from "./reportMobile.webp";
import playStore from "./playstore.svg";
import appStore from "./appStoreimg.svg";
import mobileCart from './mobileCart.webp'
import LinkedIn from "./linkdIn.svg";
import Nutrition from './nutrition.svg';
import Ayurveda from './ayurveda.svg';
import MedicalCann from './medicalCabb.svg';
import language from './language.svg';
import whiteCart from "./mobileCartwhite.webp";
import LoveHeals from "./loveHeals.svg";
import whatsappButton from "./whatsapp2.svg";
import SendBtn from "./send.svg";
import MediaBtn from "./mediaBtn.svg";
import ChatDp from "./ChatDP.png";
import RecoveryImg from "./Recovery.png";
import downloadImg from "./downloadApp.png";
import DownloadLogo from "./ZHC-logo-landscape-White.png"
const AppImages = {
  maleDoctor,
  CallusBlack,
  CallBlack,
  CallOrange,
  Whapsapp,
  Edit,
  OrangeArrow,
  WhiteArrow,
  SideEffect,
  GooglePlaystore,
  AppStore,
  Caregiver,
  Patient,
  PlayButton,
  ShcoolImage,
  MemberShipBadge,
  AwardsBadge,
  HowtoUse,
  Ingredient,
  Features,
  Delete,
  CouponIcon,
  Coupon,
  ExperienceBadge,
  Informations,
  AboutUs1,
  AboutUs2,
  AboutUs3,
  AboutUs4,
  LoveText,
  InstaIcon,
  LinkedinIcon,
  TwitterIcon,
  FaceBookIcon,
  YouTube,
  ClockIcon,
  LocationIcon,
  LoveHealsCancer,
  DownLoadApp,
  DoctorImage,
  CallUsWhite,
  DefaultBlog,
  DoctorSmall,
  ModalIcon,
  ModalImg,
  FooterLogo,
  DarkLogo,
  EventHead,
  circleLogo,
  Privacy_Policy,
  Terms_condition,
  StorieHead,
  UserQueryImg,
  ProductIcon,
  Founder,
  CallWhite,
  CallUsIcon,
  PaySecurely,
  Confirm,
  Thumbnail,
  BangloreCenter1,
  BangloreCenter2,
  BangloreCenter3,
  Check,
  orangetik,
  AiDoctor,
  MyReport,
  CollagesPic,
  doubleopen,
  doubleclose,
  Linkedin,
  Community,
  IncreaseLife,
  PatientGood,
  Satisfaction,
  SearchIcon,
  SearchBarIcon,
  reportIcon,
  sharkTankMobile,
  StafPatiantImgOrange,
  CardMobileImg,
  callMobileimg,
  searchHead,
  callMobileimgWhite,
  reportMobileImg,
  playStore,
  appStore,
  mobileCart,
  LinkedIn,
  Nutrition,
  Ayurveda,
  MedicalCann,
  language,
  LoveHeals,
  DownLoadApp2,
  whiteCart,
  whatsappButton,
  DarkLogoMobile,
  SendBtn,
  MediaBtn,
  ChatDp,
  RecoveryImg,
  downloadImg,
  DownloadLogo
};
export default AppImages;