import React, { useState, useEffect, memo } from "react";
import { Box, Button, Typography } from "@mui/material";
import Cookies from 'universal-cookie';
import Alert from '@mui/material/Alert';
import "./bookConsultation.css";
import AppImages from "../../../images/AppImages";
import TreatmentModel from "../Common-Modals/TreatmentModel/treatmentModel";

function BookConsultation(props: { loading?: any, translation: any, homePage?: any, handleAlert?: any, handleFooterModel?: any }) {
    const [modal, setModal] = useState(false);
    const cookies = new Cookies();
    const [open, setOpen] = useState(false);
    function hadleCloseModal() {
        setModal(false);
    }
    function hadleOpenModal() {
        if (cookies.get('token') === undefined) {
            setModal(true);
        } else {
            setModal(true);
            // setOpen(true)
            // setTimeout(() => {
            //     setOpen(false);
            // }, 2000)
        }
    }
    return (
        <React.Fragment>
            <Box className="Rectangle-Consultation">
                <Box className="Image-box">
                    <img src={AppImages?.StafPatiantImgOrange} alt="" className="image" />
                </Box>
                <Box className="text-to-btnBox">
                    <Typography className="Personal-aproch">
                        {props.translation?.WeareAlways}
                    </Typography>
                    <Button disableRipple className="Book-consultation-btn" onClick={hadleOpenModal}>
                        <Typography className="Book-consultation-text">
                            {props.translation?.ConnectCoach}
                        </Typography>
                    </Button>
                    <Box>
                        {open && <Alert icon={false} className="AlertST">
                            {props.translation?.teamWillCall}</Alert>}
                    </Box></Box>
                <TreatmentModel translation={props.translation} homePage={props.homePage} handleAlert={props.handleAlert} setModal={setModal}
                    modal={modal} handleCloseModal={hadleCloseModal} />
            </Box></React.Fragment>
    );
}
export default memo(BookConsultation);
