import React from "react";
import "../../homePage-Component/MyReport/myReport.css";
import { FormControl, Chip, Autocomplete, Checkbox, TextField, InputLabel, Select, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { selectExpand } from "../ExpandIcon/expandIcon";

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" />;

interface AutoProps {
    userData?: any;
    setUserData?: any;
    valueType?: any;
    lengthType?: any;
    typeList?: any;
    label?: any
    translation: any
}

interface AutoProps1 {
    lengthType?: any;
    typeList?: any;
    label?: any
    handleChange?: any;
}
export default function ReportAutofilled({ translation, userData, setUserData, valueType, lengthType, typeList, label }: AutoProps) {
    return (
        <FormControl fullWidth className="reportInputs_Form">
            <Autocomplete
                componentsProps={{ popper: { style: { width: '95%', maxWidth: "250px", zIndex: 1000 } } }}
                popupIcon={<KeyboardArrowDownIcon />}
                value={lengthType}
                onChange={(event, newValue) => {
                    setUserData({ ...userData, [valueType]: newValue });
                }}
                className="report_Inputs"
                multiple
                options={typeList}
                disableCloseOnSelect
                getOptionLabel={(option) => lengthType ? option?.prior_treatment : ""}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            value={option?.id}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.prior_treatment}
                    </li>)}
                renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                        lengthType?.length < 1 && <Chip
                            label={option.prior_treatment}
                            {...getTagProps({ index })} />))}
                renderInput={(params) => (
                    <TextField {...params} variant="standard"
                        InputProps={{ ...params.InputProps, disableUnderline: true, }}
                        sx={{
                            input: {
                                marginTop: "10px",
                                color: '#727070',
                                "&::placeholder": { opacity: 1, fontFamily: "FontRegular", fontSize: lengthType?.length > 0 ? "16px" : "14px", color: lengthType?.length > 0 ? "black" : "" }
                            },
                        }}
                        placeholder={lengthType?.length === typeList?.length ? `${translation?.Allselected}(${typeList?.length})` : lengthType?.length > 0 ? lengthType?.length + translation?.selected : lengthType?.length ? "" :
                            label} />)} />
        </FormControl>
    );
}

export function ReportAutoFilled01({ handleChange, lengthType, typeList, label }: AutoProps1) {
    return (
        <FormControl fullWidth className="reportInputs_Form">
            <InputLabel shrink={false} className="report_Inputs-label">{lengthType ? "" : label}</InputLabel>
            <Select MenuProps={{
                style: { zIndex: 1000, maxHeight: "350px" }
            }} variant="standard" value={lengthType} onChange={handleChange} disableUnderline
                className="report_Inputs" IconComponent={selectExpand}>
                {typeList?.map((items: any, index: any) => (
                    <MenuItem key={items?.id} value={items?.id}>
                        {items?.prior_treatment}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}