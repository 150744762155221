import {SaveZioparModel} from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialHomeState:SaveZioparModel={
    status: 0,
    message: '',
}

const saveZioparSlice=createSlice({
    name:'saveZioparSlice',
    initialState:initialHomeState,
    reducers:{
        setSaveZioParData(state,action:PayloadAction<SaveZioparModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
        }
    }
})
export default saveZioparSlice;