import {configureStore} from '@reduxjs/toolkit';
import homeSlice from './home/home-slice'
import aboutSlice from './aboutUs/about-slice';
import doctorSlice from './doctor/doctor-slice';
import futureEventSlice from './event/futureEvent-slice';
import pastEventSlice from './event/pastEvent-slice';
import eventDetailSlice from './event/eventDetail-slice';
import feedbackSlice from './home/feedback-slice'
import faqSlice from './faq/faq-slice';
import storySlice from './story/story-slice';
import zioparSlice from './faq/ziopar-slice';
import doctorListSlice from './doctor/docotorList-slice';
import doctorScheduleSlice from './doctor/doctorSchedule-slice';
import storyDetailSlice from './story/storyDetail-slice';
import storyFilterlSlice from './story/storyFilter-slice';
import doctorTalkFilterlSlice from './story/doctortalkFilter-slice';
import doctorFilterSlice from './doctor/doctorFilterList-slice';
import thankYouSlice from './doctor/thankyou-slice';
import OTPSlice from './doctor/otp-slice';
import CONFIRMOTPSlice from './doctor/confirmOTP-slice';
import PaymentSlice from './doctor/initiatePay-slice';
import RegisterEventSlice from './event/registerEvent-slice';
import sitemapSlice from '../pages/siteMap/siteMap-slice';
import productSlice from './product/product-slice';
import productDetailSlice from './product/productDetail-slice';
import getAddressSlice from './product/getAddress-slice';
import getCountrySlice from './product/getCountry-slice';
import getStateSlice from './product/getState-slice';
import getCartSlice from './product/getCart-slice';
import productFilterSlice from './product/productFilter-slice';
import reviewSlice from './product/productReview-slice';
import initiateRazorPaySlice from './product/initiateRazorPay-Slice';
import productDeleteSlice from './product/productDelete-slice';
import mostSearchSlice from './home/mostSearched-slice';
import createOrderSlice from './product/createOrder-slice';
import appointmentSlice from './doctor/createAppointment-slice';
import cancerFormSlice from './doctor/cancerFormList-slice';
import storiesHopeSlice from './home/storiesHope-slice';
import globalSearchSlice from './home/globalSearch-slice';
import getZioparSlice from './home/getziopar-slice';
import getChatBotAnsSlice from './home/chatbot-slice';
import customerLeadSlice from './home/customerLead-slice';

const store=configureStore(
    {
        reducer:{
            home:homeSlice.reducer,
            about:aboutSlice.reducer,
            doctor:doctorSlice.reducer,
            futureEvent:futureEventSlice.reducer,
            pastEvent:pastEventSlice.reducer,
            eventDetail:eventDetailSlice.reducer,
            feedbackDetail:feedbackSlice.reducer,
            faq:faqSlice.reducer,
            story:storySlice.reducer,
            ziopar:zioparSlice.reducer,
            doctorList:doctorListSlice.reducer,
            doctorSchedule:doctorScheduleSlice.reducer,
            storyDetail:storyDetailSlice.reducer,
            storyFilter:storyFilterlSlice.reducer,
            doctorTalkFilter:doctorTalkFilterlSlice .reducer,
            doctorFilter: doctorFilterSlice.reducer,
            thankYou: thankYouSlice.reducer,
            OTP: OTPSlice.reducer,
            CONFIRMOTP: CONFIRMOTPSlice.reducer,
            Payment: PaymentSlice.reducer,
            registerEvent: RegisterEventSlice.reducer,
            sitemap: sitemapSlice.reducer,
            productList: productSlice.reducer,
            productDetail: productDetailSlice.reducer,
            addressList: getAddressSlice.reducer,
            stateList: getStateSlice.reducer,
            countryList: getCountrySlice.reducer,
            cartList: getCartSlice.reducer,
            productFilter: productFilterSlice.reducer,
            reviewList: reviewSlice.reducer,
            inititeRazorPay: initiateRazorPaySlice.reducer,
            deleteAddress: productDeleteSlice.reducer,
            mostSearched: mostSearchSlice.reducer,
            createOrder: createOrderSlice.reducer,
            createAppointment: appointmentSlice.reducer,
            cancerFormList: cancerFormSlice.reducer,
            storyHope: storiesHopeSlice.reducer,
            globalSearch: globalSearchSlice.reducer,
            getZioPar: getZioparSlice.reducer,
            chatBotAns: getChatBotAnsSlice.reducer,
            customerLead: customerLeadSlice.reducer,
        }
    }
)


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch =typeof store.dispatch
export default store;