import React, { Fragment } from "react";
import { Box } from "@mui/material";
import AddressBar from "../../SelectAddressPage-Component/AddressBar/addressBar";
import ProductListView from "../../ProductsCartPage-Component/ProductListView/productListView";
// import CouponandPayment from "../../Common-Component/CouponandPayment/couponandPayment";
import "./orderDetails.css";

interface Translate{
    translation:any
}
export default function OrderDetails({translation}:Translate) {
    return (
        <Fragment>
            <Box className="orderDetails-Main-Box">
                <Box>
                    <AddressBar translation={translation}/>
                    <Box className="products-View-Box">
                        {[0, 1, 2, 3].map((items, index) => (
                            <ProductListView translation={translation}/>
                        ))}
                    </Box>
                </Box>
                <Box>
                </Box>
            </Box>
        </Fragment>
    );
}