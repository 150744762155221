import React from "react";
import "./videoCards.css";
import { Box, Pagination, useMediaQuery } from "@mui/material";
import DownloadApp from "../../Common-Component/DownloadApp/downloadApp";
import EventsBlog from "../../Common-Component/EventsBlog/eventsBlog";
import PersonalizedBox from "../../Common-Component/PersonalizedCareBox/personalizedBox";


export default function VideoCard(Props: { doctorData: any; pages: number; pageChange: (event: React.ChangeEvent<unknown>, value: number) => void; dark?: Boolean, translation: any }) {
    const mobileView = useMediaQuery("(max-width:600px)");
    const mobileView1 = useMediaQuery("(max-width:371px)");
    return (
        <Box className="videocardTopBox">
            <EventsBlog translation={Props?.translation} title={true} storyData={Props?.doctorData} dark={Props.dark} />
            {!mobileView ? <Box className="dwnload-DrTalk"> <DownloadApp translation={Props?.translation} /></Box> : <Box className="dwnload-DrTalk"> <PersonalizedBox translation={Props?.translation} /> </Box>}
            {Props.doctorData.length > 8 &&
                <Box className="VideoPagination-Box">
                    <Pagination
                        size={!mobileView1 ? "large" : "small"}
                        boundaryCount={!mobileView ? 1 : 1}
                        siblingCount={!mobileView ? 1 : 0}
                        color="secondary" count={10} variant="outlined" shape="rounded" page={Props.pages} onChange={Props.pageChange} />
                </Box>}
        </Box>
    );
}