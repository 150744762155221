import {DcotorModel} from "../../models/doctor-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialDoctorState:DcotorModel={
    status: 0,
    message: '',
    review_avg: 0,
    review_count: 0,
    doctor_detail: [],
    doctor_desc: [],
    doctor_education: [],
    metadatas: [], 
    doctor_review: [], 
    hospital_detail: [] 
}

const doctorSlicee=createSlice({
    name:'doctor',
    initialState:initialDoctorState,
    reducers:{
        setDoctorData(state,action:PayloadAction<DcotorModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.doctor_detail = action.payload?.doctor_detail;
            state.doctor_desc = action.payload?.doctor_desc;
            state.doctor_education = action.payload?.doctor_education;
            state.hospital_detail = action.payload?.hospital_detail;
            state.metadatas = action.payload?.metadatas;
            state.review_avg = action.payload?.review_avg;
            state.review_count = action.payload?.review_count;
            state.doctor_review = action.payload?.doctor_review;
        }
    }
})
export default doctorSlicee;