import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import "./thankyouPage.css";
import Header from "../../components/Common-Component/Header/header";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import DownloadApp from "../../components/Common-Component/DownloadApp/downloadApp";
import ThankyouMsg from "../../components/ThankYouPage-Component/ThankyouMsg/thankYouMsg";
import JoinEvents from "../../components/ThankYouPage-Component/joinEvents/joinEvents";
import SurvivorStories from "../../components/ThankYouPage-Component/SurvivorStories/surviviorStories";
import { fetchThankYouData } from "../../store/doctor/doctor-actions";
import { fetchFutureEventData } from '../../store/event/event-actions';
import Seo from "../../components/Common-Component/SEO/seo";

interface GlobalTranslat {
    translation: any
}
export default function ThankYouPage({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const thankyouData = useAppSelector(state => state?.thankYou);
    const futureEvents = useAppSelector(state => state?.futureEvent);
    // const OTPData = useAppSelector(state => state?.CONFIRMOTP);

    useEffect(() => {
        dispatch(fetchThankYouData());
        dispatch(fetchFutureEventData());
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <Seo translation={translation} metaData={thankyouData.metadatas} />
            <Box className="TopBox">
                <Box className="Thanks-Header">
                    <Header translation={translation} />
                </Box>
            </Box>
            <ThankyouMsg translation={translation} />
            <SurvivorStories translation={translation} survivorStories={thankyouData.stories} />
            {futureEvents.event_future_data?.length > 0 &&
                <JoinEvents translation={translation} sessions={futureEvents.event_future_data} />}
            <DownloadApp translation={translation} />
            <BookConsultation translation={translation} />
            <Box className="Footer">
                <Footer translation={translation} />
                <MostSearched translation={translation} />
                <br />
            </Box>
        </React.Fragment>
    );
}