import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { AddToCartModel, CartModel, CountryModel, DeleteCartModel, GETAddressModel, GETStateModel, InitiateRazorPayModel, ProductAddressModel, ProductDeleteModel, ProductDetailModel, ProductFilterModel, ProductListModel, ProductReviewModel, UpdateCartModel, createOrderModel } from '../../models/product-models'
import proudctSlice from './product-slice'
import proudctDetailSlice from './productDetail-slice'
import proudctReviewSlice from './productReview-slice'
import proudctAddressSlice from './productAddress-slice'
import editAddressSlice from './editAddress-slice'
import getAddressSlice from './getAddress-slice'
import getCountrySlice from './getCountry-slice'
import getStateSlice from './getState-slice'
import getCartSlice from './getCart-slice'
import deleteCartSlice from './cartDelete-slice'
import updateCartSlice from './updateCart-slice'
import addToCartSlice from './addToCart-slice'
import initiateRazorPaySlice from './initiateRazorPay-Slice'
import proudctDeleteSlice from './productDelete-slice'
import proudctFilterSlice from './productFilter-slice'
import productService from '../../service/productService';
import createOrderSlice from './createOrder-slice'

export const productActions = proudctSlice.actions
export const productDetailActions = proudctDetailSlice.actions
export const productFilterActions = proudctFilterSlice.actions
export const productReviewActions = proudctReviewSlice.actions
export const productAddressActions = proudctAddressSlice.actions
export const productEditAddressActions = editAddressSlice.actions
export const productGetAddressActions = getAddressSlice.actions
export const productDeleteActions = proudctDeleteSlice.actions
export const getCountryActions = getCountrySlice.actions
export const getStateActions = getStateSlice.actions
export const getCartActions = getCartSlice.actions
export const deleteCartActions = deleteCartSlice.actions
export const updateCartActions = updateCartSlice.actions
export const addToCartActions = addToCartSlice.actions
export const initiateRazorPayActions = initiateRazorPaySlice.actions
export const createOrderAction = createOrderSlice.actions


export const fetchProductData = (URL: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: ProductListModel = await productService.getProductList(URL);
        dispatch(productActions.setProductData(response))
    }
}

export const fetchProductDetail = (data:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: ProductDetailModel = await productService.getProductDetail(data);
        dispatch(productDetailActions.setProductDetail(response))
    }
}

export const fetchFilterData = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: ProductFilterModel = await productService.getProductFilterData();
        dispatch(productFilterActions.setProductFilter(response))
    }
}

export const fetchProductReview = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: ProductReviewModel = await productService.getProductReview(data);
        dispatch(productReviewActions.setProductReview(response))
    }
}

export const addAddress = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: ProductAddressModel = await productService.addProductAddress(data);
        dispatch(productAddressActions.setProductAddress(response))
    }
}

export const editAddress = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: ProductAddressModel = await productService.editProductAddress(data);
        dispatch(productEditAddressActions.setEditAddress(response))
    }
}

export const deleteAddress = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: ProductDeleteModel = await productService.deleteAddress(data);
        dispatch(productDeleteActions.setProductDelete(response))
    }
}

export const getAddress = (id: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: GETAddressModel = await productService.getAddressData(id);
        dispatch(productGetAddressActions.setGetAddress(response))
    }
}

export const getCountry = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: CountryModel = await productService.getCountryData();
        dispatch(getCountryActions.setGetCountry(response))
    }
}

export const getState = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: GETStateModel = await productService.getStateData();
        dispatch(getStateActions.setGetState(response))
    }
}

export const getCart = (id:number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: CartModel = await productService.getCartData(id);
        dispatch(getCartActions.setGetCart(response))
    }
}

export const deleteCart = (data:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: DeleteCartModel = await productService.deleteCartData(data);
        dispatch(deleteCartActions.setDeleteCart(response))
    }
}

export const updateCart = (data:any,token:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: UpdateCartModel = await productService.updateCartData(data,token);
        dispatch(updateCartActions.setUpdateCart(response))
    }
}

export const addToCartCart = (data:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: AddToCartModel = await productService.addToCartData(data);
        dispatch(addToCartActions.setAddToCartCart(response))
    }
}

export const initiateRazorPay = (data:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
    const response: InitiateRazorPayModel = await productService.initiatePay(data);
        dispatch(initiateRazorPayActions.setInitiateRazorPayCart(response))
    }
}

export const createOrder = (data:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
    const response: createOrderModel = await productService.createOrderData(data);
        dispatch(createOrderAction.createOrder(response))
    }
}
