import { ZioparDataModel } from "../../models/faq-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialZioparState: ZioparDataModel = {
    status: 0,
    message: '',
    cancer_type: [],
    cancer_stage: [],
    performance: [],
    prior_treatment: [],
    other_disease: [],
    symptoms: []
}

const zioparSlice = createSlice({
    name: 'ziopar',
    initialState: initialZioparState,
    reducers: {
        setZioparData(state, action: PayloadAction<ZioparDataModel>) {
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.cancer_type = action.payload?.cancer_type;
            state.cancer_stage = action.payload?.cancer_stage;
            state.performance = action.payload?.performance;
            state.prior_treatment = action.payload?.prior_treatment;
            state.other_disease = action.payload?.other_disease;
            state.symptoms = action.payload?.symptoms;
        }
    }
})

export default zioparSlice;