import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./storiesPage.css";
import Header from "../../components/Common-Component/Header/header";
import StoriesHead from "../../components/StoriesPage-Component/StoriesHead/storiesHead";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { fetchStoryData, fetchStoryFilterlData } from "../../store/story/story-actions";
import { storyModel } from "../../models/story-models";
import Seo from "../../components/Common-Component/SEO/seo";

interface GlobalTranslat {
    translation: any
}
export default function StoriesPage({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const storyDatas = useAppSelector(state => state?.story);
    const storyFilter = useAppSelector(state => state?.storyFilter);
    const [allData, setAllData] = useState<Array<storyModel>>([]);
    const [page, setPage] = useState(1);
    const [filteredOption, setFilteredOption] = useState({
        cancer_type: "",
        topic: ""
    });
    useEffect(() => {
        dispatch(fetchStoryFilterlData());
    }, []);

    useEffect(() => {
        let URL = 'get_filter_data_by_postcategory?page=' + page + '&postCategoryId=' + '1' + '&cancer_type=' + 1
        dispatch(fetchStoryData(URL));
    }, [page]);

    useEffect(() => {
        let URL = 'get_filter_data_by_postcategory?page=' + page

        if (filteredOption?.topic) {
            URL = URL + '&topic=' + filteredOption?.topic
        }
        // if (filteredOption.cancer_type) {
        //     URL = URL + '&cancer_type=' + filteredOption.cancer_type
        // }
        URL = URL + '&postCategoryId=' + '3'

        dispatch(fetchStoryData(URL));
    }, [page, filteredOption]);

    useEffect(() => {
        if (storyDatas?.status == 1) {
            if (storyDatas?.data?.length > 0 && (filteredOption?.topic?.length == 0 || filteredOption?.topic == null )) {
                let extra = allData.concat(storyDatas?.data);
                const Filtered = extra.filter((value, index, arr) => {
                    return (arr.findIndex((v) => v?.id === value?.id) === index);
                })
                setAllData(Filtered);
            } else if (storyDatas?.data?.length > 0 && filteredOption?.topic?.length > 1 && page > 1) {
                let extra = allData.concat(storyDatas?.data);
                const Filtered = extra.filter((value, index, arr) => {
                    return (arr.findIndex((v) => v?.id === value?.id) === index);
                })
                setAllData(Filtered);
            } else {
                setAllData(storyDatas?.data)
            }
        }
    }, [storyDatas?.data]);
    const loadMorePages = () => {
        setPage(page + 1)
    }

    return (
        <React.Fragment>
            <Seo translation={translation} metaData={storyFilter.metadatas} />
            <Box>
                <Box className="StoriesHeader">
                    <Header darkTheme={true} translation={translation}/>
                </Box>
                <Box>
                    <StoriesHead filteredOption={filteredOption} setFilteredOption={setFilteredOption} translation={translation} loadMorePages={loadMorePages} storiesData={allData} cancerCategory={storyFilter.cancer_category} topicList={storyFilter.topic_list} />
                </Box>
            </Box>
            <BookConsultation translation={translation}/>
            <Box className="Footer">
                <Footer translation={translation}/>
                <MostSearched translation={translation} />
                <br />
            </Box>
        </React.Fragment>
    );
}