import React, { memo, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./storiesOfHope.css";
import Slider from "react-slick";
import Response from "./hopeResponsive.json";
import "slick-carousel/slick/slick.css";

function StoriesofHope(Props: { storyData: any, translation: any }) {
    const settings = {
        className: "hope_carousel-Box",
        speed: 7000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        slidesToShow: 11,
        slidesToScroll: 1,
        rows: 2,
        infinite: true,
        arrows: false,
        responsive: Response?.data
    }
    const [loadData, setLoadData] = useState(false)

    setTimeout(() => {
        setLoadData(true)
        console.log("loaddta--->", loadData);
    }, 5200);
    return (
        <Box className="storieHope_main-Box">
            <Box>
                <Typography className="storiesHope_head-txt">
                    {Props?.translation?.storiesofhope}
                </Typography>
            </Box>
            {loadData && <Box className="Storieswidth100">
                <Slider {...settings}>
                    {Props?.storyData?.map((items: any, index: any) => (
                        <a href={items?.url} target="_blank" key={items?.id} rel="noreferrer" className="Maincontainer">
                            {/* <div className="Maincontainer"> */}
                            <img src={items?.image} className="Hover-image" alt={items?.title} loading="lazy" />
                            <div className="Hover-overlay">
                                <Typography className="Hover_head-txt">{items?.title}</Typography>
                                <Typography className="Hover_read-txt">{Props?.translation?.readmore}</Typography>
                            </div>
                            {/* </div> */}
                        </a>))}
                </Slider></Box>}
        </Box>
    );
}
export default memo(StoriesofHope);