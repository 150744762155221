import { CancerFormModel } from "../../models/doctor-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialOTPState: CancerFormModel = {
    status: 0,
    message: '',
    cancer_type: [],
    cancer_stage: [],
    treatement: [],
}

const cancerFormSlice = createSlice({
        name: 'CANCER_FORM_LIST',
        initialState: initialOTPState,
        reducers: {
            setCancerFormList(state, action: PayloadAction<CancerFormModel>) {
                state.status = action.payload?.status;
                state.message = action.payload?.message;
                state.cancer_type = action.payload?.cancer_type;
                state.cancer_stage = action.payload?.cancer_stage;
                state.treatement = action.payload?.treatement;
            }
        }
    })
export default cancerFormSlice;