import {DcotorListModel} from "../../models/doctor-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialDoctorListState:DcotorListModel={
    status: 0,
    message: '',
    data: [],
    total_pages: 0,
    current_page: 0,
    total_record: 0
}

const doctorListSlice=createSlice({
    name:'doctorList',
    initialState:initialDoctorListState,
    reducers:{
        setDoctorListData(state,action:PayloadAction<DcotorListModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
            state.total_pages = action.payload?.total_pages;
            state.total_record = action.payload?.total_record;
            state.current_page = action.payload?.current_page;
        }
    }
})
export default doctorListSlice;