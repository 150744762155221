import React from "react";
import "./joinEvents.css";
import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import EventBlogBox from "../../Common-Component/EventBlogbox/eventBlogBox";
import Response from "../thankyouResponsive.json";
import { EventDetailDataModel } from "../../../models/event-models";
import { ButtonGroupProps } from 'react-multi-carousel/lib/types';
import CarouselButton from "../../Common-Component/carouselButtons/carouselButtons";

interface ExpertSession {
    sessions: Array<EventDetailDataModel>
    translation: any
}

export default function JoinEvents({ translation, sessions }: ExpertSession) {

    const ButtonGroup = ({ next, previous, goToSlide, carouselState, ...rest }: ButtonGroupProps) => {
        const totalSlide = carouselState?.slidesToShow && carouselState?.currentSlide ?
            carouselState?.slidesToShow + carouselState?.currentSlide : null
        const lastItem = totalSlide !== null && carouselState?.totalItems ? totalSlide < carouselState?.totalItems : null
        return (
            <>
                {carouselState?.slidesToShow !== undefined &&
                    <>
                        {carouselState?.slidesToShow < 4 || sessions.length > 2 ?
                            <CarouselButton carouselState={carouselState} previous={previous} next={next} lastItem={lastItem} /> : null
                        }
                    </>
                }
            </>
        );
    }
    return (
        <Box className="joinEventsMain-box">
            <Typography className="heading-Session-txt">{translation?.CancerExpertSessions}</Typography>
            <Box className="joinEventsMain-carouselBox">
                <Carousel
                    ssr
                    renderButtonGroupOutside
                    customButtonGroup={<ButtonGroup />}
                    arrows={false}
                    className="all-Survivorscards"
                    responsive={Response.responsive}>
                    {sessions.map((items, index) => (
                        <Box key={items.id} className="cardHoverBox">
                            <EventBlogBox pastEvents={true} translation={translation} index={0} thanksBox={true} items={items} eventBlog={true} />
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </Box>
    );
}