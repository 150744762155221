import {UpdateUserDetailModel} from "../../models/doctor-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialThankYouState:UpdateUserDetailModel={
    status: 0,
    message: '',
    data: []
}

const updateUserSlice=createSlice({
    name:'updateUserDetail',
    initialState:initialThankYouState,
    reducers:{
        setUpdateUserDetailData(state,action:PayloadAction<UpdateUserDetailModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default updateUserSlice;