import {HomeModel} from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { SitemapModel } from "../../models/siteMap-models";

const initialSiteMapState:SitemapModel={
    doctor_slug:[],
    stories_slug:[],
    event_slug:[],
    create_url:[],
    message: ''
}

const sitemapSlice=createSlice({
    name:'sitemap',
    initialState:initialSiteMapState,
    reducers:{
        setSiteMapData(state,action:PayloadAction<SitemapModel>){
            state.stories_slug = action.payload?.stories_slug;
            state.event_slug = action.payload?.event_slug;
            state.create_url = action.payload?.create_url;
            state.doctor_slug = action.payload?.doctor_slug;
            state.message = action.payload?.message;

        }
    }
})
export default sitemapSlice;