import {DcotorScheduleModel} from "../../models/doctor-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialDoctoScheduleState:DcotorScheduleModel={
    status: 0,
    message: '',
    data: [],
    app_holiday: [],
    app_booking: []
}

const doctorScheduleSlice=createSlice({
    name:'doctorSchedule',
    initialState:initialDoctoScheduleState,
    reducers:{
        setDoctorScheduleData(state,action:PayloadAction<DcotorScheduleModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
            state.app_holiday = action.payload?.app_holiday;
            state.app_booking = action.payload?.app_booking;
        }
    }
})
export default doctorScheduleSlice;