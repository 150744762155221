export const HOME_API = 'new_home_api'
export const ABOUT_API = 'get_about_us'
export const DOCTOR_LIST_API = 'doctor_list?page='
export const DOCTOR_LIST_FILTER_API = 'get_dr_filter_custom_list?page='
export const DOCTOR_DETAIL_API = 'doctor_detail_page'
export const DOCTOR_SCHEDULE_API = 'get_reschedule_appointment?doctorId='
export const DOCTOR_FILTER_LIST_API = 'doctor_filter_list'
export const PAST_EVENTS = 'past_event_list?page='
export const FUTURE_EVENTS = 'future_event_list'
export const EVENT_DETAIL = 'broadcast_event_detail?eventId='
export const EVENT_DETAIL_NEW = 'broadcast_event_detail'
export const FEEDBACK_API = 'get_all_reviews?page='
export const GLOBAL_SEARCH = 'web_search?search_text='
export const MOST_SEARCHED = 'get_footer_most_search'
export const FAQ_API = 'get_all_faq_by_categorywise?search_text='
export const STORY_API = 'get_filter_data_by_postcategory?page='
export const STORY_DETAIL_API = 'get_story_detail'
export const BLOG_DROPDOWN = 'get_blog_filter_list'
export const DOCTOR_TALK_DROPDOWN = 'get_dr_talk_filter_list'
export const ZIOPAR_API = 'get_ziopar_form_list'
export const THANKYOU_API = 'thank_you_page'
export const GETOTP = 'user/registration'
export const CONFIRMOTP = 'user/verifyOtp'
export const INITIATEPAY = 'payment/initiatePay'
export const USER_DEATIL = 'create_user_detail'
export const CUSTOM_LEADS = 'create_lead?phone='
export const CANCER_FORM_LIST = 'get_cancer_form_list'
export const UPDATE_USER_DETAIL = 'update_user_detail'
export const REGISTEREVENT = 'broadcast_event/broadcast_event_register'
export const SITEMAP = 'sitemap_url'
export const PRODUCT_LIST = 'get-products?page='
export const PRODUCT_FILTER = 'getfilteroptions'
export const PRODUCT_DETAIL = 'get-product-details'
export const PRODUCT_REVIEWS = 'get-product-review?page='
export const ADD_ADDRESS = 'create-address'
export const EDIT_ADDRESS = 'update-address'
export const DELETE_ADDRESS = 'remove_address?id='
export const GET_ADDRESS = 'get-address?userId='
export const GET_COUNTRY = 'getallcountry'
export const GET_STATE = 'getAllstate'
export const GET_CART = 'getByCustomerId?userId='
export const DELETE_CART = 'remove_cart_data?id='
export const UPDATE_CART = 'update_cart'
export const ADD_TO_CART = 'create_cart_data'
export const INITIATE_RAZOR_PAY = 'createRazorPayOrder'
export const CREATE_ORDER = 'product_order/create-products-order'
export const CREATE_APPOINTMENT = 'appointment/create'
export const SAVE_ZIOPAR_URL = 'user/save_ziopar_url'
export const GET_ZIOPAR_URL = 'get_ziopar_result'
export const STORIES_HOPE = 'get_storis_of_hope'
export const CUSTOMER_LEAD = 'insert_custom_lead'
export const CHAT_BOT = 'get_assistence_reply'
