import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./blogPage.css";
import Header from "../../components/Common-Component/Header/header";
import Blogs from "../../components/BlogPage-Component/BlogsComponent/blogsComponent";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { fetchStoryData, fetchStoryFilterlData } from "../../store/story/story-actions";
import { storyModel } from "../../models/story-models";
import Seo from "../../components/Common-Component/SEO/seo";


interface GlobalTranslat {
    translation: any
}
export default function BlogsPage({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(1)
    const storyData = useAppSelector(state => state?.story);
    const storyFilter = useAppSelector(state => state?.storyFilter);
    const [totalData, setTotalData] = useState<Array<storyModel>>([]);
    const [loadMore, setLoadMore] = useState(true);
    const [filteredOption, setFilteredOption] = useState({
        cancer_type: "",
        topic: ""
    });
    useEffect(() => {
        dispatch(fetchStoryFilterlData());
    }, []);

    useEffect(() => {
        let URL = 'get_filter_data_by_postcategory?page=' + page

        if (filteredOption?.topic) {
            URL = URL + '&topic=' + filteredOption?.topic
        }
        // if (filteredOption.cancer_type) {
        //     URL = URL + '&cancer_type=' + filteredOption.cancer_type
        // }
        URL = URL + '&postCategoryId=' + '3'

        dispatch(fetchStoryData(URL));
    }, [page, filteredOption]);

    useEffect(() => {
        if (storyData.status === 1) {
            setLoadMore(true);
            if (storyData?.data?.length > 0) {
                let extra = totalData?.concat(storyData?.data)
                let filtered = extra?.filter((value, i, arr) => { return (arr.findIndex((v) => v.id === value.id) === i); });
                setTotalData(filtered);
            } else {
                setLoadMore(false);
            }
        }
        // window.scrollTo(0, 0);   
    }, [storyData?.data]);

    const handleMorePages = () => {
        setPage(page + 1);
    }

    return (
        <React.Fragment>
            <Seo translation={translation} metaData={storyFilter?.metadatas} />
            <Box>
                <Box className="blogPage-header">
                    <Header darkTheme={true} translation={translation}/>
                </Box>
                <Blogs translation={translation} filteredOption={filteredOption} setFilteredOption={setFilteredOption} pageNo={page} topicList={storyFilter?.topic_list} cancerCategory={storyFilter?.cancer_category}
                    handleMorePages={handleMorePages} loadMore={loadMore} totalData={totalData} setPage={setPage} storyData={storyData?.data} />
                    <BookConsultation translation={translation}/>
                <Box className="blogPage-Footer">
                    <Footer translation={translation} />
                    <MostSearched translation={translation} />
                    <br />
                </Box>
            </Box>
        </React.Fragment>
    );
}