import React from 'react'
import { Avatar, Typography, Box, Link } from '@mui/material'
import "./subDoctor.css"
import { consultDoctorsModel } from "../../../models/home-models";
import { advisoryModel } from '../../../models/about-models';
import {doctorDetailsPageSlug } from '../../../values/paths';

interface consultDoctorsList {
  doctorsList?: Array<consultDoctorsModel>
  advisory: Boolean;
  advisoryList?: Array<advisoryModel>
  translation: any
}

function SubDocter({ translation, doctorsList, advisory, advisoryList }: consultDoctorsList) {
  const data = advisory ? advisoryList : doctorsList

  return (
    <React.Fragment>
      <div className={advisory ? "subdocs_div2" : 'subdocs_div'}>
        {data?.map((doctor, index) => (
          <Box key={doctor.id} className={advisory ? "subdoc_box1" : "subdoc_box"}>
            <Avatar
              src={doctor?.doctorImage}
              className="subdoc_image" alt={doctor?.doctorName} />
            <Typography className="doctername_text">{doctor.doctorName}</Typography>
            <Typography className='doctertype_text'>{doctor?.designation}</Typography>
            {doctor?.expYears !== undefined &&
              <Typography className='doctertype_text'>{doctor?.expYears} {translation?.YrsExp}</Typography>}
            <Typography className='doctertype_text'>{doctor?.sub_district}</Typography>
            {!advisory && <Link className='doctertype-consultbtn' href={doctorDetailsPageSlug(doctor?.slug)}>
              <Typography className="doctername_consultbtntext">{translation?.Consult}</Typography></Link>}
          </Box>
        ))}
      </div>
    </React.Fragment>
  );
}
export default SubDocter
