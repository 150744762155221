
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartModel} from "../../models/product-models";

const initialStoryState: CartModel = {
    status: 0,
    message: '',
    data: [],
}

const getCartSlice = createSlice({
    name: 'getCart',
    initialState: initialStoryState,
    reducers: {
        setGetCart(state, action: PayloadAction<CartModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default getCartSlice;