import React, { useState } from "react";
import "./productsCard.css";
import { Box, Button, Typography, Link } from "@mui/material";
import { useAppDispatch } from "../../../hooks/redux-hooks";
// import { useNavigate } from "react-router";
import { addToCartCart, getCart } from "../../../store/product/product-actions";
import Cookies from "universal-cookie";
import { calculateDiscount } from "../../../values/helper";
import TreatAndOtp from "../../Common-Component/Common-Modals/TreatModelwithOTP/TreatOtp";
import { productDetailsPageSlug } from "../../../values/paths";
// import { handleLink } from "../../Common-Component/NavigationHandle/handleNavigation";


interface ProductDetail {
    getProductDetails?: any
    productItem?: any
    setRefresh?: any
    refresh?: any
    handleAlert: Function
    translation: any
}

export default function ProductsCard({ translation, handleAlert, productItem, getProductDetails, setRefresh, refresh }: ProductDetail) {
    // const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [modal, setModal] = useState(false);
    const cookies = new Cookies();
    const userID = cookies.get('userId')

    const hadleCloseModal = () => {
        setModal(false);
    }
    const hadleOpenModal = () => {
        setModal(true);
    }
    const addToCart = (type: any) => {
        if (cookies.get('token') === undefined) {
            hadleOpenModal()
        } else if (userID !== undefined) {
            let data = {
                customer_id: userID,
                product_id: productItem?.id,
                qunatity: 1,
                price: productItem?.price,
                reguler_price: productItem?.regular_price,
                sale_price: productItem?.price,
                total_price: productItem?.price,
                product_name: productItem?.name,
                image: productItem?.image,
            }
            handleAlert();
            dispatch(addToCartCart(data));
            setTimeout(() => {
                dispatch(getCart(userID));
            }, 1000)
            setRefresh(!refresh);
        }
    }
    return (
        <React.Fragment>
            <Box className="Products-Card">
                <Link href={productDetailsPageSlug(productItem?.slug)} className="naviagtion-card">
                    {productItem?.image.length ? <img src={productItem?.image} className="ProductsCard-Image" alt={productItem?.name} /> : <Box className="ProductsCard-Image" />}
                    <Typography className="ProductsName-txt">
                        {productItem?.name}
                    </Typography>
                    <Typography className="ProductsInfo-txt">
                        {productItem?.productQuantity}
                    </Typography>

                </Link>
                <Box className="ProductPrice-AddBox">
                    <Box>
                        <Box className="Price-discountBox">
                            <Typography className="Product-PriceTxt">
                                {translation?.Rs} {productItem?.regular_price}
                            </Typography>
                            <Typography className="discount-Percentage">
                                {calculateDiscount(productItem?.regular_price, productItem?.price, translation?.OFF)}
                            </Typography>
                        </Box>
                        <Typography className="Discounted-PriceTxt">
                            {translation?.Rs} {productItem?.price}
                        </Typography>
                    </Box>
                    <Box>
                        <Button className="ADD-CartBtn" onClick={() => addToCart('register')} tabIndex={-1}>
                            <Typography className="ADD-txt">
                                {translation?.BuyNow}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <TreatAndOtp translation={translation} setModal={setModal} modal={modal} handleCloseModal={hadleCloseModal} />
            </Box>
        </React.Fragment >
    );
}
