import React, { useState } from "react";
import { Button, Popover, Box } from "@mui/material";
import AppImages from "../../../images/AppImages";
import "./personalizedBox.css";
import { downloadAppPage } from "../../../values/paths";

interface Translate {
    translation: any
}

export default function PersonalizedBox({ translation }: Translate) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    function handleClose() {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <Button disableRipple className="Filter-AppdwnldBox" href={downloadAppPage} target="_blank" >
                {/* <Typography className="DwnldApp-Text">{translation?.DownloadAppcare}</Typography> */}
                <img src={AppImages.DownLoadApp} alt={translation?.Downloadapp} className="Filter-Dwnld-img" />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className="popup-dwnload-BoxP"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}>
                <Box className="popup-dwnloadBox">
                    <Box className="Download-AppPlay-store" >
                        <a href={downloadAppPage} target="_blank" rel="noreferrer" className="Download-PlayStore-btn"  >
                            <img src={AppImages.GooglePlaystore} alt={translation?.Playstore} className="Footer-Store-btns-box" />
                        </a>
                        <a href={downloadAppPage} target="_blank" rel="noreferrer" className="Download-AppStore-btn" >
                            <img src={AppImages.AppStore} alt={translation?.Appstore} className="Footer-Store-btns-box" />
                        </a>
                    </Box>
                </Box>
            </Popover>
        </React.Fragment>
    );
}