import React, { useEffect, useState } from "react";
import "./aboutEvents.css";
import { Box, Button, Divider, Typography, Breadcrumbs } from "@mui/material";
import Faq from "../../Common-Component/Faq/faq";
import AppImages from "../../../images/AppImages";
import { Link } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import { EventDetailDataModel } from "../../../models/event-models";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { registerEventData } from "../../../store/event/event-actions";
import moment from 'moment';
import Cookies from 'universal-cookie';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'
import TreatAndOtp from "../../Common-Component/Common-Modals/TreatModelwithOTP/TreatOtp";
import { handleAlert } from "../../../values/helper";
import { eventsDetailsPage, eventsPage, homePage } from "../../../values/paths";

interface EventDetail {
    eventDetails: Array<EventDetailDataModel>
    translation: any
}
export default function AboutEvent({ translation, eventDetails }: EventDetail) {
    const [modal, setModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useAppDispatch();
    let currentTime = moment(moment(), "hh:mm").format('HH:mm');
    let calendarStartDate = moment(eventDetails[0]?.broadcast_start)
    let time1 = calendarStartDate?.format('HH:mm')
    let joinTime = moment(time1, "hh:mm").subtract(10, 'minutes').format('HH:mm');
    let pastEvents = new Date().getTime() > new Date(eventDetails[0]?.broadcast_start).getTime();
    let verifyTodayDate = new Date().toLocaleDateString() == new Date(eventDetails[0]?.broadcast_start).toLocaleDateString();
    const cookies = new Cookies();
    const registerData = useAppSelector(state => state?.registerEvent);
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const hadleCloseModal = () => {
        setModal(false);
    }
    const hadleOpenModal = () => {
        setModal(true);
    }
    const joinEvent = (items: any, type: string) => {
        if (cookies.get('token') === undefined) {
            hadleOpenModal()
        } else {
            let data = {
                userId: items.user.id,
                eventId: items.id,
                join_register: type
            }
            dispatch(registerEventData(data, cookies.get('token')));
            if (registerData.status === 0 && registerData?.message !== "Event is already registered") {
                handleAlert(setSuccess);
            }
        }
        if (registerData.status === 0) {
            if (registerData?.message?.length > 0) {
                setErrorMessage(registerData.message);
                setOpen(true)
            }
        }
    }
    useEffect(() => {
        if (registerData.status === 0) {
            if (registerData?.message?.length > 0 && registerData?.message === "Event is already registered") {
                setErrorMessage(registerData?.message);
                setOpen(true)
                setSuccess(false)
            }
        }
    }, [registerData]);

    const handleTimeSlots = (items: any) => {
        if (items?.eventType !== 'Offline') {
            joinEvent(items, 'join')
        }
    }

    return (
        <React.Fragment>
            <Box>
                {eventDetails?.map((items, index) => (
                    <TopHeading key={items?.id}>
                        <Breadcrumbs separator={<NavigateNextIcon className="Navigation-Seprator" />} className="navigationDetailBox">
                            <Link href={homePage} underline="none" >
                                <Typography className="EventDetail-Navigationtxt">{translation?.Home}</Typography></Link>
                            <Link href={eventsPage} underline="none" >
                                <Typography className="EventDetail-Navigationtxt">{translation?.HealingEvents}</Typography>
                            </Link>
                            <Link href={eventsDetailsPage} underline="none">
                                <Typography className="EventDetail-Navigationtxt">{items?.name}</Typography></Link>
                        </Breadcrumbs>
                        <Typography className="OnlineYoga-Text">
                            {items?.name}
                        </Typography>
                    </TopHeading>
                ))}
            </Box>
            <Box className="AboutMainContent-Box"  >
                <Box className="AboutMainContent-subBox" >
                    {eventDetails?.map((items, index) => (
                        <Box key={items?.id} >
                            <Box className="eventFlexBox">
                                <img src={items?.image} alt={translation?.EventImage} className="AboutEvent-image1" />
                            </Box>

                        </Box>
                    ))}
                    {eventDetails[0]?.broadcast_events_faqs?.length > 0 &&
                        <>
                            <Divider variant="middle" className="Divider-Margin" />
                            {eventDetails?.length > 0 &&
                                <Box className="Faq-Box">
                                    <Faq FaqText={true} faqanswers={eventDetails[0]?.broadcast_events_faqs} />
                                </Box>}
                        </>}
                </Box>
                {eventDetails?.map((items, index) => (
                    <Box key={items?.id} className="JoinThisMain-Box">
                        <Box className="jointhisevent-box">
                            <Typography className="Jointhisevent-Text">{pastEvents ? translation?.WatchEvent : translation?.JoinEvent}</Typography>
                            <Box className="Join-Date">
                                <img src={AppImages.ClockIcon} className="Date-Icon" alt={translation?.Time} />
                                <Typography className="eventEndTime">{new Date(items?.event_date).getUTCDate()} {Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(items?.event_date))}</Typography>
                                <Typography className="eventEndTime">{new Date(items?.broadcast_start).getUTCHours() + ":" + new Date(items?.broadcast_start).getUTCMinutes()}</Typography>
                                {translation?.to}
                                <Typography className="eventEndTime">
                                    {new Date(items?.broadcast_end).getUTCHours() + ":" + new Date(items?.broadcast_end).getUTCMinutes()} {new Date(items?.broadcast_end).getUTCHours() > 12 ? "am" : "pm"}
                                </Typography>
                            </Box>
                            {items?.google_maps_place?.placeAddress?.length &&
                                <Typography className="Join-Location">
                                    <img src={AppImages.LocationIcon} className="location-icon" alt={translation?.Location} />{items?.google_maps_place?.placeAddress}</Typography>}
                            {currentTime < joinTime ?
                                <Button disableRipple className="OrangeJoin-btn" onClick={() => joinEvent(items, 'register')}>
                                    <Typography className="Joinnow-btntxt">{translation?.Register}</Typography>
                                </Button>
                                : (currentTime >= joinTime) && verifyTodayDate ? <Button className="Join-btn" style={{ backgroundColor: items?.eventType === 'Offline' ? 'lightgray' : '#f57822' }}
                                    onClick={() => handleTimeSlots(items)}>
                                    <Link href={items?.joining_link} target="_blank" underline="none">
                                        <Typography className="Joinnow-btntxt">{translation?.joinnow}
                                        </Typography></Link></Button> :
                                    <Button disableRipple className="OrangeJoin-btn" onClick={() => joinEvent(items, 'register')}>
                                        <Typography className="Joinnow-btntxt">{translation?.Register}</Typography>
                                    </Button>}
                            {success && <Alert severity="success" className="iconButtonSt">
                                <Typography>{translation?.EventRegistered}</Typography>
                            </Alert>}
                            {open && <Alert
                                severity="warning"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() =>
                                            setOpen(false)}>
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                className="iconButtonSt">
                                {errorMessage}
                            </Alert>}
                        </Box>
                        <TreatAndOtp translation={translation} setModal={setModal} modal={modal} handleCloseModal={hadleCloseModal} />
                    </Box>
                ))}
            </Box>
        </React.Fragment>
    );
}
