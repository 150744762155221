
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddToCartModel} from "../../models/product-models";

const initialStoryState: AddToCartModel = {
    status: 0,
    message: '',
    data: [],
}

const addTOCartSlice = createSlice({
    name: 'addToCart',
    initialState: initialStoryState,
    reducers: {
        setAddToCartCart(state, action: PayloadAction<AddToCartModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default addTOCartSlice;