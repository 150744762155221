import React, { Fragment, useState, useEffect } from "react";
import { Box, Divider, Typography, Link, Button } from "@mui/material";
import "./upcomingEvents.css";
import AppImages from "../../../images/AppImages";
import Cookies from 'universal-cookie';
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import { EventDetailDataModel } from "../../../models/event-models";
import { registerEventData } from "../../../store/event/event-actions";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment';
import TreatAndOtp from "../../Common-Component/Common-Modals/TreatModelwithOTP/TreatOtp";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { handleAlert } from "../../../values/helper";
import { eventsDetailsPageSlug } from "../../../values/paths";

interface FutureEventDetails {
    futureEventData: Array<EventDetailDataModel>
    translation: any
}

export default function UpcomingEvents({ futureEventData, translation }: FutureEventDetails) {
    const [modal, setModal] = useState(false);
    const dispatch = useAppDispatch();
    let currentTime = moment(moment(), "hh:mm").format('HH:mm');
    let calendarStartDate = moment(futureEventData[0]?.broadcast_start)
    let time1 = calendarStartDate.format('HH:mm')
    let joinTime = moment(time1, "hh:mm").subtract(10, 'minutes').format('HH:mm');
    let verifyTodayDate = new Date().toLocaleDateString() == new Date(futureEventData[0]?.broadcast_start).toLocaleDateString();
    const cookies = new Cookies();
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const registerData = useAppSelector(state => state?.registerEvent);
    const [success, setSuccess] = useState(false);
    const [selected, setSelected] = useState<any>();
    const hadleOpenModal = () => {
        setModal(true);
    }
    const hadleCloseModal = () => {
        setModal(false);
    }
    const joinEvent = (items: any, type: string, index: any) => {
        setSelected(index);
        if (cookies.get('token') === undefined) {
            hadleOpenModal()
        } else {
            let data = {
                userId: items.user.id,
                eventId: items.id,
                join_register: type
            }
            dispatch(registerEventData(data, cookies.get('token')));
            if (registerData?.message !== "Event is already registered") {
                handleAlert(setSuccess);
            }
        }
        if (registerData.status === 0) {
            if (registerData?.message?.length > 0 && registerData?.message === "Event is already registered") {
                setErrorMessage(registerData.message);
                handleAlert(setOpen)
            }
        }
    }
    const handleEvents = (event: any, index: any) => {
        setSelected(index);
        if (event?.eventType !== 'Offline') {
            joinEvent(event, 'join', index);
        }
    }
    useEffect(() => {
        if (registerData.status === 0) {
            // if (registerData?.message?.length) {
            if (registerData?.message !== "Event is already registered") {
                handleAlert(setSuccess);
            } else if (registerData?.message === "Event is already registered") {
                setErrorMessage(registerData?.message);
                handleAlert(setOpen)
                setSuccess(false)
            }
            // }
        }
    }, [registerData]);
    return (
        <React.Fragment>
            <TopHeading>
                <Box className="HealingEvents-MainBox" >
                    <Box className="healingEvents-box1">
                        <Typography className="HealingEvents-Text">
                            {translation?.HealingEvents}
                        </Typography>
                        <Typography className="Consultation-wellness-Text">
                            {translation?.ConsultationToWellness}
                        </Typography>
                    </Box>
                    <Box className="healingImagBox">
                        <img src={AppImages.EventHead} alt="" className="healignImage" />
                    </Box>
                </Box>
            </TopHeading>
            <Box className="upcomingComponent">
                <Box className="upcoming-txtBox">
                    <Typography className="upcoming-txt">
                        {translation?.Upcomingevents}
                    </Typography>
                </Box>
                <Divider className="upcomingEvent-divider" />
                <Box className="AllEvents-MainBox">
                    {futureEventData.map((items, index) => (
                        <Fragment key={items?.id}>
                            <Box key={items.id} className="upcomingEvents-Link" >
                                <Box className="Upcoming-events-Infobox1">
                                    <Button disableRipple className="Upcoming-events-Infobox" href={eventsDetailsPageSlug(items?.slug)}>
                                        <Box className="ComingEvent-MainImg-box">
                                            <img src={items.image} alt={translation?.EventImage} className="ComingEvents-img-box" />
                                        </Box>
                                        <Box className="nameTodesc-Box">
                                            <div className="Events-Info-txt"
                                                dangerouslySetInnerHTML={{
                                                    __html: items.description
                                                }} />
                                            <Typography className="Dr-Name-text">{items.name}</Typography>
                                            {items.user.user_details.map((name, index) => (
                                                <Typography key={name.name} className="Events-About-txt"> {translation?.by} {name.name} </Typography>))}
                                            {/* <Typography key={items.id} className="Events-About-txt">{items.eventType} </Typography> */}
                                            <Box className="flex-date-timeBox" >
                                                <Typography className="Events-TimeDate-Txt">
                                                    <img src={AppImages.ClockIcon} className="upcoming-Dateicon" alt={translation?.Time} />
                                                    {new Date(items.broadcast_start).getUTCDate()} {Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(items.broadcast_start))}, {Intl.DateTimeFormat('en', { year: "numeric" }).format(new Date(items.broadcast_start))}
                                                </Typography>
                                                <Divider orientation="vertical" className="dattime-Divivder" />
                                                <Typography className="Events-TimeDate-Txt">
                                                    {/* {Intl.DateTimeFormat('en', { timeStyle:"short" }).format(new Date(items.broadcast_start))} */}
                                                    {new Date(items.broadcast_start).getUTCHours()} :  {new Date(items.broadcast_start).getUTCMinutes()} {new Date(items.broadcast_start).getUTCHours() > 12 ? "PM" : "AM"}
                                                    - {new Date(items.broadcast_end).getUTCHours()} :  {new Date(items.broadcast_end).getUTCMinutes()} {new Date(items.broadcast_end).getUTCHours() > 12 ? "PM" : "AM"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Button>
                                    <Box className="Location-To-BtnBox" >
                                        <Typography className="Events-Type-txt">{items?.eventType}</Typography>
                                        <Typography className="Events-Payment-txt">FREE</Typography>
                                        {currentTime < joinTime ?
                                            <Button disableRipple className="join-Now-btn" onClick={() => joinEvent(items, 'register', index)} >
                                                <Typography className="Joinnow-btntxt">{translation?.Booknow}</Typography>
                                            </Button>
                                            : (currentTime >= joinTime) && verifyTodayDate ? <Button className="join-Now-btn" style={{ backgroundColor: items?.eventType === 'Offline' ? 'lightgray' : '#f57822' }}
                                                // onClick={() => {if (items?.eventType !== 'Offline') {joinEvent(items, 'join')}}}
                                                onClick={() => handleEvents(items, index)}
                                            >
                                                <Link href={items.joining_link} target="_blank" underline="none">
                                                    <Typography className="Joinnow-btntxt">{translation?.joinnow}
                                                    </Typography></Link></Button> :
                                                <Button disableRipple className="join-Now-btn" onClick={() => joinEvent(items, 'register', index)}>
                                                    <Typography className="Joinnow-btntxt">{translation?.Booknow}</Typography>
                                                </Button>}
                                        {success && selected === index && <Alert severity="success" className="iconButtonSt">
                                            <Typography>{translation?.EventRegistered}</Typography>
                                        </Alert>}
                                        {selected === index && open &&
                                            <Alert
                                                severity="warning"
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() =>
                                                            setOpen(false)}>
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>}
                                                className="iconButtonSt">
                                                {errorMessage}
                                            </Alert>}
                                    </Box>
                                </Box>
                                {/* <div className="Events-Info-txt"
                                    dangerouslySetInnerHTML={{
                                        __html: items.description
                                    }} /> */}
                                <Divider className="upcomingDvd" />
                            </Box>
                            <Divider className="upcomingEvent-divider" />
                        </Fragment>
                    ))}
                </Box>
                <TreatAndOtp translation={translation} setModal={setModal} modal={modal} handleCloseModal={hadleCloseModal} />
            </Box>
        </React.Fragment>
    );
}