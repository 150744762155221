
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductFilterModel } from "../../models/product-models";

const initialStoryState: ProductFilterModel = {
    status: 0,
    message: '',
    filter_by:[]
}

const productFilterSlice = createSlice({
    name: 'productFilter',
    initialState: initialStoryState,
    reducers: {
        setProductFilter(state, action: PayloadAction<ProductFilterModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.filter_by = action.payload?.filter_by;
        }
    }
});
export default productFilterSlice;