import React, { useEffect, useState } from "react";
import { Box} from "@mui/material";
import "./doctorTalkPage.css";
import Header from "../../components/Common-Component/Header/header";
import DoctorTalkHead from "../../components/DoctorTalk-Component/DoctorTalkHead/doctorTalkHead";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import VideoCard from "../../components/DoctorTalk-Component/VideoCards-Component/videoCards";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { fetchDoctorTalkFilterlData, fetchStoryData } from "../../store/story/story-actions";
import Seo from "../../components/Common-Component/SEO/seo";
interface GlobalTranslat {
    translation: any
}
export default function DoctorTalk({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const doctorData = useAppSelector(state => state?.story);
    const doctorFilter = useAppSelector(state => state?.doctorTalkFilter);
    const [page, setPage] = useState(1);
    // const mobileView = useMediaQuery("(max-width:600px)");
    const PageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }
    useEffect(() => {
        let URL = 'get_filter_data_by_postcategory?page=' + page + '&postCategoryId=' + '7,8'
        dispatch(fetchStoryData(URL));
        dispatch(fetchDoctorTalkFilterlData());
    }, [])
    const handleTypeFilter = (cancerType: number, topic: string) => {
        let URL = 'get_filter_data_by_postcategory?page=' + page + '&postCategoryId=' + '7,8'
        if (topic == 'all') {
            dispatch(fetchStoryData(URL));
        } else {
            URL = URL + '&topic=' + topic + '&cancer_type=' + cancerType + '&postCategoryId=' + '7,8'
            dispatch(fetchStoryData(URL));
        }
    }
    return (
        <Box className="DoctorTalk">
            <Seo translation={translation} metaData={doctorFilter.metadatas} />
            <Box>
                <Box className="DoctorTalk-Header">
                    <Header darkTheme={true} translation={translation}/>
                </Box>
                <Box>
                    <DoctorTalkHead translation={translation} cancerCategory={doctorFilter.cancer_category} topicList={doctorFilter.topic_list} handleTypeFilter={handleTypeFilter} />
                </Box>
            </Box>
            <VideoCard translation={translation} dark={true} pages={page} pageChange={PageChange} doctorData={doctorData?.data} />
            <BookConsultation translation={translation}/>
            <Box className="Footer">
                <Footer translation={translation}/>
                <MostSearched translation={translation}/>
                <br />
            </Box>
        </Box>
    );
}