
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createOrderModel } from "../../models/product-models";

const createOrderState: createOrderModel = {
    status: 0,
    message: '',
}

const createOrderSlice = createSlice({
    name: 'createOrder',
    initialState: createOrderState,
    reducers: {
        createOrder(state, action: PayloadAction<createOrderModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
        }
    }
})
export default createOrderSlice;