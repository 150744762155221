import fultureEventSlice from './futureEvent-slice'
import pastEventSlice from './pastEvent-slice'
import eventDetailSlice from './eventDetail-slice'
import registerEventSlice from './registerEvent-slice'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { FutureEventModel, PastEventModel, EventDetailModel } from "../../models/event-models";
import EventService from '../../service/eventService';

export const futureEventActions = fultureEventSlice.actions
export const pastEventActions = pastEventSlice.actions
export const eventDetailActions = eventDetailSlice.actions
export const registerEventActions = registerEventSlice.actions

export const fetchFutureEventData = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: FutureEventModel = await EventService.getFutureEvents();
        dispatch(futureEventActions.setFutureEventData(response))
    }
}

export const fetchPastEventData = (page: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: PastEventModel = await EventService.getPastEvents(page);
        dispatch(pastEventActions.setPastEventData(response))
    }
}

export const eventDetailData = (id: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: EventDetailModel = await EventService.getEventDetail(id);
        dispatch(eventDetailActions.setEventDetailData(response))
    }
}

export const registerEventData = (data: any,token:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: EventDetailModel = await EventService.registerEvent(data,token);
        dispatch(registerEventActions.setRegisterEventData(response))
    }
}