import React from "react";
import "./queriesAnswers.css";
import { Box, Typography, TextField } from "@mui/material";
import Faq from "../../Common-Component/Faq/faq";
import { faqModel } from "../../../models/faq-models";
import SearchIcon from '@mui/icons-material/Search';

interface QueriesData {
    queries_answers: Array<faqModel>;
    searchFAQ: any;
    onChangeSearch: any;
    translation:any
}

export default function QueriesAnswer({translation, queries_answers, searchFAQ, onChangeSearch }: QueriesData) {
    return (
        <React.Fragment>
            <Box className="FaqSearch-Box">
                <TextField
                    value={searchFAQ}
                    onChange={onChangeSearch}
                    size="small"
                    className="FAQsearch-Box-input" placeholder={translation?.SearchFAQ} InputProps={{
                        startAdornment: (
                            <SearchIcon className="Search-Icon" />)
                    }} />
            </Box>
    
            {queries_answers.map((items, index) => (
                <Box className="WellnesscareFaq-Box" key={items.id}>
                    <Typography className="FaqCareWellness-Text">
                        {items.name}
                    </Typography>
                    <Faq FaqText={false} faqanswers={items.faq_questions}/>
                </Box>
            ))}
        </React.Fragment>
    );
}