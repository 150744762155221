import { stat } from "fs";
import { FAQDataModel } from "../../models/faq-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialFAQState: FAQDataModel = {
    status: 0,
    message: '',
    data: [],
    metadatas:[]
}

const faqSlice = createSlice({
    name: 'faq',
    initialState: initialFAQState,
    reducers: {
        setFAQData(state, action: PayloadAction<FAQDataModel>) {
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
            state.metadatas = action.payload?.metadatas;
        }
    }
})

export default faqSlice;