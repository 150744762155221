import React, { Fragment } from 'react'
import { Box, Button, Skeleton, Typography } from '@mui/material'
import './cancerFigthing.css'
import Exportcounsult from '../../Common-Component/CousultExpDoctor/exportconsult'
import useMediaQuery from '@mui/material/useMediaQuery';
import { productListPage } from '../../../values/paths';

export default function CancerFighting(Props: { Product: any, productlist?: any, translation: any }) {
  const expertcard = useMediaQuery('(min-width:601px)');
  return (
    <Fragment>
      {expertcard ?
        <Box className='Main-cancerfighting_box'>
          <Box className="cancerfighting-box">
            <Typography className="CounsultExpert-Medical-text">
              {Props?.translation?.Getmedicienssupplements}
            </Typography>
            <Typography className="CounsultExpert-Points">
              {/* <img src={AppImages.Check} alt="Point" className='cancerfighting-tik-Icon' /> */}
              {Props?.translation?.OxidantsInflammatory} | {Props?.translation?.Managesleep}
            </Typography>
          </Box>
          <Box className="CounsultExpert-Cards" >
            <Exportcounsult translation={Props?.translation} doctorsList={Props.Product} productlist={Props.productlist} />
          </Box>
          <Button disableRipple href={productListPage} className="cancerfighting-button">
            <Typography className="CounsultExpert-btntext">{Props?.translation?.Viewallevents}</Typography>
          </Button>
        </Box> :
        <Box className='Main-cancerfighting_box'>
          <Box className="cancerfighting-box">
            <Typography className="CounsultExpert-Medical-text">
              {Props?.translation?.Getmedicienssupplements}
            </Typography>
          </Box>
          <Box className="CounsultExpert-Cards">
            {Props?.productlist?.length ? <Exportcounsult translation={Props?.translation} doctorsList={Props.Product} productlist={Props.productlist} />
              : <Skeleton variant="rounded" animation="wave" className="skeletonProducts" />
            }
          </Box>
          <Box className="cancerfighting-button-box">
            <Button disableRipple href={productListPage} className="CounsultExpert-button">
              <Typography className="CounsultExpert-btntext">{Props?.translation?.SeeAll}</Typography>
            </Button>
          </Box>
        </Box>}
    </Fragment>
  );
}
