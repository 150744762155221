
import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { metaDataModel } from "../../../models/home-models";

export default function Seo(Props: { metaData?: Array<metaDataModel>; title?: string, translation?: any }) {
    return (
        <HelmetProvider>
            <Helmet htmlAttributes={{ lang: "en" }} title={Props.title}>
                <link href="http://localhost:3000/" />
                {Props.metaData?.map((items, i) => {
                    if (items?.keyType === 1) {
                        return (<meta key={items?.key} name={items?.key} content={items?.value} />
                        )
                    } else if (items?.keyType === 2) {
                        return (
                            <meta key={items?.key} property={items?.key} content={items?.value} />
                        )
                    } else {
                        document.title = items !== null ? items?.value : Props?.translation?.ZenOncoIO;
                    }
                })}
            </Helmet>
        </HelmetProvider>
    );
}