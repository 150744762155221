import React, { Fragment } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Common-Component/Header/header";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import SharkTankvideo from "../../components/Common-Component/SharkTank-Component/sharkTankVideo/sharkTankVideo";
import "./sharkTankPage.css";

interface GlobalTranslat {
    translation: any
}
export default function SharkTankPage({ translation }: GlobalTranslat) {
    return (
        <Fragment>
            <Box className="sharkTank-Header">
                <Header darkTheme={true} translation={translation}/>
            </Box>
            <Box className="sharkTank-VideoBox">
                <SharkTankvideo />
            </Box>
            <BookConsultation translation={translation}/>
            <Box className="sharkTankFooter">
                <Footer translation={translation}/>
                <MostSearched translation={translation}/>
                <br />
            </Box>
        </Fragment>
    );
}