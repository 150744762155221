import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./productsCartPage.css";
import Header from "../../components/Common-Component/Header/header";
import MostSearched from '../../components/Common-Component/MostSearched/mostSearched';
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import ProductCartHead from "../../components/ProductsCartPage-Component/productCartHead/productCartHead";
import ProductsCart from "../../components/ProductsCartPage-Component/ProductsCart/productsCart";
import { useAppSelector } from "../../hooks/redux-hooks";
import { CartModel } from "../../models/product-models";

interface GlobalTranslat {
    translation: any
}
export default function ProductsCartPage({ translation }: GlobalTranslat) {
    const [cart, setCartData] = useState(Array<CartModel>);
    const cartData = useAppSelector(state => state?.cartList);
    useEffect(() => {
        setCartData(cartData.data)
        // window.scrollTo(0, 0);
    }, [cartData]);

    return (
        <React.Fragment>
            <Box className="ProductsCart-Header">
                <Header darkTheme={true} cartPage={true} translation={translation}/>
            </Box>
            <ProductCartHead translation={translation} />
            <ProductsCart cart={cart} translation={translation} />
            <BookConsultation translation={translation}/>
            <Box className="Footer">
                <Footer translation={translation}/>
                <MostSearched translation={translation}/>
                <br />
            </Box>
        </React.Fragment>
    );
}