
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductDeleteModel } from "../../models/product-models";

const initialStoryState: ProductDeleteModel = {
    status: 0,
    message: '',
    data: [],
}

const productDeleteSlice = createSlice({
    name: 'productDelete',
    initialState: initialStoryState,
    reducers: {
        setProductDelete(state, action: PayloadAction<ProductDeleteModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default productDeleteSlice;