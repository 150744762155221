
import Api from './Api';
import { INITIATE_RAZOR_PAY, PRODUCT_DETAIL, ADD_ADDRESS, DELETE_ADDRESS, EDIT_ADDRESS, GET_ADDRESS, GET_COUNTRY, GET_STATE, GET_CART, DELETE_CART, ADD_TO_CART, UPDATE_CART, PRODUCT_REVIEWS, PRODUCT_FILTER, CREATE_ORDER } from '../constants/ApiName'
import Cookies from 'universal-cookie';
import Api2 from './Api2';

const cookies = new Cookies();

const config = {
    headers: {}
};

const tokenConfig = {    
    headers: {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
};

export default {
    async getProductList(URL: any) {
        var response = await Api().get(URL, config);
        console.log("Product list Response----", response.data)
        return response.data;
    },
    async getProductDetail(data: any) {        
        var response = await Api().post(PRODUCT_DETAIL,data, config);
        console.log("Product DETAIL Response----", response.data)
        return response.data;
    },
 
    async getProductFilterData() {
        var response = await Api().get(PRODUCT_FILTER, config);
        console.log("Product Filter Response----", response.data)
        return response.data;
    },

    async getProductReview(data: any) {
        var response = await Api().get(PRODUCT_REVIEWS + data.page + '&product_id=' + data.id , config);
        console.log("Product Review Response----",data, response.data)
        return response.data;
    },

    async addProductAddress(data: any) {
        var response = await Api().post(ADD_ADDRESS, data, config);
        console.log("Response Add address----", response.data)
        return response.data;
    },
 
    async editProductAddress(data: any) {
        var response = await Api().put(EDIT_ADDRESS, data, config);
        console.log("Response Edit address----", response.data)
        return response.data;
    },

    async deleteAddress(data: any) {
        var response = await Api().delete(DELETE_ADDRESS + data.id + '&userId=' + data.userId, config);
        console.log("Delete address----", response.data)
        return response.data;
    },

    async getAddressData(id: any) {
        var response = await Api().get(GET_ADDRESS + id, config);
        console.log("GET address----", response.data)
        return response.data;
    },

    async getCountryData() {
        var response = await Api().get(GET_COUNTRY, config);
        console.log("GET Country----", response.data)
        return response.data;
    },

    async getStateData() {
        var response = await Api().get(GET_STATE, config);
        console.log("GET State----", response.data)
        return response.data;
    },

    async getCartData(id:number) {
        var response = await Api().get(GET_CART + id, config);
        console.log("Response GET Cart----", response.data)
        return response.data;
    },

    async deleteCartData(data:any) {
        var response = await Api().delete(DELETE_CART + data.id, tokenConfig);
        console.log("Cart Delete Response----", response.data)
        return response.data;
    },

    async updateCartData(params:any,token:any) {
        const tokenConfigExtra = {    
            headers: {
                'Authorization': 'Bearer ' + cookies.get('token') || token
            }
        };
        var response = await Api().post(UPDATE_CART, params, tokenConfigExtra);
        console.log("Response Update Cart----", response.data)
        return response.data;
    },

    async addToCartData(data:any) {
        var response = await Api().post(ADD_TO_CART, data , config);
        console.log("Response Add to cart----", response.data)
        return response.data;
    },

    async initiatePay(data:any) {
        var response = await Api().post(INITIATE_RAZOR_PAY, data , config);
        console.log("Response INITIATE RAZOR PAY----", response.data)
        return response.data;
    },

    async createOrderData(data:any) {
        var response = await Api2().post(CREATE_ORDER, data , tokenConfig);
        console.log("Response createOrderData----", response.data)
        return response.data;
    },
}