import React, { Fragment} from 'react'
import './searchHeading.css'
import TopHeading from '../../Common-Component/TopHeadingComponent/topHeading'
import { Box, Button, TextField, Typography } from '@mui/material'
import AppImages from '../../../images/AppImages'
import InputAdornment from '@mui/material/InputAdornment';
import ButtonsName from "../btns.json";

interface SearchHeadData {
    handleTabBtn: Function
    selectedButton: any
    oncologistLength: any
    medecineLength: any
    eventsLength: any
    blogsLength: any
    expertsLength: any
    handleSearchText: any
    searchText: any
    translation: any
}

export default function SearchHeading({ translation, handleSearchText, searchText, oncologistLength, medecineLength, eventsLength, blogsLength, expertsLength, handleTabBtn, selectedButton }: SearchHeadData) {
    const totalData = oncologistLength + medecineLength + eventsLength + eventsLength + blogsLength + expertsLength
    return (
        <Fragment>
            <TopHeading>
                <Box className='SearchHeading-mainbox'>
                    <Box className="SearchHeading-TxtBox">
                        <Typography className="SearchHeading-Text">
                            {translation?.Howcanwehelp}
                        </Typography>
                        <TextField
                            value={searchText}
                            onChange={handleSearchText}
                            size="medium"
                            className="SearchHeading-searchbar"
                            placeholder={translation?.Searchhere}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment:
                                    <InputAdornment position="end">
                                        <img src={AppImages.SearchBarIcon} className='searchicon' alt={translation?.Search}/>
                                    </InputAdornment>,
                            }} />
                        {selectedButton !== null ? <Typography className="listLength-Txt">
                            {selectedButton === 0 ? oncologistLength : selectedButton === 1 ? medecineLength : selectedButton === 2 ? eventsLength : selectedButton === 3 ? blogsLength : expertsLength}
                            {translation?.resultsfor} {selectedButton === 0 ? translation?.Oncologists : selectedButton === 1 ? translation?.Medicine : selectedButton === 2 ? translation?.Events : selectedButton === 3 ? translation?.Blogs : translation?.Experts}
                        </Typography>
                            :
                            <Typography className="listLength-Txt">
                                {totalData} {translation?.resultsfor} {translation?.alldatas}
                            </Typography>
                        }
                        <Box className='searchheading-tabssbox'>
                            {ButtonsName.Buttons.map((items, index) => (
                                <Button key={items?.id} onClick={() => handleTabBtn(index)} className={selectedButton === index ? 'selecttabs-btnbox' : 'tabs-btnbox'} disableRipple>
                                    <Typography className={selectedButton === index ? 'selcteed-btntext' : 'searchbar-btntext'}>{items?.name}</Typography>
                                </Button>))}
                        </Box>
                    </Box>
                </Box>
            </TopHeading>
        </Fragment>
    );
}
