import React from "react";
import "./notFound.css";
import { Box, Typography } from "@mui/material";

export default function NotFound() {
    return (
        <Box className="notFoundMain-box">
            <Typography className="notFound-txt">
                404 Not found
            </Typography>
        </Box>
    );
}