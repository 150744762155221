import React from "react";
import { Box, Typography } from "@mui/material";
import "./aboutUsHead.css";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import VideoBox from "../../Common-Component/VideoBox/videoBox";
import { homeVideoModel } from "../../../models/home-models";

interface VideoUrl {
    Video: Array<homeVideoModel>
    translation: any;
    loading: any
}
export default function AboutusHead({ loading, Video, translation }: VideoUrl) {

    return (
        <React.Fragment>
            <TopHeading>
                <Box className="TeamofCoaches-box">
                    <Typography className="TeamofCoaches-text">
                        {translation?.teamofCancerCoaches}
                    </Typography>
                    <Box className="Teamofcoaches-Video-Box">
                        <VideoBox loading={loading} Video={Video} isFromAbout={true} />
                    </Box>
                </Box>
            </TopHeading>
        </React.Fragment>
    );
}