import React from "react";
import "./TopHeading.css";
import { Box } from "@mui/material";

type Props = {
    children: | JSX.Element
    | JSX.Element[];
}
export default function TopHeading({ children }: Props) {
    return (
        <React.Fragment>
            <Box className="TopHeading-Box">
                {children}
            </Box>
        </React.Fragment>
    );
}