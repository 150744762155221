
import Api from './Api';
import {HOME_API,GET_ZIOPAR_URL, FEEDBACK_API,MOST_SEARCHED, SAVE_ZIOPAR_URL,STORIES_HOPE,GLOBAL_SEARCH, CUSTOMER_LEAD, CHAT_BOT} from '../constants/ApiName'
import Api2 from './Api2';
import Cookies from 'universal-cookie';
import Api4 from './Api4';

const config = {
    headers:{
    },
  };

  const configContent = {
    headers:{
        'Content-Type': 'text/plain',
    },
  };
const cookies = new Cookies();

const tokenConfig = {    
    headers: {
        'Authorization': 'Bearer ' + cookies.get('token'),
    },
};

export default{
    async getAllHome(){
        var response= await Api().get(HOME_API, config);
        console.log("Home Response----", response)
        return response.data;
    },
    async getAllFeedback(page: number){
        var response= await Api().get(FEEDBACK_API + page, config);
        console.log("Feedback Response----", response)
        return response.data;
    },
    async getGlobalSearch(searchText: string){
        var response= await Api2().get(GLOBAL_SEARCH + searchText, config);
        console.log("Global Search Response----", response)
        return response.data;
    },
    async getMostSearched(){
        var response= await Api().get(MOST_SEARCHED, config);
        console.log("Most Searched Response----", response)
        return response.data;
    },
    async saveziopar(data:any){
        var response= await Api2().post(SAVE_ZIOPAR_URL, data, tokenConfig);
        console.log("SAVE ZIOPAR URL Response----", response)
        return response.data;
    },

    async customLead(data:any){
        var response= await Api().post(CUSTOMER_LEAD, data, config);
        console.log("Customer lead Response----", response)
        return response.data;
    },

    async chatBotAnswer(data:any){
        var response= await Api().post(CHAT_BOT, data, config);
        console.log("CHAT BOT Response----", response)
        return response.data;
    },

    async setziopar(data:any){
        var response= await Api().post(GET_ZIOPAR_URL, data, config);
        console.log("Download ZIOPAR URL Response----", response)
        return response.data;
    },
    async getStoriesHope(){
        var response = await Api().get(STORIES_HOPE, config);
        console.log("Stories Hope Response----", response)
        return response.data;
    },
}