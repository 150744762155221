import React from "react";
import "./treatmentInfo.css";
import { Box } from "@mui/material";
import DOMPurify from 'dompurify';

export default function TreatmentInfo(Props: { data: any, }) {

  const cleanHTML = DOMPurify.sanitize(Props.data?.content, { ALLOWED_TAGS: ["a", "img", 'br', 'n', 'p', 'strong', "ul", "li", "h2", "h3", "h4", "h5", "tr", "td", "table", "tbody", "figure"] });
  const replace1 = cleanHTML?.replace("https://youtu.be/iXs987eWclE", '<a target="_blank"  style="color:#ff7540;text-decoration: none;" href="https://youtu.be/iXs987eWclE">https://youtu.be/iXs987eWclE<a/>');
  const replace2 = replace1?.replace("Conversation with Oral Cancer winner Nasreen HashmiPlay EpisodePause EpisodeMute/Unmute EpisodeRewind 10 Seconds1xFast Forward 30 seconds00:00/SubscribeShareRSS FeedShareLinkEmbed", "");
  const replace3 = replace2.replaceAll("</li>", "</li><br/>")
  return (
    <Box>
      <Box className="TreatmentInfo-Box">
        {Props?.data?.image && Props?.data?.image !== "" &&
          <img src={Props?.data?.image} alt="" className="ExpertImage" />}
        <div className="ExpertDesc-Text"
          dangerouslySetInnerHTML={{
            __html: replace3
          }} />
      </Box>
    </Box>
  );
}