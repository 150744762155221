import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { CONFIRMOTPModel, CancerFormModel, INITIALPAYModel, OTPModel, UpdateUserDetailModel, customLeadsModel, userDetailModel } from "../../models/doctor-models";
import DoctorService from '../../service/doctorService';
import otpSlice from './otp-slice';
import confirmOtpSlice from './confirmOTP-slice';
import initialPaySlice from './initiatePay-slice';
import userDetailSlice from './userDetail-slice';
import customLeadsSlice from './customLeads-slice';
import cancerFormListSlice from './cancerFormList-slice';
import updateUserDetailSlice from './updateUserDetail-slice';

export const otpActions = otpSlice.actions
export const confirmOtpActions = confirmOtpSlice.actions
export const initialPaySliceActions = initialPaySlice.actions
export const userDetailActions = userDetailSlice.actions
export const customLeadsActions = customLeadsSlice.actions
export const cancerFormListActions = cancerFormListSlice.actions
export const updateUserDetailActions = updateUserDetailSlice.actions

export const getOTPRequests = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: OTPModel = await DoctorService.getOTP(data);
        dispatch(otpActions.setOTPData(response))
    }
}

export const confirmOTP = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: CONFIRMOTPModel = await DoctorService.confirmOTP(data);
        dispatch(confirmOtpActions.setConfirmOTPData(response))
    }
}

export const initiatePay = (data: any, token?: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: INITIALPAYModel = await DoctorService.initiatePayment(data,token);
        dispatch(initialPaySliceActions.setInitaialPayData(response))
    }
}

export const handleUserDetail = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: userDetailModel = await DoctorService.userDeatils(data);
        dispatch(userDetailActions.setUserDetailData(response))
    }
}

export const customLeads = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: customLeadsModel = await DoctorService.customLeadsData(data);
        dispatch(customLeadsActions.setCustomLeadsData(response))
    }
}

export const getCancerFormList = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: CancerFormModel = await DoctorService.fetchCancerFormList();
        dispatch(cancerFormListActions.setCancerFormList(response))
    }
}

export const updateUserDetail = (data:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: UpdateUserDetailModel = await DoctorService.updateUserDetail(data);
        dispatch(updateUserDetailActions.setUpdateUserDetailData(response))
    }
}