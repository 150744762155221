
import Api from './Api';
import { STORY_API, STORY_DETAIL_API, BLOG_DROPDOWN, DOCTOR_TALK_DROPDOWN } from '../constants/ApiName'

const config = {
    headers: {}
};

export default {
    async getStory(url: string) {
        var response = await Api().get(url, config);
        console.log("Story Response----", response)
        return response.data;
    },
    async getDetailStory(data: any) {
        var response = await Api().post(STORY_DETAIL_API, data, config);
        console.log("Story Detail Response----", response)
        return response.data;
    },
    async getStoryDropdown() {
        var response = await Api().get(BLOG_DROPDOWN, config);
        console.log("Blog Dropdown Response----", response)
        return response.data;
    },
    async getDoctorTalkDropdown() {
        var response = await Api().get(DOCTOR_TALK_DROPDOWN, config);
        console.log("Doctor Dropdown Response----", response)
        return response.data;
    },
}