import React, { useState } from "react";
import "./messageBtn.css";
import { Button, Collapse } from "@mui/material";
import { KeyboardArrowDown, } from "@mui/icons-material";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MessageBox from "../MessageBox/messageBox";

interface Translate {
    translation: any
}
export default function MessageBtn({ translation }: Translate) {
    const [showChat, setShowChat] = useState(false);

    const handleChatMessage = () => {
        setShowChat(!showChat);
    }
    return (
        <>
            <Button aria-label="messageButton" disableRipple className={showChat ? "messageBtn1" : "messageBtn"} onClick={handleChatMessage}>
                {showChat ? <KeyboardArrowDown className="Message-Stickyimg" /> : <QuestionAnswerIcon className="Message-Stickyimg" />}
            </Button>
            {showChat &&
                <MessageBox translation={translation} />
            }
        </>
    );
}