import React, { Fragment } from "react";
import { Box } from "@mui/material";
import "./sharkTankVideo.css";
import ReactPlayer from "react-player";


export default function SharkTankvideo() {
    return (
        <Fragment>
            <Box className="SharkTank-MainBox">
                <ReactPlayer controls url="https://zenonco-web-image.s3.ap-south-1.amazonaws.com/videos/shark-tank-india.mp4" width="100%" height="100%" />
            </Box>
        </Fragment>
    );
} 