import React, { useState, useEffect, Suspense } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Common-Component/Header/header";
import MeetDoctors from "../../components/DoctorsList-Component/MeetDoctors/meetDoctors";
// import ListOfDoctors from "../../components/DoctorsList-Component/ListOfDoctors/listOfDoctors";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import "./doctorsListPage.css";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { fetchDoctorListData, fetchDoctorFilterData, createAppointment } from '../../store/doctor/doctor-actions';
import { fetchDoctorScheduleData } from '../../store/doctor/doctor-actions';
import { handleSlots } from "../../utils/commonFunctions";
import { getTimeSlot } from "../../utils/commonFunctions";
import { initiatePay } from "../../store/doctor/otp-actions";
import Cookies from 'universal-cookie';
import { handleAlert } from "../../values/helper";
import useRazorpay from "react-razorpay";

const ListOfDoctors = React.lazy(() => import("../../components/DoctorsList-Component/ListOfDoctors/listOfDoctors"));

interface GlobalTranslat {
    translation: any
}
export default function DoctorsList({ translation }: GlobalTranslat) {
    const Razorpay = useRazorpay();
    const dispatch = useAppDispatch();
    const [modal, setModal] = useState(false);
    // const [bookmodal, setBookModal] = useState(false);
    const cookies = new Cookies();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const doctorListData = useAppSelector(state => state?.doctorList);
    const doctorFilterData = useAppSelector(state => state?.doctorFilter);
    const doctorScheduleData = useAppSelector(state => state?.doctorSchedule);
    // const appointmentData = useAppSelector(state => state?.createAppointment);
    const doctorData = useAppSelector(state => state?.doctor);
    const paymentData = useAppSelector(state => state?.Payment);
    const [availableDates, setAvailableDates] = useState([]);
    const [doctorID, setDoctorID] = useState(0);
    const [slots, setSlots] = useState([]);
    const [time, setTime] = useState<any[]>([]);
    const [alert, setAlert] = useState(false);
    const [noDoctorData, setNoDoctorData] = useState(false);
    const [categoryID, setCategoryID] = useState({ categoryId: "" })
    const [selectedChip, setSelectedChip] = useState(-5);
    const [filteredData, setFilteredData] = useState({
        city: "",
        speciality: "",
        cancer_type: "",
    });
    const [selectedDr, setSelectedDr] = useState(-5);
    const [selectedTime, setSelectedTime] = useState('');
    const [date, setDate] = useState<any>({});
    const [sucessModal, setSucessModal] = useState(false);

    //NOTE: Life cycle Methods
    useEffect(() => {
        let URL_SCHEDULE = 'get_reschedule_appointment?doctorId=' + doctorID
        dispatch(fetchDoctorFilterData());
        dispatch(fetchDoctorScheduleData(URL_SCHEDULE));
    }, [doctorID]);

    const handleSelectedDr = (DRid: any, index: any) => {
        setSelectedDr(index);
        setDoctorID(DRid)
    }

    useEffect(() => {
        let URL = 'get_dr_filter_custom_list?page=' + page
        if (filteredData.city) {
            URL = URL + '&zenCityId=' + filteredData.city
        }
        if (filteredData.speciality) {
            URL = URL + '&zenOncologistTypeId=' + filteredData.speciality
        }
        if (filteredData.cancer_type) {
            URL = URL + '&cancerCategoryId=' + filteredData.cancer_type
        }
        if (categoryID.categoryId) {
            URL = URL + '&apptCategoryId=' + categoryID.categoryId
        }
        if (search.length > 0) {
            URL = URL + '&search_text=' + search
        }
        dispatch(fetchDoctorListData(URL))
    }, [page, filteredData, categoryID, search]);

    useEffect(() => {
        if (paymentData?.razorpay_config?.length > 0) {
            let filterData = paymentData.razorpay_config.filter(
                item => item.description === 'Razorpay key',
            );
            if (filterData?.length > 0) {
                // setRazorPayKey(filterData[0]?.value);
                onPaymentClick()
                setTimeout(() => {
                    // onPaymentClick()
                    onBook(paymentData)
                }, 600);
            }
        }
    }, [paymentData])

    const handlePaymentSuccess = (reponse: any) => {
        console.log('Payment successful!', reponse);
        setSucessModal(true);
    }

    const onPaymentClick = () => {
        const options = {
            key: paymentData.razorpay_config[1]?.value,
            amount: paymentData?.data?.amount?.toString(),
            currency: "INR",
            name: "ZenOnco.io",
            description: "",
            image: 'https://zenonco-web-image.s3.ap-south-1.amazonaws.com/assets/images/Logo.svg',
            order_id: paymentData?.data?.id,
            prefill: {
                name: cookies.get('name'),
                email: cookies.get('Email'),
                contact: cookies.get('number'),
            },
            handler: (response: any) => {
                handlePaymentSuccess(response);
            },
            notes: {
                address: "",
            },
            theme: { color: '#104462' }
        };
        const rzp1 = new Razorpay(options);
        rzp1.open();
        // navigate("/thankyou", { replace: true })
        // navigate(0);
    }

    const PageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    const initiatePayment = (time: string, date: any, drInfo: any) => {
        if (cookies.get('token') !== undefined) {
            setSelectedTime(time)
            setDate(date)
            let data = {
                userId: cookies.get('userId'),
                subscriptionPlanId: true,
                amount: drInfo.fee,
                currency: 'INR',
            }
            dispatch(initiatePay(data, cookies.get('token')));
        }
    }
    const onBook = (data: any) => {
        const userId = cookies.get('userId')
        const obj = {
            doctorId: doctorID,
            userId: userId,
            apptDate: date?.date,
            apptTime: selectedTime,
            paymentId: data?.data?.id,
            paymentStatus: "1",
            completed: "1",
            // apptBookingPatientsId: userId
        }
        dispatch(createAppointment(obj, cookies.get('token')))
    }
    useEffect(() => {
        if (availableDates?.length) {
            availableDates?.map((items: any, index) => {
                let filterData: any[] = [];
                if (doctorScheduleData?.app_booking?.length > 0) {
                    filterData = doctorScheduleData?.app_booking?.filter((item: any) => item?.apptDate === items?.date)
                    setTime(getTimeSlot(date ? date : availableDates[0], filterData))
                }
            });
            setTime(slots)
        }
    }, [availableDates, doctorScheduleData]);
    useEffect(() => {
        if (doctorScheduleData !== undefined && doctorScheduleData?.data?.length > 0) {
            handleSlots(doctorScheduleData, setSlots, setAvailableDates)
        }
    }, [doctorScheduleData]);

    //NOTE: UI Implementation 
    useEffect(() => {
        if (doctorListData?.data?.length === 0) {
            setTimeout(() => {
                setNoDoctorData(true)
            }, 1000)
        } else {
            setNoDoctorData(false)
        }
    }, [doctorListData]);
    const hadleOpenModal = () => {
        setModal(true);
    }
    const handleOpenFooter = () => { // if (cookies.get('token') == undefined) {// } else {// setOpen(true);// setModal(true);// }
    }
    return (
        <Box className="Doctors">
            <Box className="Header-Top-Box">
                <Box className="DR-list-Head">
                    <Header apointment={true} alert={alert} darkTheme={true} translation={translation} />
                </Box>
                <MeetDoctors translation={translation} />
            </Box>
            <Suspense>
                <ListOfDoctors translation={translation} handleAlert={() => handleAlert(setAlert)} setSelectedDr={setSelectedDr} setTime={setTime} time={time} availabelDates={availableDates} selectedDr={selectedDr} handleDoctor={handleSelectedDr}
                    doctorInfo={doctorData?.doctor_detail} doctorID={doctorID} setDoctorID={setDoctorID}
                    cityFilter={doctorFilterData.cities}
                    doctorScheduleData={doctorScheduleData}
                    cancertypeFilter={doctorFilterData.cancer_type}
                    specialityFilter={doctorFilterData.specialities}
                    DataList={doctorListData.data} pageChange={PageChange} pages={page}
                    totalDoctors={doctorListData.total_record}
                    appCateogires={doctorFilterData.app_cateogires}
                    filteredData={filteredData}
                    noDoctorData={noDoctorData}
                    categoryID={categoryID}
                    setCategoryID={setCategoryID}
                    setSucessModal={setSucessModal}
                    sucessModal={sucessModal}
                    setFilteredData={setFilteredData}
                    setPage={setPage}
                    search={search}
                    doctorListData={doctorListData}
                    initiatePayment={initiatePayment}
                    setSearch={setSearch}
                    setSelectedChip={setSelectedChip}
                    selectedChip={selectedChip} />
            </Suspense>
            <BookConsultation handleFooterModel={() => handleOpenFooter()} translation={translation} />
            <Box className="Footer">
                <Footer translation={translation} />
                <MostSearched translation={translation} />
                <br />
            </Box>
        </Box>
    );
}