import React, { Fragment } from 'react'
import { Box, Typography, Button, Skeleton } from '@mui/material';
import './consultexpert.css'
import Exportcounsult from '../../Common-Component/CousultExpDoctor/exportconsult'
import useMediaQuery from '@mui/material/useMediaQuery';
import { doctorListPage } from '../../../values/paths';


function CounsultExpert(Props: { doctorsList?: any, doctor?: any, translation: any }) {
  const expertcard = useMediaQuery('(min-width:601px)');
  return (
    <Fragment>
      {expertcard ?
        <Box className='Main-CounsultExpert_box'>
          <Box className="CounsultExpert-box">
            <Typography className="CounsultExpert-Medical-text">
              {Props?.translation?.intergrative}
            </Typography>
            <Typography className="CounsultExpert-Points">
              {Props?.translation?.IndiaonlyIntegrative} |  {Props?.translation?.Ayurvedatoreducesideeffects}
            </Typography>
          </Box>
          <Box className="CounsultExpert-Cards" >
            <Exportcounsult translation={Props?.translation} doctorsList={Props.doctorsList} doctordata={Props.doctor} />
          </Box>
          <Button disableRipple href={doctorListPage} className="CounsultExpert-button">
            <Typography className="CounsultExpert-btntext"> {Props?.translation?.Consultwithexpert}</Typography>
          </Button>
        </Box> :
        <Box className='Main-CounsultExpert_box'>
          <Box className="CounsultExpert-box">
            <Typography className="CounsultExpert-Medical-text">
              {Props?.translation?.intergrative}
            </Typography></Box>
          <Box className="CounsultExpert-Cards">
            {Props.doctorsList?.length ? <Exportcounsult translation={Props?.translation} doctorsList={Props.doctorsList} doctordata={Props.doctor} /> :
              <Skeleton variant="rounded" animation="wave" className="skeletonProducts" />}
          </Box>
          <Box className="CounsultExpert-button-box">
            <Button disableRipple href={doctorListPage} className="CounsultExpert-button">
              <Typography className="CounsultExpert-btntext">{Props?.translation?.SeeAll}</Typography>
            </Button>
          </Box></Box>
      }</Fragment >
  );
}
export default CounsultExpert;
