import homeSlice from './home-slice'
import feedbackSlice from './feedback-slice'
import customerLeadSlice from './customerLead-slice'
import chatbotSlice from './chatbot-slice'
import mostSearchSlice from './mostSearched-slice'
import savezioparSlice from './saveziopar-slice'
import getzioparSlice from './getziopar-slice'
import storiesHopeSlice from './storiesHope-slice'
import globalSearchSlice from './globalSearch-slice'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { ChatBotModel, FeedbackModel,CustomerLeadModel, GetZioparModel, GlobalSearchModel, HomeModel, MostSearchModel, StoriesHopeModel } from "../../models/home-models";
import HomeService from "../../service/homeService";

export const homeActions = homeSlice.actions
export const feedbackActions = feedbackSlice.actions
export const leadCustomerActions = customerLeadSlice.actions
export const chatBotActions = chatbotSlice.actions
export const mostSearchedActions = mostSearchSlice.actions
export const saveZioParActions = savezioparSlice.actions
export const getZioParActions = getzioparSlice.actions
export const storiesHopeActions = storiesHopeSlice.actions
export const globalSearchActions = globalSearchSlice.actions

export const fetchHomeData = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: HomeModel = await HomeService.getAllHome();
        dispatch(homeActions.setHomeData(response))
    }
}

export const fetchFeedback = (page: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: FeedbackModel = await HomeService.getAllFeedback(page);
        dispatch(feedbackActions.setFeedbackData(response))
    }
}

export const addCustLead = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: CustomerLeadModel = await HomeService.customLead(data);
        dispatch(leadCustomerActions.setCustomerLeadData(response))
    }
}

export const getChatBotAnswer = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: ChatBotModel = await HomeService.chatBotAnswer(data);
        dispatch(chatBotActions.setChatBotData(response))
    }
}

export const fetchGlobalSearch = (searchText: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: GlobalSearchModel = await HomeService.getGlobalSearch(searchText);
        dispatch(globalSearchActions.setGlobalSearchData(response))
    }
}

export const fetchMostSearched = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: MostSearchModel = await HomeService.getMostSearched();
        dispatch(mostSearchedActions.setMostSearchData(response))
    }
}

export const saveZioPar = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: MostSearchModel = await HomeService.saveziopar(data);
        dispatch(saveZioParActions.setSaveZioParData(response))
    }
}

export const setZioPar = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: GetZioparModel = await HomeService.setziopar(data);
        dispatch(getZioParActions.setGETZioParData(response))
    }
}

export const getStoriesHopeData = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: StoriesHopeModel = await HomeService.getStoriesHope();
        dispatch(storiesHopeActions.setStoryHopeData(response))
    }
}