
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductListModel } from "../../models/product-models";

const initialStoryState: ProductListModel = {
    status: 0,
    message: '',
    data: [],
    metadatas: [],
}

const productSlice = createSlice({
    name: 'product',
    initialState: initialStoryState,
    reducers: {
        setProductData(state, action: PayloadAction<ProductListModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
            state.metadatas = action.payload?.metadatas;
        }
    }
})
export default productSlice;