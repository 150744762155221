import React, { useState, useEffect } from "react";
import "./eventsPage.css";
import { Box } from "@mui/material";
import Header from "../../components/Common-Component/Header/header";
import UpcomingEvents from "../../components/EventsPage-Component/UpcomingEvents/upcomingEvents";
import PastEvents from "../../components/EventsPage-Component/PastEvents/pastEvents";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { fetchFutureEventData, fetchPastEventData } from '../../store/event/event-actions';
import { EventDetailDataModel } from "../../models/event-models";
import Seo from "../../components/Common-Component/SEO/seo";
interface GlobalTranslat {
    translation: any
}
export default function EventsPage({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const futureEvents = useAppSelector(state => state?.futureEvent);
    const pastEvents = useAppSelector(state => state?.pastEvent);
    const [page, setPage] = useState(1);
    const [pastEventsData, setPastEventData] = useState<Array<EventDetailDataModel>>([]);

    //NOTE: Life cycle Methods
    useEffect(() => {
        dispatch(fetchFutureEventData());
    }, []);

    useEffect(() => {
        dispatch(fetchPastEventData(page))
    }, [page, dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (pastEvents?.status === 1) {
            if (pastEvents?.event_past_data?.length > 0) {
                let extra = pastEventsData.concat(pastEvents?.event_past_data)
                let filtered = extra.filter((value, i, arr) => { return (arr.findIndex((v) => v.id === value.id) === i); });
                setPastEventData(filtered)
            }
        }
    }, [pastEvents]);

    const handleLoadMore = () => {
        setPage(page + 1)
    }

    //NOTE: UI Implementation 
    return (
        <React.Fragment>
            <Seo translation={translation}
                metaData={futureEvents.metadatas} />
            <Box className="mainBoxEventsList">
                <Box className="HeadingComponents">
                    <Box className="event-Header">
                        <Header darkTheme={true} translation={translation}/>
                    </Box>
                </Box>
                <UpcomingEvents translation={translation} futureEventData={futureEvents.event_future_data} />
                <PastEvents translation={translation} pastEventData={pastEventsData} handleLoadMore={handleLoadMore} />
                <BookConsultation translation={translation}/>
                <Box className="Footer">
                    <Footer translation={translation}/>
                    <MostSearched translation={translation}/>
                    <br />
                </Box>
            </Box>
        </React.Fragment>
    );
}