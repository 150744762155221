import aboutSlice from './about-slice'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import {RootState} from '../index'
import {AboutModel} from "../../models/about-models";
import AboutService from '../../service/aboutService';

export const aboutActions=aboutSlice.actions

export const fetchAboutData=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{  
                const response:AboutModel=await AboutService.getAllAboutInfo();
                dispatch(aboutActions.setAboutData(response))
    }

}