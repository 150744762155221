import React from "react";
import "./thankYouMsg.css";
import { Box, Typography } from "@mui/material";
import AppImages from "../../../images/AppImages";


interface Translate {
    translation: any
}
export default function ThankyouMsg({ translation }: Translate) {
    return (
        <Box className="MsgMain-box">
            <Box>
                <Typography className="thankYou-txt">{translation?.believingInUs}</Typography>
                <Typography className="ourAim-txt">{translation?.empowerCancerPatients}</Typography>
                <Box className="Helping-pointsBox">
                    <Box>
                        <Typography className="helpingPoints"><span className="points" />{translation?.MakingRightChoices}</Typography>
                        <Typography className="helpingPoints"><span className="points" />{translation?.ImproveClinicalOutcome}</Typography>
                    </Box>
                    <Box>
                        <Typography className="helpingPoints"><span className="points" />{translation?.PlanningTheirTreatment}</Typography>
                        <Typography className="helpingPoints"><span className="points" />{translation?.EnhanceQualityLife}</Typography>
                    </Box>
                </Box>
                <Typography className="immidiateResponce-txt">{translation?.immidiateAssistance}</Typography>
            </Box>
            <Box className="thanks-imageBox">
                <img src={AppImages.DoctorImage} alt={translation?.Stories} className="thankYou-DRimage" />
            </Box>
        </Box>
    );
}