import React, { useEffect, Fragment, useState, memo } from "react";
import { Box, Button, Typography } from "@mui/material";
import "./mostSearched.css";
import { useAppSelector, useAppDispatch } from "../../../hooks/redux-hooks";
import { fetchMostSearched } from "../../../store/home/home-actions";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { KeyboardArrowUp } from "@mui/icons-material";
import Collapse from '@mui/material/Collapse';

interface Translate {
    translation: any
}
function MostSearched({ translation }: Translate) {
    const mostSearchData = useAppSelector(state => state?.mostSearched);
    const [load, setLoad] = useState(false)
    
    setTimeout(() => {
        setLoad(true)
    }, 4000);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchMostSearched());
    }, []);
    const [searchList, setSearchList] = useState(false);

    const handleSearchList = () => {
        setSearchList(!searchList);
    }
    return (
        <Box className="MostSearched-MainBox">
            <Button disableRipple className="MostSearchedtxt-btn-Box" onClick={handleSearchList}>
                <Typography className="Most-Searched-Txt" >
                    {translation?.Mostsearched}
                </Typography>
                {!searchList ? <KeyboardArrowDownIcon className="dropdown_searchList" /> :
                    <KeyboardArrowUp className="dropdown_searchList" />}
            </Button>
            {load && <Collapse in={searchList} > <Box className="Result-Box1">
                {mostSearchData.data.map((items, index) => (
                    <Fragment key={items?.id}>
                        <Box className="Searched-Txt-result-box">
                            <a className="mostSearchedLink" href={items.url} target="_blank" rel="noreferrer">
                                <Typography key={index} className="Searched-Txt-result">{items.term}</Typography>
                            </a>
                        </Box></Fragment>))}
            </Box>
            </Collapse>}
        </Box>
    );
}
export default memo(MostSearched);
