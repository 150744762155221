import React, { useState } from "react";
import "./storiesHead.css";
import { Box, Button, Typography, FormControl, InputLabel, Select, MenuItem, Autocomplete, TextField, useMediaQuery } from "@mui/material";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import EventsBlog from "../../Common-Component/EventsBlog/eventsBlog";
import { cancerCategoryModel } from "../../../models/story-models";
import AppImages from "../../../images/AppImages";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


interface DoctorTalk {
    cancerCategory: Array<cancerCategoryModel>
    topicList: Array<string>
    storiesData: any
    loadMorePages: Function
    translation: any
    filteredOption: any;
    setFilteredOption: any
}

export default function StoriesHead({ filteredOption, setFilteredOption, translation, cancerCategory, topicList, storiesData, loadMorePages }: DoctorTalk) {
    const [selected, setSelected] = useState(true);
    const handleAll = () => {
        setSelected(!selected);
        setFilteredOption({ cancer_type: "", topic: "" });
    }
    const mobileView = useMediaQuery("(max-width:601px)");
    const handleCloseAll = () => {
        setSelected(false)
    }
    // const handleFilterderOptions = (name: any, name1: any) => (e: any) => {
    //     setFilteredOption({ ...filteredOption, [name]: e.target.value, [name1]: "" });
    // };
    return (
        <React.Fragment>
            <TopHeading>
                <Box className="StoriesHeadBox">
                    <Box>
                        <Typography className="StoriesHead-Text">{translation?.Stories}</Typography>
                        <Typography className="oneplace-Text">{translation?.ConsultationToWellness}</Typography>
                        <Typography className="Storiesdesc-Text">{translation?.Policy_card}</Typography>
                    </Box>
                    <Box className="StoriesAvatar-Box">
                        <img src={AppImages.StorieHead} alt="" className="Stories-headImg" />
                    </Box>
                </Box>
                <Box className="StoriesFilterBox">
                    <Button disableRipple className={selected ? "selected-BlogBtn" : "BlogAll-btn"} onClick={handleAll}>
                        <Typography className={selected ? "selected-Blogtxt" : "BlogAll-txt"}>{translation?.All}</Typography>
                    </Button>
                    <Box className="stories-chipsBox">
                        {topicList && <Box className="Blog-ChipsBox">
                            <Box className="topic-filterBox">
                                <Autocomplete
                                    popupIcon={<KeyboardArrowDownIcon className="auto-keyboardIcon" />}
                                    componentsProps={{ popper: { style: { width: 'max-content', zIndex: 1000 } } }}
                                    options={topicList}
                                    getOptionLabel={(option) => filteredOption?.topic ? option : ""}
                                    noOptionsText={translation?.NodataFound}
                                    renderOption={(props, option) => (
                                        <Box key={option}>
                                            {option?.length > 0 && <li  {...props} key={option}>
                                                {option?.length > 0 && option}
                                            </li>}
                                        </Box>)}
                                    className="Topic-chip"
                                    onChange={(event: any, newValue: any) => {
                                        setFilteredOption({ topic: newValue, cancer_type: "" });
                                        handleCloseAll() 
                                    }}
                                    renderInput={(params) => <TextField className="autotxtField-inputBox" variant="standard" placeholder={translation?.Topic} {...params}
                                        InputProps={{ ...params.InputProps, disableUnderline: true, style: { fontSize: mobileView ? "13px" : "14px", fontFamily: mobileView ? "FontLight" : "FontSemiBold", fontWeight: mobileView ? "600" : "normal" } }}
                                        sx={{
                                            input: {
                                                color: 'black',
                                                "&::placeholder": { opacity: 1, }
                                            },
                                        }} />} />
                            </Box>
                        </Box>}
                    </Box>
                </Box>
            </TopHeading>
            <Box className="StoriesCardsBox">
                <EventsBlog blogs={true} translation={translation} dark={true} title={true} eventBlog={false} storyData={storiesData} />
                {storiesData?.length > 1 && <Button disableRipple className="StoriesBtn" onClick={() => loadMorePages()}>
                    <Typography className="Stories-btnTxt">{translation?.Loadmore}</Typography></Button>}
            </Box>
        </React.Fragment>
    );
}