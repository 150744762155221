import {DcotorFilterModel, DcotorScheduleModel} from "../../models/doctor-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialDoctorFilterState:DcotorFilterModel={
    status: 0,
    message: '',
    cities: [],
    specialities: [],
    app_cateogires: [],
    cancer_type: [],
}

const doctorFilterSlice=createSlice({
    name:'doctorFilter',
    initialState:initialDoctorFilterState,
    reducers:{
        setDoctorFilterData(state,action:PayloadAction<DcotorFilterModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.cities = action.payload?.cities;
            state.specialities = action.payload?.specialities;
            state.cancer_type = action.payload?.cancer_type;
            state.app_cateogires = action.payload?.app_cateogires;
        }
    }
})
export default doctorFilterSlice;