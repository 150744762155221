import React, { Fragment, useState } from 'react';
import './treatmentindia.css';
import { Box, Typography, useMediaQuery } from '@mui/material';

interface Translate {
    translation: any
}
export default function TreatmentIndia({ translation }: Translate) {
    const [showMore, setShowMore] = useState(false)
    function handleMoreLess() {
        setShowMore(!showMore)
    }
    const mobileview = useMediaQuery("(max-width:600px)");
    return (
        <Fragment>
            <Box className='TreatmentIndia-mainbox'>
                <Typography className='TreatmentIndia-headingtext'>{translation?.Besttreatmentindia}</Typography>
                <Box className='TreatmentIndia-subbox'>
                    <Typography className={showMore ? 'TreatmentIndia-subtext2' : 'TreatmentIndia-subtext'}>
                        {translation?.committedcaregiversDimpleParmar}
                        {!mobileview && <><br /><br /></>}
                        {translation?.Indiaurgerychemotherapyozone}
                        {!mobileview && <><br /><br /></>}
                        {translation?.CancertreatmentcostIndiasevere}
                        <span onClick={handleMoreLess} className='TreatmentIndia-readmorebtn'>{showMore ? "" : translation?.readMore}</span>
                        <br /><br />
                        {translation?.ZenOncopatienjourneys}
                        <br /> <br />
                        {translation?.Zenfitnessemotionalwellness}
                        <br /><br />
                        {translation?.ZenOncocancerBangaloretoo} <span onClick={handleMoreLess} className='TreatmentIndia-readmorebtn'>{showMore ? translation?.readLess : " "}</span>
                    </Typography>
                </Box></Box></Fragment>
    );
}

