import {CustomerLeadModel, } from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialFeedbackState:CustomerLeadModel={
    status: 0,
    lead_id: '',
}

const customerLeadSlice=createSlice({
    name:'customerLead',
    initialState:initialFeedbackState,
    reducers:{
        setCustomerLeadData(state,action:PayloadAction<CustomerLeadModel>){
            state.status = action.payload?.status;
            state.lead_id = action.payload?.lead_id;
        }
    }
})
export default customerLeadSlice;