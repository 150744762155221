import React from "react";
import { Box } from "@mui/material";
import Header from "../../components/Common-Component/Header/header";
import Footer from '../../components/Common-Component/Footer/footer'
import BookConsultation from '../../components/Common-Component/BookConsultation/bookConsultation'
import MostSearched from '../../components/Common-Component/MostSearched/mostSearched'
import ZioperInnerHead from "../../components/ZioperInnerpage-Component/ZioperInnerHead/zioperInnerHead";
import "./zioperInnerPage.css";
import ZioperInnerDetails from "../../components/ZioperInnerpage-Component/ZioperInnerDetails/zioperInnerDetails";
import { useAppSelector } from "../../hooks/redux-hooks";
import { useLocation } from "react-router";

interface GlobalTranslat {
    translation: any
}

export default function ZioperInnerPage({ translation }: GlobalTranslat) {

    const GetZioParData = useAppSelector(state => state?.getZioPar);
    const location = useLocation();
    const allData = location?.state?.GetZioParData
    const url = location?.state?.url;
    return (
        <Box>
            <Box className="Header">
                <Header darkTheme={true} translation={translation}/>
            </Box>
            <ZioperInnerHead url={url} translation={translation} />
            <ZioperInnerDetails ZioperDetails={allData} />
            <BookConsultation translation={translation}/>
            <Box className="Footer">
                <Footer translation={translation}/>
                <MostSearched translation={translation}/>
                <br />
            </Box>
        </Box>
    )
}