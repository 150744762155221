import React, { useEffect, useState } from 'react';
import './Messages.css';

function Messages({ socket }) {
  const [messages, setMessages] = useState();

  useEffect(() => {
    const messageListener = (message) => {
        console.log('get_response',message);
        setMessages(message)
      //setMessages((prevMessages) => {
        //const newMessages = {...prevMessages};
       // newMessages[message.id] = message;
       // return newMessages;
      //});
    };
  
    // const deleteMessageListener = (messageID) => {
    //   setMessages((prevMessages) => {
    //     const newMessages = {...prevMessages};
    //     delete newMessages[messageID];
    //     return newMessages;
    //   });
    // };
  
    socket.on('getChatbotmessage', messageListener);
    //socket.on('deleteMessage', deleteMessageListener);
    socket.emit('getChatbotmessage');

    return () => {
      socket.off('message', messageListener);
      //socket.off('deleteMessage', deleteMessageListener);
    };
  }, [socket]);

  return (
    <div className="message-list">
        <div>
        {
            messages
        }
        </div>
      {/* {[...Object.values(messages)]
        .sort((a, b) => a.time - b.time)
        .map((message) => (
          <div
            key={message.id}
            className="message-container"
            title={`Sent at ${new Date(message.time).toLocaleTimeString()}`}
          >
            <span className="user">{message.user.name}:</span>
            <span className="message">{message.value}</span>
            <span className="date">{new Date(message.time).toLocaleTimeString()}</span>
          </div>
        ))
      } */}
      
    </div>
  );
}

export default Messages;