import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  StoryDetailModel } from "../../models/story-models";

const initialStoryDetailState: StoryDetailModel = {
    status: 0,
    data: {
        id: '',
        author: '',
        postCategoryId: '',
        postSubcategoryId: '',
        title: '',
        content: '',
        image: '',
        author_detail: {
            id: 0,
            user_details: []
        },
        cancer_healing_stories_metadatas:[],
        updatedAt: '',
    },
    message: '',
    metadatas:[]
}

const storyDetailSlice = createSlice({
    name: 'storyDetail',
    initialState: initialStoryDetailState,
    reducers: {
        setStoryDetailData(state, action: PayloadAction<StoryDetailModel>) {
            state.status = action.payload?.status;
            state.data = action.payload?.data;
            state.message = action.payload?.message;
            state.metadatas = action.payload?.metadatas;
        }
    }
})
export default storyDetailSlice;