import React from "react";
import "./eventBolgBox.css";
import { Avatar, Box, Button, Chip, Link, Typography, } from "@mui/material";
import AppImages from "../../../images/AppImages";
import { blogDetailsPageSlug, eventsDetailsPageSlug } from "../../../values/paths";


export default function EventBlogBox(Props: {
    eventBlog?: Boolean, items?: any, user?: any, index: number,
    thanksBox?: Boolean, survivorsPage?: Boolean, pastEvents?: Boolean,
    dark?: Boolean, title?: Boolean, searchPage?: Boolean, translation: any
}) {
    return (
        <Link href={Props.pastEvents ? eventsDetailsPageSlug(Props.items?.slug) : blogDetailsPageSlug(Props.items?.slug)} className={Props.thanksBox || Props.survivorsPage ? "thanks-joinCards" : Props?.searchPage ? "BlogEventSearch-Card" : Props.pastEvents ? "BlogEvent-Card1" : "BlogEvent-Card"}>
            <Box>
                <Box key={Props.items?.id} className={Props?.searchPage ? "searchEvent-mainbox" : ''}>
                    <img src={Props.items?.image?.length ? Props.items.image : AppImages.DefaultBlog} alt={Props?.translation?.Blogs} className={Props?.searchPage ? "EventsSearch-Videos" : "Events-Videos"} />
                </Box>
                {Props.survivorsPage ? null :
                    <>
                        {Props.eventBlog || Props.title ?
                            Props?.items?.title !== "" && <Typography className="OverComing-Cancer">{Props.items?.title || Props.items?.name}</Typography>
                            : Props.items?.content?.length &&
                            <div className={Props.index <= 4 ? "OverComing-Cancer" : "Blogs-desc-txt"} dangerouslySetInnerHTML={{ __html: Props.items?.content }} />}
                    </>}
                {/* Blogs&&Events-Avatar-name Box */}
                {Props.thanksBox || Props.survivorsPage || Props.pastEvents ? null : <Box className="Avatar-Name-Box">
                    <Avatar src={Props.user?.user_details[0]?.image} alt="name" className="Events-Avatar" />
                    <Typography className="Oncologist-Name-Text">{Props.user?.user_details[0]?.name.length ? Props.user?.user_details[0]?.name : Props?.translation?.Anonymous}</Typography>
                </Box>}
                {/* Blogs-category-Chip */}
                {Props.eventBlog || Props.thanksBox || Props.survivorsPage ? null :
                    <Box className="Blog-ChipsBox">
                        <Chip label={Props.items?.post_category?.name} className="Blog-Chip-btn" />
                    </Box>}
                {/* thankyou-Component-joinBtn */}
                {Props.thanksBox &&
                    <Box className="thankYou-BtnBox">
                        <Button disableRipple className="thankYou-joinBtn">
                            <Typography className="thankYou-jointext">{Props?.translation?.Join}</Typography>
                        </Button>
                    </Box>}
                {/* thankyou-Component-joinBtn */}
                {Props.survivorsPage &&
                    <>
                        {Props.items?.author_detail?.user_details?.map((item: any) => (
                            <Box key={Props.items?.id}>
                                <Typography className="survivarText-title">{Props.items?.title}</Typography>
                                <Typography className="survivarText">{item?.name}</Typography>
                            </Box>
                        ))}
                    </>}
            </Box>
        </Link>
    );
}
