import React, { Fragment, useEffect, useState } from 'react'
import './zioparpage.css'
import { Box } from '@mui/material'
import Header from '../../components/Common-Component/Header/header'
import Footer from '../../components/Common-Component/Footer/footer'
import BookConsultation from '../../components/Common-Component/BookConsultation/bookConsultation'
import MostSearched from '../../components/Common-Component/MostSearched/mostSearched'
import ZioparHead from '../../components/Ziopar-Components/zioparHead'
interface GlobalTranslat {
  translation: any
}
export default function ZioparPage({ translation }: GlobalTranslat) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <Fragment>
      <Box className="zioperPgae-head">
        <Header darkTheme={true} translation={translation} />
      </Box>
      <Box>
        <ZioparHead loading={loading} translation={translation} />
      </Box>
      <BookConsultation translation={translation} />
      <Box className="Footer">
        <Footer translation={translation} />
        <MostSearched translation={translation} />
      </Box>
    </Fragment>
  )
}
