import React, { Fragment } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import "./productCartHead.css";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";


interface Translate {
  translation: any
}

export default function ProductCartHead({ translation }: Translate) {
  const maxWidth500 = useMediaQuery("(max-width:500px)");
  return (
    <Fragment>
      <TopHeading>
        <Box className="Cart-Main-Box">
          <Typography className="CartHeadS-heading-txt">{translation?.cart} </Typography>
          {maxWidth500 ?
            <>
              <Typography className="heading-txt"> {translation?.ADDRESS} </Typography>
              <Typography className="heading-txt"> {translation?.PAYMENT}</Typography>
            </> :
            <>
              <Typography className="heading-txt">· · · · · · · · · · · · · · ·   {translation?.ADDRESS} </Typography>
              <Typography className="heading-txt"> · · · · · · · · · · · · · · ·  {translation?.PAYMENT}</Typography>
            </>
          }
        </Box>
      </TopHeading>
    </Fragment>
  );
}