import {GetZioparModel} from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialHomeState:GetZioparModel={
    status: 0,
    medical_treatment: [], 
    nourish_reports: [], 
    stay_fit_reports: [], 
    zen_cancer_proof: [], 
    diagnostic_test: [], 
    symptoms: [], 
}

const getZioparSlice=createSlice({
    name:'saveZioparSlice',
    initialState:initialHomeState,
    reducers:{
        setGETZioParData(state,action:PayloadAction<GetZioparModel>){
            state.status = action.payload?.status;
            state.medical_treatment = action.payload?.medical_treatment;
            state.nourish_reports = action.payload?.nourish_reports;
            state.stay_fit_reports = action.payload?.stay_fit_reports;
            state.zen_cancer_proof = action.payload?.zen_cancer_proof;
            state.diagnostic_test = action.payload?.diagnostic_test;
            state.symptoms = action.payload?.symptoms;
        }
    }
})
export default getZioparSlice;