import React, { Fragment, useState } from "react";
import { Box, Tab, Typography, useMediaQuery } from "@mui/material";
import "./zioperInnerDetails.css";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AppImages from "../../../images/AppImages";
import tabsLabel from "../zioperTabs.json";

interface ZioperData {
    ZioperDetails: any
}
export default function ZioperInnerDetails({ ZioperDetails }: ZioperData) {
    const [value, setValue] = useState("0");
    const LessThen700 = useMediaQuery("(max-width:700px)")
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }
    const renderItem = (item: any, cancerProof?: any) => {
        return (
            <>
                {item?.bullet.toString() !== "0" && <Box className="txtwith_logo-Box">
                    {item?.bullet.toString() === '2' && <img src={AppImages?.circleLogo} className="ZioperDetails_pointImg" alt="->" />}
                    {item?.bullet.toString() !== "1" && item?.bullet.toString() !== "2" && item?.bullet.toString() !== "0" && <span className="txtDot_box">•</span>}
                    <div dangerouslySetInnerHTML={{ __html: cancerProof ? item?.prior_treatment : item?.prior_statement }} className={item?.bullet.toString() === "1" ? "ZioperDetails_HeadTxt" : "ZioperDetails_pointTxt"} />
                </Box>}
            </>
        );
    }
    return (
        <Box className="zioperInnerDetails_main-Box">
            <Box className="zioperTab_panels-Box">
                <TabContext value={value}>
                    <TabList allowScrollButtonsMobile TabIndicatorProps={{ className: "ZeoperDetails-Indicator" }} orientation={LessThen700 ? "horizontal" : "vertical"} scrollButtons="auto" variant="scrollable" onChange={handleChange}>
                        {tabsLabel?.ZioperTabs.map((items, index) => (
                            <Tab key={items?.id} label={
                                <Typography className={value === items?.id ? "zioperTab-label1" : "zioperTab-label"}>
                                    {items?.Label}
                                </Typography>} className={value === items?.id ? "tabs-details-Box1" : "tabs-details-Box"} value={items?.id} />))}
                    </TabList>
                    <Box className="zioperTabpanel-Box">
                        {value === "0" &&
                            <TabPanel value={value} tabIndex={0} className="Panel-details-Box">
                                {ZioperDetails?.medical_treatment.map((items: any, ind: any) => (
                                    <Fragment key={items?.id}>
                                        {renderItem(items)}
                                    </Fragment>
                                ))}
                            </TabPanel>
                        }
                        {value === "1" &&
                            <TabPanel value={value} tabIndex={0} className="Panel-details-Box">
                                {ZioperDetails?.diagnostic_test.map((items: any, ind: any) => (
                                    <>
                                        {renderItem(items)}
                                    </>
                                ))}
                            </TabPanel>
                        }
                        {value === "2" &&
                            <TabPanel value={value} tabIndex={0} className="Panel-details-Box">
                                {ZioperDetails?.nourish_reports.map((items: any, ind: any) => (
                                    <>
                                        {renderItem(items)}
                                    </>
                                ))}
                            </TabPanel>
                        }
                        {value === "3" &&
                            <TabPanel value={value} tabIndex={0} className="Panel-details-Box">
                                {ZioperDetails?.stay_fit_reports.map((items: any, ind: any) => (
                                    <>
                                        {renderItem(items)}
                                    </>
                                ))}
                            </TabPanel>
                        }
                        {value === "4" &&
                            <TabPanel value={value} tabIndex={0} className="Panel-details-Box">
                                {ZioperDetails?.zen_cancer_proof.map((items: any, ind: any) => (
                                    <>
                                        {renderItem(items, true)}
                                    </>
                                ))}
                            </TabPanel>
                        }
                        {value === "5" &&
                            <TabPanel value={value} tabIndex={0} className="Panel-details-Box">
                                {ZioperDetails?.symptoms.map((items: any, ind: any) => (
                                    <>
                                        {renderItem(items)}
                                    </>
                                ))}
                            </TabPanel>
                        }
                    </Box>
                </TabContext>
            </Box>
        </Box>
    );
}