import React from "react";
import { Box, Typography } from "@mui/material";
import "./pastEvents.css";
import { EventDetailDataModel } from "../../../models/event-models";
import EventsBlog from "../../Common-Component/EventsBlog/eventsBlog";


interface PastEventDetails {
    pastEventData: Array<EventDetailDataModel>
    handleLoadMore: Function
    translation: any
}

export default function PastEvents({ translation, pastEventData, handleLoadMore }: PastEventDetails) {
    return (
        <Box className="Main-Pastevent-Box">
            <Box className="pastevents-Box">
                <Box className="EventTextBox">
                    <Typography className="pastEvents-txt">
                        {translation?.Pastevents}</Typography>
                </Box>
                <Box>
                    <EventsBlog translation={translation} pastEvents={true} pastEventData={pastEventData} eventBlog={true} />
                </Box>
            </Box>
        </Box>
    );
}