import React, { useState } from 'react'
import { Autocomplete, Box, Button, Typography, TextField, Checkbox, Alert, InputLabel, Select, MenuItem } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import './contactdetails.css'
import AppImages from '../../../../images/AppImages';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { selectExpand } from '../../ExpandIcon/expandIcon';
const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" />;

export default function Contactdetails(props: { translation: any, handleNext: any, cancerFormData: any, homePage?: any }) {
  const [cancerTreatment, setCancerTreatment] = useState<any>([]);
  const [cancerInfo, setCancerInfo] = useState({ cancerType: "", cancerStage: "", cancerTreatment: "", });
  const [showAlert, setShowAlert] = useState(false)
  const handleChange = (name: any) => (e: any, newValue: any) => {
    setCancerInfo({ ...cancerInfo, [name]: e.target.value })
  }
  function handleError() {
    var cancerTreatIds = new Array();
    if (cancerInfo.cancerStage.length < 1 || cancerTreatment.length < 1 || cancerInfo?.cancerType.length < 1) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
      cancerTreatment.map((item: any) => { cancerTreatIds.push(item.id) })
      props.handleNext(cancerInfo, cancerTreatIds)
    }
  }
  return (
    <Box className="caregiverpatient-Box"  >
      <Typography className='caregiverpatient-title-text'>{!props?.homePage ? props?.translation.EnterDetails : props?.translation?.PleaseEnterdetails}</Typography>
      <Box className="treatmentModal-txtF-Box">
        <FormControl fullWidth variant="outlined">
          <InputLabel shrink={false} className="cancerInfo-label">{cancerInfo.cancerType ? "" : props?.translation?.SelectCancertype}</InputLabel>
          <Select variant="standard" value={cancerInfo.cancerType} onChange={handleChange("cancerType")}
            className="cancerInfo-input" IconComponent={selectExpand} MenuProps={{ style: { maxHeight: "350px", width: "100%" } }}>
            {props.cancerFormData?.cancer_type?.map((items: any, index: any) => (
              <MenuItem className='cancerType-menulists' key={items.id} value={items.id}>
                <Typography>
                  {items.name}
                </Typography>
              </MenuItem>))}
          </Select>
        </FormControl>
        {showAlert && cancerInfo.cancerType.length < 1 &&
          <Alert severity="error" className="modal-error-Box">{props?.translation.SelectCancertype}</Alert>}
        <FormControl fullWidth variant="outlined" className='input-items-box'>
          <InputLabel shrink={false} className="cancerInfo-label">{cancerInfo.cancerStage ? "" : props?.translation?.SelectCancerStage}</InputLabel>
          <Select variant="standard" value={cancerInfo.cancerStage} onChange={handleChange("cancerStage")} className="cancerInfo-input" IconComponent={selectExpand}>
            {props.cancerFormData?.cancer_stage?.map((items: any, index: any) => (
              <MenuItem key={items.id} value={items.id}>
                {items.cancer_stage}
              </MenuItem>))}
          </Select>
        </FormControl>
        {showAlert && cancerInfo.cancerStage?.length < 1 &&
          <Alert severity="error" className="modal-error-Box">{props?.translation.SelectCancerStage}</Alert>}
        <FormControl fullWidth variant="outlined" >
          <Autocomplete popupIcon={<KeyboardArrowDownIcon />} value={cancerTreatment} onChange={(event, newValue) => setCancerTreatment(newValue)}
            multiple
            options={props.cancerFormData?.treatement}
            disableCloseOnSelect
            getOptionLabel={(option) => option.treatment}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox value={option?.id} icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} onChange={handleChange("cancerTreatment")} />
                {option.treatment}
              </li>)}
            renderInput={(params) => (
              <TextField {...params} placeholder={props?.translation?.SelectTreatmentTaken} />)} />
        </FormControl>
        {showAlert && cancerTreatment < 1 &&
          <Alert severity="error" className="modal-error-Box">{props?.translation?.SelectCancerTreatment}</Alert>}
        <Button disableRipple className="contetcdetails-txtF-Btn" onClick={handleError}>
          <Typography className="treatmentModal-Booklabel">{props?.translation?.Next}</Typography>
        </Button>
      </Box>
      <Box className="contetcdetails-imgmainbox1">
        <img className="treatmentModel-img" src={AppImages.LoveHealsCancer} alt='error' />
      </Box>
    </Box>
  );
}

