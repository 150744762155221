
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GETStateModel } from "../../models/product-models";

const initialStoryState: GETStateModel = {
    status: 0,
    message: '',
    data: [],
}

const getAddressSlice = createSlice({
    name: 'getState',
    initialState: initialStoryState,
    reducers: {
        setGetState(state, action: PayloadAction<GETStateModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default getAddressSlice;