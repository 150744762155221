
import Api from './Api';
import {ABOUT_API} from '../constants/ApiName'
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const config = {
    headers:{}
  };
  const tokenConfig = {
    headers: {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
};
export default{
    async getAllAboutInfo(){
        var response= await Api().get(ABOUT_API, config);
        console.log("About Response----", response)
        return response.data;
    },
}