import React, { Suspense, useEffect, useState } from 'react'
import { Box, Skeleton, CircularProgress } from "@mui/material";
import TagManager from 'react-gtm-module';
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { fetchHomeData, fetchFeedback, getStoriesHopeData, addCustLead } from '../../store/home/home-actions';
import "./homePage.css";
import Footer from "../../components/Common-Component/Footer/footer";
import Header from '../../components/Common-Component/Header/header';
import Degree360 from "../../components/Home-Components/360degree/360degree";
import MostSearched from '../../components/Common-Component/MostSearched/mostSearched';
import Seo from '../../components/Common-Component/SEO/seo';
import { handleAlert } from '../../values/helper';
import { getZioparData } from "../../store/faq/faq-actions";
import VisitCenters from '../../components/homePage-Component/visit-Centers/visitCenters';
import MyReport from '../../components/homePage-Component/MyReport/myReport';
import CounsultExpert from '../../components/Home-Components/CousultExprt/counsultExpert';
import CancerFighting from '../../components/Home-Components/Cancer-Fighting/cancerFighting';
import ExpertSession from '../../components/Home-Components/ExpertSession/expertSession';
import StoriesofHope from '../../components/homePage-Component/storiesOfHope/storiesOfHope';
import YourJourneySub from '../../components/homePage-Component/YourJourneySub/yourJourneysub';
import SavingLives from '../../components/homePage-Component/SavingLives/savingLives';
import HealingJounrney from '../../components/homePage-Component/HealingJourney/healingJounrney';
import TreatmentIndia from '../../components/homePage-Component/Treatment-in-India/treatmentIndia';
import WonAwards from '../../components/homePage-Component/Won-Awards/wonAwards';
import FeedbackCards from '../../components/Common-Component/FeedBackCards/feedbackCards';
import BookConsultation from '../../components/Common-Component/BookConsultation/bookConsultation';
import ZenOncoApp from '../../components/homePage-Component/ZenOnco-App/zenOncoApp';
import { useNavigate } from "react-router";

const tagManagerArgs = {
    dataLayer: {
        page: 'home'
    },
    dataLayerName: 'PageDataLayer'
}
interface GlobalTranslat {
    translation: any
}
export default function Dashboard({ translation }: GlobalTranslat) {
    TagManager.dataLayer(tagManagerArgs);
    const dispatch = useAppDispatch();
    const allHomeData = useAppSelector(state => state?.home);
    const feedbackData = useAppSelector(state => state?.feedbackDetail);
    const storyHopeData = useAppSelector(state => state?.storyHope);
    const [feedback, setFeedback] = useState<any>([]);
    const [alert, setAlert] = useState(false);
    const [alerts, setAlerts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [connectDetails, setConnectDetails] = useState({ name: "", countryCode: "", number: "" });
    const [submited, setSubmited] = useState(false);
    const navigate = useNavigate();
    // const customerLeadData = useAppSelector(state => state?.customerLead);
    const zioparData = useAppSelector(state => state?.ziopar);
    //NOTE: Life cycle Methods
    setTimeout(() => {
        setLoading(true);
    }, 1200);
    useEffect(() => {
        // dispatch(fetchSiteMapData());
        dispatch(fetchHomeData());
        dispatch(getStoriesHopeData())
        dispatch(getZioparData())
    }, []);
    useEffect(() => {
        const filteredData = feedbackData?.reviews_data.filter((value, i, arr) => { return (arr.findIndex((v) => v?.id === value?.id) === i) });
        setFeedback(filteredData);
    }, [feedbackData]);
    useEffect(() => {
        dispatch(fetchFeedback(page));
    }, [page]);
    // useEffect(() => {
    //     dispatch(getZioparData())
    // }, []);

    //NOTE: Custom Methods
    function onLoadMore() {
        if (feedbackData?.isLoadMore) {
            setPage(page + 1);
        }
    }
    const handleBack = () => {
        window.addEventListener('popstate', function (event) {
            navigate(0)
        });
    }
    useEffect(() => {
        handleBack()
    }, [window.history.pushState]);

    const onPressSubmit = (data: any) => {
        let param = {
            name: data.name,
            phone: data.countryCode ? data.countryCode + data.number : '+91' + data.number,
        }
        dispatch(addCustLead(param));
    }
    //NOTE: UI Implementation
    return (
        <React.Fragment>
            <Seo translation={translation} metaData={allHomeData?.metadatas}
                title="Cancer Treatment in India | Integrative Oncology - ZenOnco.io" />
            <Box className="Dashboard-MainBox">
                <Box className="Top">
                    <Box className="Header">
                        <Header loading={loading} translation={translation} journey={alerts ? false : true} darkTheme={false} alert={alert ? alert : alerts} />
                    </Box>
                    <Degree360 loading={loading} setConnectDetails={setConnectDetails} connectDetails={connectDetails} setSubmited={setSubmited} submited={submited} translation={translation} onPressSubmit={onPressSubmit} Video={allHomeData?.home_page_video} NewsPartners={allHomeData?.news_partner} />
                </Box>
                {loading && <>
                    <VisitCenters translation={translation} centerList={allHomeData?.visit_center} />
                    <MyReport loading={loading} translation={translation} cancer_stage={zioparData?.cancer_stage}
                        cancer_type={zioparData?.cancer_type}
                        disease={zioparData?.other_disease}
                        performance={zioparData?.performance}
                        treatment={zioparData?.prior_treatment}
                        symptoms={zioparData?.symptoms} />
                    <YourJourneySub translation={translation} />
                    <CounsultExpert translation={translation} doctorsList={allHomeData?.cam_doctor} doctor={allHomeData?.cam_doctor} />
                    <CancerFighting translation={translation} Product={allHomeData?.product_list} productlist={allHomeData?.product_list} />
                    {allHomeData?.event_data?.length > 0 ?
                        <ExpertSession translation={translation} doctorsList={allHomeData?.event_data} eventList={allHomeData?.event_data} />
                        : <Skeleton variant="rounded" animation="wave" className="skeletonProducts" />}
                    <StoriesofHope storyData={storyHopeData?.data} translation={translation} />
                    <SavingLives translation={translation} />
                    <ZenOncoApp translation={translation} />
                    <FeedbackCards translation={translation} Reviews={feedback} onLoadMore={onLoadMore} isLoadMore={feedbackData?.isLoadMore} />
                    <WonAwards translation={translation} awards={allHomeData?.award_data} awardlist={allHomeData?.award_data} />
                    <TreatmentIndia translation={translation} />
                    <HealingJounrney loading={loading} translation={translation} homePage={true} handleAlert={() => handleAlert(setAlert)} />
                    <BookConsultation translation={translation} loading={loading} />
                    <Box className="Footer">
                        <Footer translation={translation} />
                        <MostSearched translation={translation} />
                        <br />
                    </Box>
                </>}
            </Box>
        </React.Fragment>);
}