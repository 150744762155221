import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import FAQService from '../../service/faqService';
import faqSlice from './faq-slice';
import zioparSlice from './ziopar-slice';
import { FAQDataModel, ZioparDataModel } from '../../models/faq-models';

export const faqActions = faqSlice.actions
export const zioparActions = zioparSlice.actions

export const getFAQData = (data:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: FAQDataModel = await FAQService.getFAQData(data);
        dispatch(faqActions.setFAQData(response))
    }
   
}

export const getZioparData = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const response: ZioparDataModel = await FAQService.getZioparData();
        dispatch(zioparActions.setZioparData(response))
    }
}