import React from 'react'
import { Box, Button, Typography } from "@mui/material";
import AppImages from '../../../../images/AppImages';
import "./caregiverPatient.css"


export default function CaregiverOrPatient(Props: { translation: any, handleCaregiverPatient: any }) {
  return (
    <Box className="caregiverpatient-Box" >
      <Typography className='caregiverpatient-title-text'>{Props.translation?.AreCaregiver} <br />{Props.translation?.orPatient}</Typography>
      <Box className='caregiverpatient-imgbox'>
        <Button disableRipple className='cargiverpatient-imgss' onClick={() => Props.handleCaregiverPatient(2)}>
          <Box>
            <img className='caregive-img' src={AppImages.Caregiver} alt="" />
          </Box>
          <Typography className='cargiverpatient-imgss-text1'>{Props.translation?.Caregiver}</Typography>
        </Button>
        <Button disableRipple className='cargiverpatient-imgss' onClick={() => Props.handleCaregiverPatient(1)}>
          <Box>
            <img className='patient-img' src={AppImages.Patient} alt="" />
          </Box>
          <Typography className='cargiverpatient-imgss-text1'>{Props.translation?.Patient}</Typography>
        </Button>
      </Box>
    </Box>
  );
}

