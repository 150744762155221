import React from "react";
import "./treatment.css";
import { Box, CircularProgress, Typography } from "@mui/material";

export default function Treatment(Props: { data: any; translation: any }) {
    return (
        <Box className="Treatment-MainBox1">
            <Box>

            </Box>
            {
                Props?.data?.title.length > 0 || Props?.data?.updatedAt ?
                    <Box>
                        <Typography className="ShimogaCancer-Text">
                            {Props?.data?.title} - {Props?.data?.author_detail?.user_details[0]?.name}
                        </Typography>
                        <Typography className="May27-Text">
                            {new Date(Props?.data?.updatedAt).getDate()} {new Date(Props?.data?.updatedAt).toLocaleString('default', { month: "long", year: "numeric" })}
                        </Typography>
                    </Box> :
                    <Box className="Loading-effect">
                        <CircularProgress color="secondary" />
                    </Box>
            }
        </Box>
    );
}
