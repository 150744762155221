import React, { useState } from "react";
import "./requestAppointment.css";
import { Dialog, Button, Box, Typography, TextField, Autocomplete, Divider, Alert } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CountryCode from "../../../CountryCode.json";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RequestSubmit from "./requestSubmited";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { addCustLead } from "../../../../store/home/home-actions";

interface Translate {
    translation: any;
    openModal: any;
    setOpenModal: any;
}
export default function RequestAppointment({ translation, openModal, setOpenModal }: Translate) {
    const dispatch = useAppDispatch();
    const [submited, setSubmited] = useState(false);
    const [page, setPage] = useState(0);
    const [alert, setAlert] = useState(false);
    const [connectDetails, setConnectDetails] = useState(
        { name: "", countryCode: "", number: "" });
    const ModalClose = () => {
        setOpenModal(false)
    }
    const handleConnectDetails = (props: any) => (e: any, newValue?: any) => {
        if (props === "number") {
            const onlyNums = e.target?.value?.replace(/[^0-9]/, '');
            setConnectDetails({ ...connectDetails, [props]: onlyNums });
        } else if (props === "countryCode") {
            setConnectDetails({ ...connectDetails, [props]: newValue?.phone });
        } else if (props === "name") {
            const onlyAlphabet = e.target.value.replace(/[^a-zA-Z\s]/g, '');
            setConnectDetails({ ...connectDetails, [props]: onlyAlphabet });
        } else {
            setConnectDetails({ ...connectDetails, [props]: e.target.value });
        }
    }
    const onPressSubmit = (data: any) => {
        let param = {
            name: data.name,
            phone: data.countryCode ? data.countryCode + data.number : '+91' + data.number,
        }
        dispatch(addCustLead(param));
    }
    const handleValidation = () => {
        if (connectDetails.name === "" || connectDetails.number === "") {
            setAlert(true);
        } else {
            onPressSubmit(connectDetails);
            setSubmited(true)
            setTimeout(() => { setSubmited(false); }, 6000)
            setTimeout(() => {
                setConnectDetails({ name: "", countryCode: "", number: "" })
            }, 6000)
            setPage(1);
            sessionStorage.setItem("language", "true");
            setAlert(false);
        }
    }
    return (
        <Dialog
            PaperProps={{ className: "RequestModal-dialog" }}
            sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.80)', backdropFilter: "blur(5px)" } }}
            open={openModal}
            onClose={ModalClose}
            fullWidth maxWidth="sm">
            <Button disableRipple className="RequestModal-closeBtn" onClick={ModalClose} aria-label='close'>
                <CloseIcon className="RequestModal-closeIcon" />
            </Button>
            {page === 0 && <Box className="RequestModal-mainBox">
                <Box className="Head_SubBox">
                    <Typography className="RequestAptment-txt">
                        {translation?.RequestAppointment}
                    </Typography>
                    <Typography className="RequestAptment_sub-txt">
                        {translation?.ExpertAyurvedadoctors}
                    </Typography>
                </Box>
                <Box className="name_numberBox">
                    <TextField
                        autoComplete='off'
                        value={connectDetails.name}
                        onChange={handleConnectDetails('name')}
                        className="RequestModal-name-Box"
                        placeholder={translation?.Name}
                        sx={{ "& .MuiOutlinedInput-root": { "& > fieldset": { borderColor: "#C5C5C5 !important" }, } }}
                        InputProps={{ disableUnderline: true, className: "auto-inputBox" }}
                    />
                    {alert && connectDetails.name === "" &&
                        <Alert severity="error" className="connectForm_alert-box">{translation?.PleaseEntername}</Alert>}
                    <Box className="RequestModal-numberBox">
                        <Autocomplete
                            onChange={handleConnectDetails('countryCode')}
                            className="RequestModal-number-Box"
                            id="country-select1"
                            popupIcon={<KeyboardArrowDownIcon className="country-keyboardIcon" />}
                            options={CountryCode.Code}
                            disableClearable
                            autoHighlight
                            defaultValue={{ "code": "IN", "label": "India", "phone": "+91" }}
                            componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                            getOptionLabel={(option) => option.phone}
                            isOptionEqualToValue={(option, selected) => selected.phone === "" || option.phone === selected.phone ? true : false}
                            renderOption={(props, option) => (
                                <Box key={option.label} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt="" />
                                    {option.phone}
                                </Box>)}
                            renderInput={(params) => (
                                <TextField variant="standard"  {...params} sx={{ "& .MuiInputBase-root": { fontSize: "14px", borderRadius: "0px", padding: "0px 0px 0px 0px" } }} />)} />
                        <Divider orientation="vertical" className="RequestModal-numberDivider" />
                        <TextField
                            value={connectDetails.number}
                            onChange={handleConnectDetails('number')}
                            autoComplete='off'
                            className="RequestModal-name-Box"
                            placeholder={translation?.PhoneNumberTxt}
                            sx={{ "& .MuiOutlinedInput-root": { "& > fieldset": { border: "none", borderColor: "#ffff !important" }, } }}
                            InputProps={{ className: "auto-inputBox" }} />
                    </Box>
                    {alert && connectDetails.number === "" &&
                        <Alert severity="error" className="connectForm_alert-box">{translation?.PleaseEnternumber}</Alert>}
                </Box>
                <Button disableRipple className="RequestModal-Btn" onClick={handleValidation}>
                    <Typography className="RequestModal-Btntxt">
                        {translation?.StartyourRecovery}
                    </Typography>
                </Button>
            </Box>}
            {page === 1 && <RequestSubmit translation={translation} />}
        </Dialog>
    );
}