import {StoriesHopeModel} from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialHomeState:StoriesHopeModel={
    data: [],
    status: 0,
    // display_order: 0,
    // title: '',
    // image: '',
    // url: ''
}

const storiesHopeSlice=createSlice({
    name:'storiesHopeSlice',
    initialState:initialHomeState,
    reducers:{
        setStoryHopeData(state,action:PayloadAction<StoriesHopeModel>){
            state.data = action.payload?.data;
            state.status = action.payload?.status;
            // state.display_order = action.payload?.display_order;
            // state.title = action.payload?.title;
            // state.image = action.payload?.image;
            // state.url = action.payload?.url;
        }
    }
})
export default storiesHopeSlice;