import {ChatBotModel, } from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialFeedbackState:ChatBotModel={
    status: 0,
    message: '',
    data: '',
}

const chatBotSlice=createSlice({
    name:'chatbot',
    initialState:initialFeedbackState,
    reducers:{
        setChatBotData(state,action:PayloadAction<ChatBotModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default chatBotSlice;