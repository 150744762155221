import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { PastEventModel } from "../../models/event-models";


const initialPastEventState:PastEventModel={
    status: 0,
    message: '',
    event_past_data: []
}

const pastEventSlice=createSlice({
    name:'pastEvent',
    initialState:initialPastEventState,
    reducers:{
        setPastEventData(state,action:PayloadAction<PastEventModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.event_past_data = action.payload?.event_past_data;
        }
    }
})
export default pastEventSlice