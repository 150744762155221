import React, { useState, useEffect, Suspense } from "react";
import "./doctorsPage.css";
import { Box, CircularProgress } from "@mui/material";
import Header from "../../components/Common-Component/Header/header";
import Footer from "../../components/Common-Component/Footer/footer";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { fetchDoctortData, fetchDoctorScheduleData, createAppointment } from '../../store/doctor/doctor-actions';
import { useLocation } from "react-router";
import { handleSlots } from "../../utils/commonFunctions";
import Seo from "../../components/Common-Component/SEO/seo";
import { initiatePay } from "../../store/doctor/otp-actions";
import Cookies from 'universal-cookie';
import { handleAlert } from "../../values/helper";
import ConsultationHead from "../../components/DoctorsPage-Component/consultationHead/consultationHead";
import useRazorpay from "react-razorpay";

const ConsultationInfo = React.lazy(() => import('../../components/DoctorsPage-Component/ConsultationInfo/consultationInfo'));
interface GlobalTranslat {
    translation: any
}
export default function DoctorsPage({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const [alert, setAlert] = useState(false);
    const [slots, setSlots] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [time, setTime] = useState('');
    const [date, setDate] = useState<any>({});
    const doctorData = useAppSelector(state => state?.doctor);
    const doctorScheduleData = useAppSelector(state => state?.doctorSchedule);
    const paymentData = useAppSelector(state => state?.Payment);
    const location = useLocation();
    const [load, setLoad] = useState(true);
    const [modal, setModal] = useState(false);
    const cookies = new Cookies();
    const Razorpay = useRazorpay()

    //NOTE: Life cycle Methods
    useEffect(() => {
        let URL_SCHEDULE = 'get_reschedule_appointment'
        const doctorLink = window.location.href.split("doctors/")[1];
        if (location.state?.id !== undefined) {
            let data = {
                doctorId: location.state?.id,
            }
            dispatch(fetchDoctortData(data));
            dispatch(fetchDoctorScheduleData(URL_SCHEDULE + '?doctorId=' + location.state?.id));
        } else {
            let data = {
                doctor_slug: doctorLink,
            }
            dispatch(fetchDoctortData(data));
            dispatch(fetchDoctorScheduleData(URL_SCHEDULE + '?doctor_slug=' + doctorLink));
        }
    }, []);
    useEffect(() => {
        if (doctorScheduleData !== undefined && doctorScheduleData?.data?.length > 0) {
            handleSlots(doctorScheduleData, setSlots, setAvailableDates)
        }
    }, [doctorScheduleData]);
    useEffect(() => {
        if (paymentData?.razorpay_config?.length > 0) {
            let filterData = paymentData.razorpay_config.filter(
                item => item.description === 'Razorpay key',
            );
            if (filterData?.length > 0) {
                // setRazorPayKey(filterData[0]?.value);
                setTimeout(() => {
                    onPaymentClick()
                    onBook(paymentData);
                }, 600);
            }
        }
    }, [paymentData]);
    const handlePaymentSuccess = (reponse: any) => {
        console.log('Payment successful! Doctor Detail--', reponse);
        setModal(true)
    }
    const onPaymentClick = () => {
        const options = {
            key: paymentData.razorpay_config[1]?.value,
            amount: paymentData?.data?.amount?.toString(),
            currency: "INR",
            name: "ZenOnco.io",
            description: "",
            image: 'https://zenonco-web-image.s3.ap-south-1.amazonaws.com/assets/images/Logo.svg',
            order_id: paymentData?.data?.id,
            prefill: {
                name: cookies.get('name'),
                email: cookies.get('Email'),
                contact: cookies.get('number'),
            },
            notes: {
                address: "",
            },
            handler: (response: any) => {
                handlePaymentSuccess(response);
            },
            theme: { color: '#104462' }
        };
        const rzp1 = new Razorpay(options);
        rzp1.open();
        // navigate("/thankyou", { replace: true })
        // navigate(0);
    }
    const initiatePayment = (time: string, date: any) => {
        setTime(time)
        setDate(date)
        let data = {
            userId: cookies.get('userId'),
            subscriptionPlanId: true,
            amount: doctorData?.doctor_detail[0]?.fee,
            currency: 'INR',
        }
        dispatch(initiatePay(data, cookies.get('token')));
    }
    const onBook = (data: any) => {
        const userId = cookies.get('userId')
        const obj = {
            // doctorId: location.state.id,
            doctorId: doctorData?.doctor_desc[0]?.apptNameId,
            userId: userId,
            apptDate: date?.date,
            apptTime: time,
            paymentId: data?.data?.id,
            paymentStatus: "1",
            completed: "1",
        }
        dispatch(createAppointment(obj, cookies.get('token')))
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setLoad(false)
        }, 2000);
        // onBook(doctorData?.doctor_detail);
    }, [doctorData?.doctor_detail.length]);
    //NOTE: UI Implementation  
    return (
        <React.Fragment>
            <Seo translation={translation}
                metaData={doctorData.metadatas} />
            <Box className="Header-Top-Box">
                <Box className="DRpage-Header">
                    <Header alert={alert} apointment={true} darkTheme={true} translation={translation} />
                </Box>
            </Box>
            <ConsultationHead translation={translation} load={load} doctorInfo={doctorData?.doctor_detail} doctorHospital={doctorData.hospital_detail} />
            <Box>
                <Suspense fallback={<CircularProgress color="secondary" className="loader-position-DR" />}>
                    <ConsultationInfo setModal={setModal} modal={modal} translation={translation} load={load} doctorScheduleData={doctorScheduleData} handleAlert={() => handleAlert(setAlert)} availabelDates={availableDates} doctorInfo={doctorData?.doctor_detail} initiatePayment={initiatePayment}
                        doctorEducation={doctorData?.doctor_education} doctorDescription={doctorData.doctor_desc} />
                </Suspense>
            </Box>
            <BookConsultation translation={translation} />
            <Box className="Footer">
                <Footer translation={translation} />
                <MostSearched translation={translation} />
            </Box>
        </React.Fragment>
    );
}