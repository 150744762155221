import {MostSearchModel} from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialHomeState:MostSearchModel={
    status: 0,
    message: '',
    data:[]
}

const mostSearcheSlice=createSlice({
    name:'mostSearched',
    initialState:initialHomeState,
    reducers:{
        setMostSearchData(state,action:PayloadAction<MostSearchModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default mostSearcheSlice;