import React from "react";
import "./awards.css";
import { Box, Typography } from "@mui/material";
import { awardNewsModel } from "../../../models/about-models";

import "react-multi-carousel/lib/styles.css";
import Exportcounsult from "../../Common-Component/CousultExpDoctor/exportconsult";

interface awardsRecognizationList {
    awardsList: Array<awardNewsModel>
    translation: any
}
export default function AwardsAndRecognition({ awardsList, translation }: awardsRecognizationList) {
    return (
        <Box className="Main-Awards-RecognitionBox">
            <Box className="Awards-Text-box" >
                <Typography className="Awards-Text">
                    {translation?.Awardsandrecognition}
                </Typography>
            </Box>
            <Box className="Recognized-carosualbox" aria-hidden="false">
                <Exportcounsult translation={translation} aboutPage={true} doctorsList={awardsList} awardlist={awardsList} />

            </Box>
        </Box>
    );
}