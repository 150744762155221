import React from "react";
import "./faq.css";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from "@mui/material";


interface Faqdatas {
    faqanswers?: any[]
    FaqText: Boolean;
}

export default function Faq({ FaqText, faqanswers }: Faqdatas) {

    return (
        <React.Fragment>
            {faqanswers?.length !== undefined && faqanswers?.length > 0 &&
                <>
                    <Box className="fullFaq-block">
                        {faqanswers?.map((items, index) => (
                            <Box key={items.question} tabIndex={-1}>
                                <Accordion className="Accordion-Box" >
                                    <AccordionSummary>
                                        <Typography className="Canicall-doctorText">{items.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="AccordionDetails-Box">
                                        <Box className="orange-Bar" />
                                        <Box tabIndex={-1}>
                                            <div tabIndex={-1} className="Youcanwhats-appText" dangerouslySetInnerHTML={{ __html: items.answer }} />
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                                {(index < faqanswers?.length - 1) && <Divider />}
                            </Box>
                        ))}
                    </Box>
                </>
            }
        </React.Fragment>
    );
}