import React, { Fragment } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./paymentBox.css";
import AppImages from "../../../images/AppImages";
import { homePage, selectAddressPage } from "../../../values/paths";


export default function PaymentMethod(Props: { translation: any, Address?: any; continue?: any; details?: any; totalQuantity?: any, totalPrice?: any, cartData?: any }) {

    return (
        <Fragment>
            <Box className="mainPaymentBox">
                <Typography className="PriceDetail-txt">
                    {Props?.translation?.PriceDetails}
                </Typography>
                <Box className="FinalPriceMainBox">
                    <Box className="priceTotalBox">
                        <Typography className="price-item-txt">
                            {Props?.translation?.Price} ({Props.totalQuantity})
                        </Typography>
                        <Typography className="Price-txt">
                            {Props?.translation?.Rs} {Props.totalPrice}
                        </Typography>
                    </Box>

                </Box>
                <Divider className="DVD-Width" />
                <Box className="FinalPriceMainBox">
                    <Box className="finalPrice-Box">
                        <Typography className="finalTotal-txt">{Props?.translation?.Total}</Typography>
                        <Typography className="finalPrice-txt">{Props?.translation?.Rs} {Props.totalPrice}</Typography>
                    </Box>
                    {Props?.Address && !Props?.details ? null : Props.totalPrice > 0 && <Link className="CheckOut-btn"
                        to={Props?.continue ? homePage : selectAddressPage}
                        state={{ totalQuantity: Props.totalQuantity, totalPrice: Props.totalPrice }}>
                        <Typography className="CheckOut-text">
                            {Props.continue ? Props?.translation?.Continue : Props?.translation?.Checkout}
                        </Typography>
                    </Link>}
                </Box>
            </Box>
            <Box className="couponBox">
                <img src={AppImages.Coupon} alt={Props?.translation?.Coupon} width="50%" />
                <img src={AppImages.PaySecurely} alt={Props?.translation?.PaySecuarly} width="69%" />
            </Box>
        </Fragment >
    )
}