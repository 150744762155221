import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store'
import { Provider } from 'react-redux';
import "react-multi-carousel/lib/styles.css";
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module'
import "slick-carousel/slick/slick.css";

const tagManagerArgs = {
  gtmId: 'GTM-TXD8RW7'
}

setTimeout(() => {
  TagManager.initialize(tagManagerArgs);
}, 5000)
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
reportWebVitals();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router >
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
