import React, { useState } from "react";
import { Box, Typography, TextField, Autocomplete, Divider, Button, Alert } from "@mui/material";
import "./connectForm.css";
import CountryCode from "../../CountryCode.json";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Translate {
    translation: any
    onPressSubmit?: any
    setConnectDetails: any
    connectDetails: any
    setSubmited: any;
    submited: any;
    loading: any;
}
export default function ConnectForm({ loading, translation, onPressSubmit, setSubmited, connectDetails, setConnectDetails, submited }: Translate) {
    const [alert, setAlert] = useState(false);

    const handleConnectDetails = (props: any) => (e: any, newValue?: any) => {
        if (props === "number") {
            const onlyNums = e.target?.value?.replace(/[^0-9]/, '');
            setConnectDetails({ ...connectDetails, [props]: onlyNums });
        } else if (props === "countryCode") {
            setConnectDetails({ ...connectDetails, [props]: newValue?.phone });
        } else if (props === "name") {
            const onlyAlphabet = e.target.value.replace(/[^a-zA-Z\s]/g, '');
            setConnectDetails({ ...connectDetails, [props]: onlyAlphabet });
        } else {
            setConnectDetails({ ...connectDetails, [props]: e.target.value });
        }
    }

    function handleValidation() {
        if (connectDetails.name === "" || connectDetails.number === "") {
            setAlert(true);
        } else {
            onPressSubmit(connectDetails);
            setSubmited(true)
            setTimeout(() => { setSubmited(false); }, 6000)
            setTimeout(() => {
                setConnectDetails({ name: "", countryCode: "", number: "" })
            }, 6000)
            setAlert(false);
        }
    }
    return (
        <Box className="connectForm_main-box">
            <Typography className="connect-wellness-txt">{translation?.ConnectWellnesscoach}</Typography>
            <TextField
                autoComplete='off'
                value={connectDetails.name}
                onChange={handleConnectDetails('name')}
                className="connectForm_main-name-Box"
                placeholder={translation?.Name}
                sx={loading && { "& .MuiOutlinedInput-root": { "& > fieldset": { border: "none", borderColor: "#ffff !important" }, } }}
                InputProps={{ className: loading ? "auto-inputBox" : "" }}
            />
            {alert && connectDetails.name === "" && <Alert severity="error" className="connectForm_alert-box">{translation?.PleaseEntername}</Alert>}
            <Box className="country_to-numberBox">
                <Autocomplete
                    onChange={handleConnectDetails('countryCode')}
                    className="CountryconnectForm"
                    id="country-select"
                    popupIcon={<KeyboardArrowDownIcon className="country-keyboardIcon" />}
                    options={loading ? CountryCode.Code : []}
                    disableClearable
                    autoHighlight
                    defaultValue={{ "code": "IN", "label": "India", "phone": "+91" }}
                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                    getOptionLabel={(option) => option.phone}
                    isOptionEqualToValue={(option, selected) => selected.phone === "" || option.phone === selected.phone ? true : false}
                    renderOption={(props, option) => (
                        <Box key={option.label} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img width="20" src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} alt="" />
                            {option.phone}
                        </Box>)}
                    renderInput={(params) => (
                        <TextField autoComplete="off" label={translation?.Code} variant="standard"  {...params} sx={{ "& .MuiInputBase-root": { fontSize: "14px", borderRadius: "0px", padding: "0px 0px 0px 0px" } }} />)} />
                <Divider orientation="vertical" className="country_to-numberDivider" />
                <TextField
                    autoComplete='off'
                    value={connectDetails.number}
                    onChange={handleConnectDetails('number')}
                    className="connectForm_main-number-Box"
                    placeholder={translation?.PhoneNumberTxt}
                    sx={loading && { "& .MuiOutlinedInput-root": { "& > fieldset": { border: "none", borderColor: "#ffff !important", }, } }}
                    InputProps={{ className: loading ? "auto-inputBox" : "" }} />
            </Box>
            {alert && connectDetails.number === "" &&
                <Alert severity="error" className="connectForm_alert-box">{translation?.PleaseEnternumber}</Alert>}
            <Button disableRipple className="connectForm_submit-Btn" onClick={handleValidation}>
                <Typography className="connectForm_submit-txt">
                    {translation?.Submit}
                </Typography>
            </Button>
            {submited && <Alert severity="success">
                <Typography>
                    {translation?.ThankYou},{translation?.OurWellnessCounselors}
                </Typography>
            </Alert>}
        </Box>
    );
}