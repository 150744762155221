import React, { useState, useEffect, Fragment } from "react";
import { Box, CircularProgress } from "@mui/material";
import "./productsPage.css";
import Header from "../../components/Common-Component/Header/header";
import ProductsHead from "../../components/ProductsPage-Component/ProductsHead/productsHead";
import MostSearched from '../../components/Common-Component/MostSearched/mostSearched';
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import ProductsList from "../../components/ProductsPage-Component/ProductsList/productsList";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { fetchFilterData, fetchProductData, fetchProductDetail } from "../../store/product/product-actions";
import { ProductListDataModel } from "../../models/product-models";
import { handleAlert } from "../../values/helper";

interface GlobalTranslat {
    translation: any
}
export default function ProductsPage({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const productList = useAppSelector(state => state?.productList);
    const [page, setPage] = useState(1)
    const [alert, setAlert] = useState(false);
    const [categoryID, setCategoryID] = useState({
        category: "",
        categoryId: -1
    })
    const [refresh, setRefresh] = useState(false);
    const [sorting, setSorting] = useState('')
    const [search, setSearch] = useState('')
    const [noMoreData, setNoMoreData] = useState(true)
    const [noData, setNoData] = useState(false)
    const [productData, setProductData] = useState<Array<ProductListDataModel>>([]);

    useEffect(() => {
        dispatch(fetchFilterData());
    }, []);
    function onLoadMore() {
        setPage(page + 1);
    }
    useEffect(() => {
        let URL = 'get-products?page=' + page

        if (categoryID.categoryId > 0) {
            URL = URL + '&categoryId=' + categoryID?.categoryId
        }
        if (sorting) {
            URL = URL + '&sort_by_value=' + sorting
        }
        if (search) { URL = URL + '&search_text=' + search }
        dispatch(fetchProductData(URL));
    }, [categoryID, sorting, search, page, dispatch])
    useEffect(() => {
        if (productList.status === 1) {
            setNoMoreData(true);
            if (productList?.data?.length > 0) {
                let extra = productData.concat(productList?.data);
                let filtered = extra.filter((value, i, arr) => { return (arr.findIndex((v) => v.id === value.id) === i); });
                setProductData(filtered);
            } else {
                setNoData(true)
                setNoMoreData(false);
            }
        } else {
            setNoMoreData(false);
        }
    }, [productList]);

    const getProductDetail = (id: number) => {
        let data = {
            id: id
        }
        dispatch(fetchProductDetail(data));
    }

    return (
        <>
            <Box className="Products-Header" zIndex={1000}>
                <Header darkTheme={true} alert={alert} translation={translation}/>
            </Box>
            <ProductsHead translation={translation} />
            <ProductsList translation={translation} productList={productList} handleAlert={() => handleAlert(setAlert)} noData={noData} refresh={refresh} setRefresh={setRefresh} categoryID={categoryID} sorting={sorting} pageNo={page} setSearch={setSearch} setSorting={setSorting} setCategoryID={setCategoryID}
                onLoadMore={onLoadMore} noMoreData={noMoreData} productData={productData} getProductDetail={getProductDetail}
                setProductData={setProductData} setPage={setPage} search={search} />
            <BookConsultation translation={translation}/>
            {productData?.length ? <Box className="Footer">
                <Footer translation={translation}/>
                <MostSearched translation={translation} />
                <br />
            </Box>
                : <CircularProgress color="secondary" className="loader-position-DR" />}
        </>
    );
}