import React, { Fragment, useState } from "react";
import { Box, Button, Chip, TextField, Typography, Checkbox, useMediaQuery } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import "./productsList.css";
import PersonalizedBox from "../../Common-Component/PersonalizedCareBox/personalizedBox";
import ProductsCard from "../ProductsCard/productsCard";
import SortLabels from "./sortBy.json";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { OnlyAlphabets } from "../../../values/helper";
import Seo from "../../Common-Component/SEO/seo";


interface ProductList {
    DataList?: Array<string>
    pageChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
    Filter?: Array<string>
    filteredData?: any
    setFilteredData?: any
    categoryID?: any
    setCategoryID?: any
    setSearch?: any
    search?: any
    productData?: any
    getProductDetail?: any
    onLoadMore: Function
    handleAlert: any
    pageNo: any
    sorting: any
    setSorting?: any
    setProductData?: any
    setPage?: any
    noMoreData?: any
    setRefresh?: any
    refresh?: any
    noData?: any
    productList?: any
    translation: any
}

export default function ProductsList({ translation, productList, noData, handleAlert, refresh, setRefresh, noMoreData, sorting, setProductData, setPage, setSorting, pageNo, onLoadMore, productData, categoryID, getProductDetail, setSearch, search, setCategoryID, pageChange, setFilteredData, filteredData }: ProductList) {
    const [selectedChip, setSelectedChip] = useState(-5);
    const productFilterData = useAppSelector(state => state?.productFilter);
    const maxWidth500 = useMediaQuery("(max-width:1025px)");

    const handleFilteredValue = (Props: any, index: any) => {
        if (categoryID.categoryId === Props.categoryId) {
            setCategoryID({ categoryId: '' });
            setSelectedChip(-5)
        } else {
            setCategoryID({ categoryId: Props?.categoryId });
            setProductData([])
            setPage(1)
            setSelectedChip(index)
        }
    }
    const handleSorting = (e: any) => {
        if (sorting === e.value) {
            setSorting("");
        } else {
            setSorting(e.value)
        }
        setProductData([])
        setPage(1)
    }
    const handleSelectValue = (item: any, index: any) => {
        setSelectedChip(index)
        handleFilteredValue(item, index)
    }
    const handleSearchData = (e: any) => {
        setSearch(e?.target?.value?.replace(OnlyAlphabets, ''));
        setProductData([])
        setPage(1)
    }
    return (
        <React.Fragment>
            <Seo translation={translation}
                metaData={productList.metadatas}
                title="Cancer Treatment in India | Integrative Oncology - ZenOnco.io" />
            <Box className="ListOfProducts-MainBox">
                <Box className="ProductsLeftside-Box">
                    <Box className="ProductsLeftside-Box-subbox">
                        <TextField
                            value={search}
                            onChange={handleSearchData} className="FilterBysearch-Box" placeholder={translation?.SearchProducts} InputProps={{
                                startAdornment: (
                                    <SearchIcon className="Search-Icon" />
                                )
                            }} />
                        <Box>
                            <Typography className="Filter-byText">
                                {translation?.FilterBy}
                            </Typography>
                            <Box className="FilterByBadgesBox">
                                {productFilterData?.filter_by.map((item: any, index: any) => (
                                    <Chip key={index} label={item?.category} onClick={() => handleSelectValue(item, index)}
                                        className={selectedChip === index ? "SelectedFillter-Badges" : "FilterdBadges"} />
                                ))}
                            </Box>
                        </Box>
                        <Box>
                            <Typography className="Filter-byText">
                                {translation?.SortBy}
                            </Typography>
                            <Box>
                                {SortLabels.Labels.map((label, indedx) => (
                                    <Box className="sortBy-Box" key={label.label}>
                                        <Box>
                                            <Checkbox
                                                id={label?.label}
                                                disableRipple
                                                checked={sorting === label.value ? true : false}
                                                className="checkBox-Filtered" sx={{
                                                    color: "#808080",
                                                    '&.Mui-checked': {
                                                        color: "#f57625",
                                                    },
                                                }}
                                                onChange={() => handleSorting(label)} />
                                        </Box>
                                        <Box className="SortCheckBox">
                                            <label htmlFor={label?.label} className="sortBY-txt">{label.label}</label>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    {!maxWidth500 && <PersonalizedBox translation={translation} />}
                </Box>
                <Box className="ProductsRightSide-Box">
                    <Box className="ProductsListBox">
                        {productData?.length > 0 ? productData?.map((item: any, index: number) => (
                            <Fragment key={item?.id}>
                                <ProductsCard translation={translation} setRefresh={setRefresh} refresh={refresh} handleAlert={handleAlert} productItem={item} getProductDetails={(id: number) => getProductDetail(id)} />
                            </Fragment>)) :
                            (noData && productData?.length === 0) && <div className="Data-Not-Found">{translation?.Datanotfound}</div>}
                    </Box>
                    <Box className="ProductsRightSide-Box">
                        {(noMoreData && productData?.length > 4) && <Button disableRipple className="Loadproduct-btn" onClick={() => onLoadMore()}>
                            <Typography className="ViewAll-textproduct">
                                {translation?.Loadmore}
                            </Typography>
                        </Button>}
                    </Box>
                </Box>
                {maxWidth500 && <PersonalizedBox translation={translation} />}
            </Box>
        </React.Fragment >
    );
}