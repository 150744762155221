import React, { useEffect, useState } from 'react'
import { Box, Dialog, TextField, Typography, Button, Alert, Divider, Autocomplete } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import AppImages from '../../../../images/AppImages';
import CountryCode from "../../../CountryCode.json";
import { OnlyNumbers } from '../../../../values/helper';
import { OnlyAlphabets } from '../../../../values/helper';
import { confirmOTP, getCancerFormList, getOTPRequests } from '../../../../store/doctor/otp-actions';
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import Cookies from 'universal-cookie';
import CloseIcon from '@mui/icons-material/Close';


export default function TreatAndOtp(Props: { translation: any, drInfo?: any, isDoctor?: any, setModal: any, modal: boolean, handleCloseModal: Function, amount?: number }) {
  const [otp, setOtp] = useState('');
  const [reportDetails, setReportDetails] = useState({
    name: "", code: "+91", number: ""
  });
  const [showAlert, setShowAlert] = useState(false)
  const [showOTP, setShowOTP] = useState(false)
  const [modal, setModal] = useState(false);
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const OTPData = useAppSelector(state => state?.CONFIRMOTP);
  const GETOTPData = useAppSelector(state => state?.OTP);
  const [showMsg, setShowMsg] = useState(false);
  const handleChange = (Prop: any) => (event: any, newValue?: any) => {
    if (Prop === 'number') {
      const onlyNums = event.target.value.replace(OnlyNumbers, '');
      if (onlyNums.length <= 10) {
        setReportDetails({ ...reportDetails, [Prop]: onlyNums });
      }
    } else if (Prop === 'code') {
      setReportDetails({ ...reportDetails, [Prop]: newValue?.phone });

    } else if (Prop === "name") {
      const onlyAlphabet = event.target.value.replace(OnlyAlphabets, '');
      setReportDetails({ ...reportDetails, [Prop]: onlyAlphabet });
    } else {
      setReportDetails({ ...reportDetails, [Prop]: event.target.value });
    }
  }
  function handleError() {
    if (reportDetails.name.length < 1) {
      setShowAlert(true)
      setShowOTP(false)
    } else if (reportDetails.number.length < 1) {
      setShowAlert(true)
      setShowOTP(false)
    } else {
      setShowAlert(false)
      genrateOtp()
    }
  }
  function closeDrawer() {
    Props.setModal(false)
  }
  function genrateOtp() {
    let data = {
      name: reportDetails.name,
      phone: reportDetails.number,
      whatsapp_update: 1
    }
    cookies.set("name", reportDetails.name, { path: '/' })
    cookies.set("number", reportDetails.number, { path: '/' })
    setShowOTP(true)
    dispatch(getOTPRequests(data));
  }
  useEffect(() => {
    setTimeout(() => {
      dispatch(getCancerFormList());
    }, 4000)
  }, [])
  useEffect(() => {
    if (OTPData.status === 200) {
      cookies.set("token", OTPData?.data?.data?.token, { path: '/' });
    }
  }, [OTPData]);
  function confirmOTPMethod() {
    if (GETOTPData?.otp === otp) {
      let data = {
        otp: otp,
        userId: GETOTPData?.data?.id
      }
      cookies.set("userId", GETOTPData?.data?.id, { path: '/' })
      dispatch(confirmOTP(data));
      Props.setModal(false)
    } else {
      setShowAlert(true);
    }
  }
  function handleOTPError() {
    if (otp.length <= 3) {
      setShowAlert(true)
    } else {
      setModal(true);
      setShowAlert(false)
      confirmOTPMethod()
    }
  }
  function resendOtp() {
    genrateOtp()
    setShowMsg(true)
    setTimeout(() => {
      setShowMsg(false)
    }, 2000)
  }
  function handleEditNumber() {
    setShowOTP(false)
  }
  const handleOTP = (newValue: string) => {
    const onlyNums = newValue.replace(/[^0-9]/, '');
    if (onlyNums.length >= 0) {
      setOtp(onlyNums);
    }
  }

  return (
    <Dialog
      PaperProps={{ className: "treatmentmodel-dialog" }}
      open={Props.modal}
      onClose={() => Props.handleCloseModal()}
      fullWidth
      maxWidth="sm">
      <Button disableRipple className="modal-closeBtn" onClick={closeDrawer}>
        <CloseIcon />
      </Button>
      <>
        <Box className="treatmentModal-MainBox">
          <Box className="treatmentModal-Box">
            <Typography className="treatmentModal-title-text">
              {Props?.isDoctor != undefined && Props?.isDoctor ? Props?.translation?.BookappointmentWith + Props?.drInfo?.doctorName + Props.translation?.SignUpNow : Props.translation?.WantSignUpNow}
            </Typography>
            <Box className="treatmentModal-txtF-Box">
              <TextField
                autoComplete='off'
                disabled={showOTP ? true : false}
                value={reportDetails.name}
                onChange={handleChange("name")}
                placeholder={Props.translation?.Name}
                className="treatmentModal-nametxtF-Box"
                size="small"
              />
              {
                showAlert && reportDetails.name.length < 1 &&
                <Alert severity="error" className="modal-error-Box"> {Props.translation?.PleaseEntername} </Alert>
              }
              <TextField
                autoComplete="off"
                disabled={showOTP ? true : false}
                value={reportDetails.number} className="numberTyp-txtField"
                onChange={handleChange("number")}
                placeholder={Props.translation?.PhoneNo} size="medium" InputProps={{
                  startAdornment: (
                    <Box className="modal-input-items">
                      <Autocomplete
                        disabled={showOTP ? true : false}
                        className="CountryConnectCode"
                        id="country-select-"
                        options={CountryCode.Code}
                        disableClearable
                        autoHighlight
                        defaultValue={{ "code": "IN", "label": "India", "phone": "+91" }}
                        onChange={handleChange("code")}
                        componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                        getOptionLabel={(option) => option.phone}
                        renderOption={(props, option) => (
                          <Box key={option.label} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0, } }} {...props}>
                            <img
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField variant="standard"  {...params}
                            sx={{ "& .MuiInputBase-root": { borderRadius: "0px", padding: "0px 0px 0px 0px" } }} />
                        )} />
                      <Divider orientation="vertical" className="Modal-txtDivider" />
                    </Box>)
                }} required />
              {
                showAlert && reportDetails.number.length < 1 &&
                <Alert severity="error" className="modal-error-Box"> {Props?.translation?.PleaseEnternumber} </Alert>
              }
              <Button disableRipple disabled={showOTP ? true : false} className="treatmentModal-txtF-Btn" onClick={handleError} sx={{ background: showOTP ? "#d9d9d9 !important" : "#f57822 !important" }} >
                <Typography className="treatmentModal-Booklabel">{Props?.translation?.Submit}</Typography>
              </Button>
            </Box>
          </Box>
          {showOTP &&
            <Box className="treatmentModal-txtF-Box2" >
              <Box className="otpsentbox">
                <Typography className="OTPsentext"> {Props?.translation?.SentOTPTo}   </Typography>
                <Box display="flex" gap="6px">
                  <Typography className="OTPsentext1">{reportDetails?.code} {reportDetails.number}</Typography>
                  <Box>
                    <img src={AppImages.Edit} onClick={handleEditNumber} alt="Awards" width="50%" height="auto" /></Box>
                </Box>
              </Box>
              <MuiOtpInput
                className='treatment-muiotpinput'
                length={4}
                value={otp}
                onChange={handleOTP}
              />
              <Button
                disableRipple
                disabled={otp.length > 3 ? false : true}
                className='Treatment-verfiybtn'
                onClick={handleOTPError}
                sx={{
                  background: otp.length > 3 ? "#f57822 !important" : "#d9d9d9 !important",
                }}>
                <Typography className="treatmentModal-Booklabel">{Props?.translation?.VerifyOTP}</Typography>
              </Button>
              <Button
                disableRipple
                className='Treatment-verfiybtn'
                sx={{
                  background: "#f57822 !important"
                }}
                onClick={resendOtp}>
                <Typography className="treatmentModal-Booklabel">{Props?.translation?.ResendOTP}</Typography>
              </Button>
              <Box>
                {showMsg &&
                  <Alert severity="success" className="modal-error-Box">{Props?.translation?.Yoursucessfully}</Alert>
                }
                {
                  showAlert && otp.length <= 3 ?
                    <Alert severity="error" className="modal-error-Box">  {Props?.translation?.PleaseEnteOTP} </Alert> :
                    showAlert && GETOTPData?.otp !== otp ?
                      <Alert severity="error" className="modal-error-Box">{Props?.translation?.PleaseentercorrectOTP}</Alert> : null
                }
              </Box>
            </Box>}
          <Box className="treatmentModel-imgmainbox" >
            <Box className="treatmentModel-imgmainbox1" >
              <img
                className="treatmentModel-img"
                src={AppImages.LoveHealsCancer}
                alt='error'
              />
            </Box>
          </Box>
        </Box>
      </>
    </Dialog>
  );
}

