import React, { memo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import "./zenOncoApp.css";
import appDetails from "./appDetails.json";
import AppImages from "../../../images/AppImages";
import { appStore, googlePlay } from "../../../constants/AppConstants";

interface Translate {
    translation: any
}
function ZenOncoApp({ translation }: Translate) {
    const [selectedImg, setSelectedImg] = useState(0);
    const handleImages = (i: any) => {
        setSelectedImg(i)
    }
    return (
        <Box className="zenOncoApp_main-Box">
            <Typography className="zenOncoApp_Head-txt">{translation?.DownlaodAPP}</Typography>
            <Box className="Appimagespoints-Box">
                {appDetails.appDetails.map((imgs, index) => (
                    selectedImg === index &&
                    <Box key={imgs?.id} className="images-boxmobile">
                        <img className="main-img" src={require(`../../../images/zenOncoApp-images/${imgs?.image}`)} key={imgs?.id} alt={translation?.Downloadapp} loading="lazy" />
                    </Box>))}
                <Box className="Head_Points-Box">
                    <Box>
                        {appDetails?.appDetails?.map((pts, index) => (
                            <Button key={pts?.id} disableRipple className="zenOncoApppoints-Box" onClick={() => handleImages(index)} onPointerOver={() => handleImages(index)}>
                                <img src={AppImages?.Check} className="zenOncoApp_points-icon" alt={translation?.Downloadapp} loading="lazy" />
                                <Typography className="zenOncoApp_points-txt">{translation?.[pts?.point]}</Typography>
                            </Button>))}</Box>
                    <Box className="app_playstore-Box">
                        <a href={googlePlay} target="_blank" rel="noreferrer" className="DownloadBtn">
                            <img src={AppImages.playStore} className="app_playstore-img" alt={translation?.Playstore} loading="lazy" /></a>
                        <a href={appStore} target="_blank" rel="noreferrer" className="DownloadBtn">
                            <img src={AppImages.appStore} className="app_playstore-img" alt={translation?.Appstore} loading="lazy" /></a>
                    </Box></Box></Box></Box>
    );
}
export default memo(ZenOncoApp);