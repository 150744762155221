import React from "react";
import { Box, Typography, Button } from "@mui/material";
import "./searchResult.css";
import Exportcounsult from "../../Common-Component/CousultExpDoctor/exportconsult";
import EventBlogBox from "../../Common-Component/EventBlogbox/eventBlogBox";
import Carousel from "react-multi-carousel";
import SearchResponsive from "../../ThankYouPage-Component/thankyouResponsive.json";
import { ButtonGroupProps } from 'react-multi-carousel/lib/types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';


interface SearchDataDetails {
    selectedButton: any
    oncologistList: any
    medicineList: any
    eventList: any
    blogsList: any
    expertsList: any
    translation:any
}

export default function SearchResult({translation, selectedButton, oncologistList, medicineList, eventList, blogsList, expertsList }: SearchDataDetails) {
    const ButtonGroup = ({ next, previous, goToSlide, carouselState, ...rest }: ButtonGroupProps) => {
        const totalSlide = carouselState?.slidesToShow && carouselState?.currentSlide ?
            carouselState?.slidesToShow + carouselState?.currentSlide : null
        const lastItem = totalSlide !== null && carouselState?.totalItems ? totalSlide < carouselState?.totalItems : null
        return (
            <div className="Blogsexpert-Box">
                {carouselState?.currentSlide !== 0 && <Button aria-label='swipeLeft' disableRipple className="BlogsCButton-prev" onClick={previous} >
                    <NavigateBeforeIcon className="expertCButtonicon" />
                </Button>}
                {lastItem === null || lastItem ? <Button aria-label='swipeRight' disableRipple className="BlogsCButton-next" onClick={next}>
                    <NavigateNextIcon className="expertCButtonicon" />
                </Button> : null}
            </div>
        )
    }
    const totalData = oncologistList?.length + medicineList?.length + eventList?.length + blogsList?.length + expertsList?.length
    return (
        <Box className="MainSearchResult-Box">
            {
                selectedButton !== null ?
                    <>
                        {selectedButton === 0 &&
                            <Box className="Result_type-Box">
                                {oncologistList?.length > 0 ?
                                    <><Typography className="Searchresult_Txt">
                                        {translation?.Oncologists}
                                    </Typography>
                                        <Box className="Result_typeCard-Box">
                                            <Exportcounsult translation={translation} searchPage={true} doctorsList={oncologistList} doctordatass={oncologistList} />
                                        </Box> </> : <Typography className="Searchresult_Txt">{translation?.NodataFound}</Typography>}
                            </Box>}
                        {selectedButton === 1 && <Box className="Result_type-Box">
                            {medicineList?.length > 0 ? <> <Typography className="Searchresult_Txt">
                                {translation?.Medicine}
                            </Typography>
                                <Box className="Result_typeCard-Box">
                                    <Exportcounsult translation={translation} searchPage={true} doctorsList={medicineList} productlist={medicineList} />
                                </Box></> : <Typography className="Searchresult_Txt">{translation?.NodataFound}</Typography>}
                        </Box>}
                        {selectedButton === 2 && <Box className="Result_type-Box">
                            {eventList?.length ? <> <Typography className="Searchresult_Txt">
                                {translation?.Events}
                            </Typography>
                                <Box className="Result_typeCard-Box">
                                    <Exportcounsult translation={translation} searchPage={true} doctorsList={eventList} eventListdata={eventList} />
                                </Box></> : <Typography className="Searchresult_Txt">{translation?.NodataFound}</Typography>}
                        </Box>}
                        {selectedButton === 3 && <Box className="Result_type-Box">
                            {blogsList?.length > 0 ? <> <Typography className="Searchresult_Txt">
                            {translation?.Blogs}
                            </Typography>
                                <Box className="Result_typeCard-Box">
                                    <Carousel
                                        ssr
                                        responsive={SearchResponsive.responsive}
                                        renderButtonGroupOutside
                                        customButtonGroup={<ButtonGroup />}
                                        arrows={false}
                                        className="expertlCarousel">
                                        {blogsList.map((items: any, index: any) => (
                                            <Box key={index} className="Box-result">
                                                <EventBlogBox translation={translation} user={items?.author_detail} items={items} dark={false} title={true} searchPage={true} eventBlog={false} index={index} />
                                            </Box>
                                        ))}
                                    </Carousel>
                                </Box></> : <Typography className="Searchresult_Txt">{translation?.NodataFound}</Typography>}
                        </Box>}
                        {selectedButton === 4 && <Box className="Result_type-Box">
                            {expertsList?.length > 0 ? <> <Typography className="Searchresult_Txt">
                                {translation?.Experts}
                            </Typography>
                                <Box className="Result_typeCard-Box">
                                    <Exportcounsult translation={translation} searchPage={true} doctorsList={expertsList} doctordatass={expertsList} />
                                </Box></> : <Typography className="Searchresult_Txt">{translation?.NodataFound}</Typography>}
                        </Box>}
                    </> :
                    totalData > 0 ? <>
                        {oncologistList?.length > 0 && <Box className="Result_type-Box">
                            <Typography className="Searchresult_Txt">
                                {translation?.Oncologists}
                            </Typography>
                            <Box className="Result_typeCard-Box">
                                <Exportcounsult translation={translation} searchPage={true} doctorsList={oncologistList} doctordatass={oncologistList} />
                            </Box>
                        </Box>}
                        {medicineList?.length > 0 && <Box className="Result_type-Box">
                            <Typography className="Searchresult_Txt">
                                {translation?.Medicine}
                            </Typography>
                            <Box className="Result_typeCard-Box">
                                <Exportcounsult translation={translation} searchPage={true} doctorsList={medicineList} productlist={medicineList} />
                            </Box>
                        </Box>}
                        {eventList?.length > 0 && <Box className="Result_type-Box">
                            <Typography className="Searchresult_Txt">
                                {translation?.Events}
                            </Typography>
                            <Box className="Result_typeCard-Box">
                                <Exportcounsult translation={translation} searchPage={true} doctorsList={eventList} eventListdata={eventList} />
                            </Box>
                        </Box>}
                        {blogsList?.length > 0 && <Box className="Result_type-Box">
                            <Typography className="Searchresult_Txt">
                              {translation?.Blogs}
                            </Typography>
                            <Box className="Result_typeCard-Box">
                                <Carousel
                                    ssr
                                    responsive={SearchResponsive.responsive}
                                    renderButtonGroupOutside
                                    customButtonGroup={<ButtonGroup />}
                                    arrows={false}
                                    infinite={true}
                                    className="expertlCarousel">
                                    {blogsList.map((items: any, index: any) => (
                                        <Box key={index} className="Box-result">
                                            <EventBlogBox translation={translation} user={items?.author_detail} items={items} dark={false} title={true} searchPage={true} eventBlog={false} index={index} />
                                        </Box>
                                    ))}
                                </Carousel>
                            </Box>
                        </Box>}
                        {expertsList?.length > 0 && <Box className="Result_type-Box">
                            <Typography className="Searchresult_Txt">
                                {translation?.Experts}
                            </Typography>
                            <Box className="Result_typeCard-Box">
                                <Exportcounsult translation={translation} searchPage={true} doctorsList={expertsList} doctordata={expertsList} />
                            </Box>
                        </Box>}
                    </> : <Typography className="Searchresult_Txt">{translation?.NodataFound}</Typography>
            }
        </Box>
    );
}