import React from "react";
import "./meetDoctors.css";
import { Box, useMediaQuery } from "@mui/material";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import { Typography } from "@mui/material";
import AppImages from "../../../images/AppImages";


interface Translat {
    translation: any
}
export default function MeetDoctors({ translation }: Translat) {
    const mobileView = useMediaQuery("(max-width:600px)");
    return (
        <React.Fragment>
            <TopHeading>
                {mobileView ?
                    <Box className="MeetDoctors-MainBox">
                        <Box className="AllMeet-TxtBox">
                            <Typography className="MeetDoctors-Text">
                                {translation?.Consultbestdoctors}
                            </Typography></Box>
                    </Box>
                    : <Box className="MeetDoctors-MainBox">
                        <Box className="AllMeet-TxtBox">
                            <Typography className="MeetDoctors-Text">
                                {translation?.MeettheDoctors}
                            </Typography>
                            <Typography className="AskQueries-Txt">
                                {translation?.yourQueries}
                            </Typography>
                        </Box>
                        <img src={AppImages.DoctorImage} alt={translation?.DrImage} className="doctr-image" />
                    </Box>}
            </TopHeading>
        </React.Fragment>
    );
}
