import React, { useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider, CircularProgress } from "@mui/material";
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import './App.css';
import theme from './values/colors';
import { Routes, Route, } from 'react-router-dom';
import Dashboard from './pages/HomePage/homePage';
import AboutUs from './pages/AboutUsPage/aboutUsPage';
import EventsPage from './pages/EventsPage/eventsPage';
import ContactUs from './pages/ContactUsPage/contactUsPage';
import DoctorsPage from './pages/DoctorsPage/doctorsPage';
import EventDetailsPage from './pages/EventsDetailsPage/EventsDetailePage';
import BlogsPage from './pages/BlogsPage/blogPage';
import InnerBlog from './pages/InnerBlogPage/innerBlogPage';
import DoctorTalk from './pages/DoctorTalkPage/doctorTalkPage';
import DoctorsList from './pages/DoctersListPage/doctorsListPage';
import StoriesPage from './pages/StoriesPage/storiesPage';
import QueriesPage from './pages/FAQPage/faqPage';
import ThankYouPage from './pages/ThankYouPage/thankyouPage';
import TermsandConditions from './pages/Terms&conditions/terms_ConditionsPage';
import PrivacyPolicyPage from './pages/Privacy&policy/privacyPolicyPage';
import ProductsPage from './pages/ProductsPage/productsPage';
import ProductsDetailsPage from './pages/ProductsDetailPage/productsDetailsPage';
import ProductsCartPage from './pages/ProductsCartPage/productsCartPage';
import SelectAddressPage from './pages/SelecteAddressPage/selectAddressPage';
import ProductAddressPage from './pages/ProductsAddressPage/productAddressPage';
import OrderDetailsPage from './pages/OrderDetailsPage/orderDetailsPage';
import SharkTankPage from './pages/sharkTankPage/sharkTankPage';
import SearchPage from './pages/SearchPage/search';
import ZioparPage from './pages/ZioperPage/ZioparPage';
import ZioperInnerPage from './pages/ZioperInnerPage/zioperInnerPage';
import WhatsappBtn from './components/Common-Component/WhatsappBtn/whatsAppBtn';
import { fetchTranslations } from './values/helper';
import ChatbotPage from './pages/ChatbotPage/chatbotPage';
import NotFoundPage from './pages/NotFoundPage/notFoundPage';
import MessageBtn from './components/Common-Component/MessageBtn/messageBtn';
import RequestAppointment from './components/Common-Component/Common-Modals/RequestAppointment/requestAppointment';
import DownloadAppPage from './pages/downloadAppPage/downloadAppPage';
import { homePage, aboutPage, eventsPage, contactPage, doctorDetailsPage, eventsDetailsPage, blogListPage, blogDetailsPage, doctorTalkPage, doctorListPage, storiesPage, queriesPage, thankyouPage, termsConditionPage, privacyPolicyPage, productListPage, productDetailsPage, productsCartPage, selectAddressPage, productAddressPage, orderDetailsPage, sharkTankPage, searchPage, zioperPage, zioperInnerPage, chatbotInnerPage, downloadAppPage } from './values/paths';
import { useNavigate } from "react-router";

function App() {
  const [translation, setTranslations] = useState();
  const [notFound, setNotFound] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPage, setShowPage] = useState(false);
  let language = sessionStorage?.getItem("language");
  const urlLanguage = window.location.pathname.split("/");
  const navigate = useNavigate();
  useEffect(() => {
    setAnimation(true);
    const popUpModal = sessionStorage.getItem("modalOpen")
    if (!popUpModal) {
      setTimeout(() => {
        setOpenModal(true);
      }, 30000);
    }
    setTimeout(() => {
      setShowPage(true);
    }, 900);
  }, []);

  useEffect(() => {
    if (language === null) {
      if (urlLanguage[1].length > 2) {
        sessionStorage.setItem("language", "");
        if (urlLanguage[1] !== language) {
          navigate(0)
        }
      } else {
        sessionStorage.setItem("language", urlLanguage[1]);
        if (urlLanguage[1] !== language) {
          navigate(0)
        }
      }
    } else if (language !== null && urlLanguage[1] !== language) {
      if (urlLanguage[1].length > 2) {
        sessionStorage.setItem("language", "");
        if (language !== "") {
          navigate(0)
        }
      } else {
        sessionStorage.setItem("language", urlLanguage[1]);
        if (urlLanguage[1] !== language) {
          navigate(0)
        }
      }
    }
  }, [language]);

  useEffect(() => {
    fetchTranslations(language).then((a) => setTranslations(a?.commonText));
  }, [language]);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path={homePage} element={<Dashboard translation={translation} />} />
          <Route path={aboutPage} element={<AboutUs translation={translation} />} />
          <Route path={eventsPage} element={<EventsPage translation={translation} />} />
          <Route path={contactPage} element={<ContactUs translation={translation} />} />
          <Route path={doctorDetailsPage} element={<DoctorsPage translation={translation} />} />
          <Route path={eventsDetailsPage} element={<EventDetailsPage translation={translation} />} />
          <Route path={blogListPage} element={<BlogsPage translation={translation} />} />
          <Route path={blogDetailsPage} element={<InnerBlog translation={translation} />} />
          <Route path={doctorTalkPage} element={<DoctorTalk translation={translation} />} />
          <Route path={doctorListPage} element={<DoctorsList translation={translation} />} />
          <Route path={storiesPage} element={<StoriesPage translation={translation} />} />
          <Route path={queriesPage} element={<QueriesPage translation={translation} />} />
          <Route path={thankyouPage} element={<ThankYouPage translation={translation} />} />
          <Route path={termsConditionPage} element={<TermsandConditions translation={translation} />} />
          <Route path={privacyPolicyPage} element={<PrivacyPolicyPage translation={translation} />} />
          <Route path={productListPage} element={<ProductsPage translation={translation} />} />
          <Route path={productDetailsPage} element={<ProductsDetailsPage translation={translation} />} />
          <Route path={productsCartPage} element={<ProductsCartPage translation={translation} />} />
          <Route path={selectAddressPage} element={<SelectAddressPage translation={translation} />} />
          <Route path={productAddressPage} element={<ProductAddressPage translation={translation} />} />
          <Route path={orderDetailsPage} element={<OrderDetailsPage translation={translation} />} />
          <Route path={sharkTankPage} element={<SharkTankPage translation={translation} />} />
          <Route path={searchPage} element={<SearchPage translation={translation} />} />
          <Route path={zioperPage} element={<ZioparPage translation={translation} />} />
          <Route path={zioperInnerPage} element={<ZioperInnerPage translation={translation} />} />
          <Route path={chatbotInnerPage} element={<ChatbotPage />} />
          <Route path={downloadAppPage} element={<DownloadAppPage translation={translation} />} />
          <Route path='*' element={showPage ? <NotFoundPage setNotFound={setNotFound} /> : <CircularProgress color="secondary" className="loader-position-Pages" />} />
        </Routes>
        {!notFound && <>
          <RequestAppointment openModal={openModal} setOpenModal={setOpenModal} translation={translation} />
          <MessageBtn translation={translation} />
          <WhatsappBtn animation={animation} /></>}
      </ThemeProvider>
    </StyledEngineProvider>);
}
export default App;
