import React from "react";
import "./contactUsPage.css";
import { Box } from "@mui/material";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import Header from "../../components/Common-Component/Header/header";
import Contact from "../../components/ContactUspage-Component/ContactUs/contact";
interface GlobalTranslat {
    translation: any
}
export default function ContactUs({ translation }: GlobalTranslat) {
    return (
        <React.Fragment>
            <Box className="Header-Top-Box">
                <Box className="contactus-Header">
                    <Header darkTheme={true} translation={translation}/>
                </Box>
                <Box>
                    <Contact translation={translation} />
                </Box>
            </Box>
            <BookConsultation translation={translation}/>
            <Box className="ContactFooter">
                <Footer translation={translation}/>
                <br />
            </Box>
        </React.Fragment>
    );
}