import React, { useEffect } from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import Header from "../../components/Common-Component/Header/header";
import Treatment from "../../components/InnerBlog-component/Treatment/treatment";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import DownloadApp from "../../components/Common-Component/DownloadApp/downloadApp";
import TreatmentInfo from "../../components/InnerBlog-component/TreatmentInfo/treatmentInfo";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { fetchStoryDetailData } from "../../store/story/story-actions";
import { useLocation } from 'react-router-dom';
import "./innerBlogPage.css";
import Seo from "../../components/Common-Component/SEO/seo";
import PersonalizedBox from "../../components/Common-Component/PersonalizedCareBox/personalizedBox";


export default function InnerBlog(Props: { key?: number, data?: any, translation: any }) {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const storyDetailData = useAppSelector(state => state?.storyDetail);
    const query = new URLSearchParams(location?.search);
    const mobileView = useMediaQuery("(max-width:600px)");

    useEffect(() => {
        const storyLink = window.location.href.split("/cancer/")[1];

        if (location.state?.id !== undefined) {
            let data = {
                story_id: location.state?.id
            }
            console.log("story_id-=-=-=-=--->", data);
            dispatch(fetchStoryDetailData(data));
        } else {
            let data = {
                story_slug: storyLink.replace("/", '')
            }
            dispatch(fetchStoryDetailData(data));
        }
    }, []);

    return (
        <Box className="innerBlog">
            <Seo translation={Props?.translation} metaData={storyDetailData?.data?.cancer_healing_stories_metadatas} />
            <Box>
                <Box className="innerBlog-Header">
                    <Header darkTheme={true} translation={Props?.translation} />
                </Box>
                <Treatment translation={Props?.translation} data={storyDetailData?.data} />
            </Box>
            {
                storyDetailData?.data?.content?.length > 0 ? <TreatmentInfo data={storyDetailData?.data} /> :
                    <Box className="Loading-effect2">
                        <CircularProgress color="secondary" />
                    </Box>
            }
            {mobileView ? <Box className="dwnload-innerBlog"> <PersonalizedBox translation={Props?.translation} /> </Box> : <DownloadApp translation={Props?.translation} />}
            <BookConsultation translation={Props?.translation} />
            <Box className="Footer">
                <Footer translation={Props?.translation} />
                <MostSearched translation={Props?.translation} />
                <br />
            </Box>
        </Box>
    );
}