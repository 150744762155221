import { customLeadsModel } from "../../models/doctor-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialPayState: customLeadsModel = {
        status:'',
        data: [],
        message: ''
}

const customLeadsSlice = createSlice({
    name: 'customLeads',
    initialState: initialPayState,
    reducers: {
        setCustomLeadsData(state, action: PayloadAction<customLeadsModel>) {
            state.status = action.payload?.status;
            state.data = action.payload?.data;
            state.message = action.payload?.message;
        }
    }
})
export default customLeadsSlice;