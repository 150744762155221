import {FeedbackModel, } from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialFeedbackState:FeedbackModel={
    status: 0,
    message: '',
    reviews_data: [],
    page: 1,
    isLoadMore: true,
}

const feedbackSlice=createSlice({
    name:'feedback',
    initialState:initialFeedbackState,
    reducers:{
        setFeedbackData(state,action:PayloadAction<FeedbackModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.reviews_data = state.reviews_data.concat(action.payload?.reviews_data);
            state.page = state.page + 1
            state.isLoadMore = action.payload?.reviews_data?.length > 0 ? true : false
        }
    }
})
export default feedbackSlice;