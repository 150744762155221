import { DcotorScheduleModel, AvailabesDateModel } from "../models/doctor-models";
import moment from "moment";

export function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export function imageUrl(key: any) {
  return `https://img.youtube.com/vi/${key}/hqdefault.jpg`
}

export function videoLink(link: any) {
  return ` https://www.youtube.com/watch?v=${link}`
}
export const getDates = (item: { day: number; timeInfo: any; }, holidayArr: string | any[]) => {
  const now = new Date();
  const now1 = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  // const year = now.getFullYear();
  now.setDate(now.getDate() + ((item.day + (7 - now.getDay())) % 7));
  let allDates = [];

  while (now.getFullYear() <= now1.getFullYear()) {
    var pushDate = new Date(now.getTime());
    let date1 =
      pushDate.getFullYear() +
      '-' +
      (pushDate.getMonth() + 1 > 9
        ? pushDate.getMonth() + 1
        : '0' + (pushDate.getMonth() + 1)) +
      '-' +
      (pushDate.getDate() > 9 ? pushDate.getDate() : '0' + pushDate.getDate());
    if (!holidayArr.includes(date1)) {
      allDates.push({
        date: date1,
        timeInfo: item.timeInfo,
      });
    }
    now.setDate(now.getDate() + 7);
  }
  return allDates;
};
export const getTimeSlot = (obj: AvailabesDateModel, filterData: any[]) => {
  let allTimes: any[] = [];
  let currentTime = moment(moment(), 'HH:mm')
    .add(60, 'minutes')
    .format('HH:mm');
  let currentDate = moment(moment(), 'yyyy-MM-DD').format('yyyy-MM-DD');
  obj.timeInfo?.map((item: { startTime: moment.MomentInput; endTime: moment.MomentInput; durationMins: moment.DurationInputArg1; }) => {
    let startTime = moment(item?.startTime, 'HH:mm');
    //Format the end time and the next day to it
    let endTime = moment(item?.endTime, 'HH:mm');
    while (startTime < endTime) {
      let timeFilter = [];
      if (filterData?.length > 0) {
        timeFilter = filterData.filter(
          (itemA: { apptTime: string; }) => itemA.apptTime === startTime?.format('HH:mm'),
        );
      }
      let startTime1 = moment(startTime, 'HH:mm').format('HH:mm');
      //Push times
      if (timeFilter?.length === 0) {
        if (currentDate === obj.date) {
          if (startTime1 >= currentTime) {
            allTimes.push(startTime?.format('HH:mm'));
          }
          let bookedSlot: any[] = [];
          filterData.map((item) => {
            return bookedSlot.push(moment(item.apptTime, 'HH:mm').format('HH:mm'))
          })
          allTimes = allTimes.filter(function (el) {
            return !bookedSlot.includes(el);
          });
        } else if (filterData?.length > 0) {

          allTimes.push(startTime?.format('HH:mm'));
          let bookedSlot: any[] = [];
          filterData.map((item) => {
            return bookedSlot.push(moment(item.apptTime, 'HH:mm').format('HH:mm'))
          })
          allTimes = allTimes.filter(function (el) {
            return !bookedSlot.includes(el);
          });
        } else {
          allTimes.push(startTime?.format('HH:mm'));
        }
      }
      //Add interval of 30 minutes
      startTime?.add(item?.durationMins, 'minutes');
    }
    return allTimes;
  });
  return allTimes;
};

export const handleSlots = (
  doctorSchedule: DcotorScheduleModel,
  setSlots: Function,
  setAvailableDates: Function,
) => {
  let arr: any[] = [];
  let holidayArr: any[] = [];

  doctorSchedule?.app_holiday?.map(item => {
    moment.locale('en');
    var dt = item.holidayDate;
    let formattedDate = moment(dt).format('yyyy-MM-DD');
    holidayArr.push(formattedDate);
    return holidayArr
  });
  let data: any[] = [];
  doctorSchedule?.data?.map(item => {
    if (data.length > 0) {
      let filterData = data.filter(itemA => itemA.day === item.day);
      if (filterData.length > 0) {
        let obj1 = {
          startTime: item.startTime,
          endTime: item.endTime,
          durationMins: item.durationMins,
        };
        filterData[0]?.timeInfo?.push(obj1);
      } else {
        let obj = { day: 0, timeInfo: [{}] };
        obj.day = item.day;
        let obj1 = {
          startTime: item.startTime,
          endTime: item.endTime,
          durationMins: item.durationMins,
        };
        obj.timeInfo = [obj1];
        data.push(obj);
      }
    } else {
      let obj = { day: 0, timeInfo: [{}] };
      obj.day = item.day;
      let obj1 = {
        startTime: item.startTime,
        endTime: item.endTime,
        durationMins: item.durationMins,
      };
      obj.timeInfo = [obj1];
      data.push(obj);
    }
    return data
  });
  data?.map(item => {
    let arr1 = getDates(item, holidayArr);
    return arr = [...arr, ...arr1];
  });
  var sortedDates = arr?.sort(function (a, b) { return new Date(a.date).getTime() - new Date(b.date).getTime() })
  if (sortedDates.length > 0) {
    let filterData: any[] = [];
    if (doctorSchedule?.app_booking?.length > 0) {
      filterData = doctorSchedule?.app_booking?.filter(
        item => item.apptDate === sortedDates[0]?.date,
      );
    }
    console.log("TimeSlots----->", sortedDates[0], filterData)
    let timeSlots = getTimeSlot(sortedDates[0], filterData);
    setSlots(timeSlots);
  }
  setAvailableDates(sortedDates);
};