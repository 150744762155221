import React, { useState } from "react"
import "./doctorTalkHead.css";
import { Box, Typography, Button, MenuItem, FormControl, InputLabel, Select, Autocomplete, TextField, useMediaQuery } from "@mui/material";
import { ExpandIcon } from "../../Common-Component/ExpandIcon/expandIcon";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import { cancerCategoryModel } from "../../../models/story-models";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface DoctorTalk {
    cancerCategory: Array<cancerCategoryModel>
    topicList: Array<string>
    handleTypeFilter: Function
    translation: any
}
export default function DoctorTalkHead({ translation, cancerCategory, topicList, handleTypeFilter }: DoctorTalk) {
    const [selected, setSelected] = useState(true);
    const [filteredOption, setFilteredOption] = useState({ cancer_type: "", topic: "" });
    const mobileView = useMediaQuery("(max-width:601px)")

    const handleAll = () => {
        setSelected(!selected);
        setFilteredOption({ cancer_type: "", topic: "" });
        handleTypeFilter(7, 'all');
    }
    const handleCloseAll = () => {
        setSelected(false)
    }
    const handleFilterderOptions = (name: any, name1: any) => (e: any) => {
        if (name === 'topic') {
            handleTypeFilter(7, e.target.value);
        } else {
            handleTypeFilter(e.target.value, filteredOption.topic);
        }
        setFilteredOption({ ...filteredOption, [name]: e.target.value, [name1]: "" })
        if (filteredOption?.cancer_type !== "" || filteredOption?.topic !== "") {
            handleCloseAll()
        }
    };
    return (
        <React.Fragment>
            <Box>
                <TopHeading>
                    <Box className="DoctorTalk-MainBox">
                        <Typography className="DoctorTalk-Text">{translation?.Doctortalk}</Typography>
                        <Typography className="TalkQueries-Text">
                            {translation?.yourQueries}
                        </Typography>
                    </Box>
                    <Box className="doctorFilterBox">
                        <Button disableRipple className={selected ? "selected-BlogBtn" : "BlogAll-btn"} onClick={handleAll}>
                            <Typography className={selected ? "selected-Blogtxt" : "BlogAll-txt"}>{translation?.All}</Typography>
                        </Button>
                        <Box className="DoctorTalk-ChipsBox">
                            {!mobileView && <FormControl size="small" onClick={handleCloseAll}>
                                <InputLabel shrink={false} className="Blog-txt">
                                    {filteredOption.cancer_type.length !== 0 ? "" : translation?.Category} </InputLabel>
                                <Select value={filteredOption.cancer_type} onChange={handleFilterderOptions("cancer_type", "topic")}
                                    variant="standard" disableUnderline className={filteredOption?.cancer_type?.length !== 0 ? "Blog-Chip1" : "Blog-Chip"}
                                    IconComponent={ExpandIcon} >
                                    {cancerCategory.map((items, index) => (
                                        items?.name?.length > 0 && <MenuItem key={items.id} value={items.id}>{items.name}</MenuItem>))}
                                </Select>
                            </FormControl>}
                            {topicList && <Box className="Blog-ChipsBox">
                                <Box className="topic-filterBox">
                                    <Autocomplete
                                        popupIcon={<KeyboardArrowDownIcon className="auto-keyboardIcon" />}
                                        componentsProps={{ popper: { style: { width: 'max-content', zIndex: 1000 } } }}
                                        options={topicList}
                                        getOptionLabel={(option) => filteredOption?.topic ? option : ""}
                                        noOptionsText={translation?.NoresultsFound}
                                        renderOption={(props, option) => (
                                            <Box key={option}>
                                                {option?.length > 0 && <li  {...props} key={option}>
                                                    {option?.length > 0 && option}
                                                </li>}
                                            </Box>)}
                                        className="Topic-chip"
                                        onChange={(event: any, newValue: any) => {
                                            setFilteredOption({ topic: newValue, cancer_type: "" });
                                            handleCloseAll()
                                        }}
                                        renderInput={(params) => <TextField className="autotxtField-inputBox" variant="standard" placeholder={translation?.Topic} {...params}
                                            InputProps={{ ...params.InputProps, disableUnderline: true, style: { fontSize: mobileView ? "13px" : "14px", fontFamily: mobileView ? "FontLight" : "FontSemiBold", fontWeight: mobileView ? "600" : "normal" } }}
                                            sx={{
                                                input: {
                                                    color: 'black',
                                                    "&::placeholder": { opacity: 1, }
                                                },
                                            }} />} />
                                </Box>
                            </Box>}

                        </Box>
                    </Box>
                </TopHeading>
            </Box>
        </React.Fragment>
    );
}