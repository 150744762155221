import React, { useEffect, useState } from "react";
import { Box, Typography, Breadcrumbs, Link, Button, CircularProgress } from "@mui/material";
import "./productsDetailsHead.css";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { addToCartCart, getCart } from "../../../store/product/product-actions";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import { calculateDiscount } from "../../../values/helper";
import TreatAndOtp from "../../Common-Component/Common-Modals/TreatModelwithOTP/TreatOtp";
import { BackPage } from "../../../values/helper";
import { homePage, productsCartPage } from "../../../values/paths";


export default function ProductDetailsHead(Props: { translation: any, productDetails: any }) {
    const dispatch = useAppDispatch();
    const [modal, setModal] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const navigate = useNavigate();
    const cookies = new Cookies();
    const userID = cookies.get('userId')
    const [selected, setSelected] = useState(
        {
            img: Props?.productDetails?.image,
            BTN: 0
        });
    const totalProductPrice = Props.productDetails?.price * quantity;
    const increaQuantity = () => {
        setQuantity(quantity + 1);
    }
    const decreasQuantity = () => {
        setQuantity(quantity - 1);
    }
    const selectedImage = (Props: any, Props1: any) => {
        setSelected({ img: Props, BTN: Props1 });
    }

    useEffect(() => {
        if (Props?.productDetails?.product_images) {
            setSelected({ img: Props?.productDetails?.product_images[0]?.url, BTN: 0 });
        }
    }, [Props.productDetails?.product_images]);

    useEffect(() => {
        setSelected({ img: Props?.productDetails?.image, BTN: 0 })
    }, [Props?.productDetails?.image])

    const hadleCloseModal = () => {
        setModal(false);
    }
    const hadleOpenModal = () => {
        setModal(true);
    }
    const addToCart = (type: any) => {
        if (cookies.get('token') === undefined) {
            hadleOpenModal()
        }
        else if (userID !== undefined) {
            let data = {
                customer_id: userID,
                product_id: Props?.productDetails?.id,
                qunatity: quantity,
                price: Props?.productDetails?.price,
                reguler_price: Props?.productDetails?.regular_price,
                sale_price: Props?.productDetails?.price,
                total_price: totalProductPrice,
                product_name: Props?.productDetails?.name,
                image: selected?.img,
                join_register: type
            }
            dispatch(addToCartCart(data))
            setTimeout(() => {
                dispatch(getCart(userID))
            }, 1000)
            navigate(productsCartPage)
            window.scrollTo(0, 0);
        }
    }

    return (
        <>
            <TopHeading>
                {Props?.productDetails?.product_images ? <Box className="PDeatailHead-Box">
                    <Box className="PDEatlis-Navigation">
                        <Breadcrumbs separator={<NavigateNextIcon className="Navigater-seprate" />}>
                            <Link href={homePage} className="Navigation-Labels">{Props?.translation?.Home}</Link>
                            <Button onClick={() => BackPage()} className="Navigation-Labels">{Props?.translation?.Shop}</Button>
                            <Button onClick={() => BackPage()} className="Navigation-Labels">{Props?.translation?.IntegrativeMedicine}</Button>
                            <Button className="Navigation-Labels">{Props.productDetails?.name}</Button>
                        </Breadcrumbs>
                    </Box>
                    <Box className="medecine-Details-Box">
                        <Box className="medecine-allImg-Box">
                            <Box className="head-imgBox">
                                <img src={selected.img} alt={Props?.translation?.ProductsImages} className="head-img" />
                            </Box>
                            <Box className="secondary-image-Box">
                                {Props?.productDetails?.product_images?.map((images: any, index: any) => (
                                    <Box key={images?.url} className={selected?.BTN === index ? "selected-Sec-button" : "secondary-images-button"}
                                        onClick={() => selectedImage(images?.url, index)}>
                                        <img src={images?.url} alt={Props?.translation?.ProductsImages} className="secondary-images" />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box className="medicine-desc-box">
                            <Typography className="medicineOnco-txt">{Props?.productDetails?.name}</Typography>
                            <Typography className="medicine-quantity-txt">{Props?.productDetails?.productQuantity}</Typography>

                            <Box className="rating-Box">
                                <Typography className="medicine-discP-txt">{Props?.translation?.Rs} {Props?.productDetails?.price}</Typography>
                                <Typography className="medicine-orgP-txt">{Props?.translation?.Rs} {Props?.productDetails?.regular_price}</Typography>
                                <Typography className="disc-Percentage-txt">{calculateDiscount(Props?.productDetails?.regular_price, Props?.productDetails?.price, Props?.translation?.OFF)}</Typography>
                            </Box>
                            <Box className="quantity-allBox">
                                <Box className="quantity-btn-Box">
                                    <Button disabled={quantity < 2} className={quantity > 1 ? "plus-minusBtn1" : "plus-minusBtn"} onClick={decreasQuantity}> - </Button>
                                    <Typography className="quantity-txt"> {quantity} </Typography>
                                    <Button className="plus-minusBtn1" onClick={increaQuantity}> + </Button>
                                </Box>
                                <Box>
                                    <Button className="addTocart-btn" onClick={() => addToCart('register')}>
                                        <Typography className="addTocart-text">{Props?.translation?.AddtoCart}</Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                    : <CircularProgress color="secondary" className="loader-position-DetailsP" />}
            </TopHeading>
            <TreatAndOtp translation={Props.translation} setModal={setModal} modal={modal} handleCloseModal={hadleCloseModal} />
        </>
    );
}