import React from "react";
import "./productsHead.css";
import { Box, Typography } from "@mui/material";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";

interface Translate {
    translation: any
}

export default function ProductsHead({ translation }: Translate) {
    return (
        <>
            <TopHeading>
                <Box className="productsH-MainBox">
                    <Box className="productsH-TxtBox">
                        <Typography className="increasChances-Text">
                            {translation?.IncreasChances}
                        </Typography>
                        <Box className="AllPointBox">
                            <Typography className="AllPoint-txt">{translation?.AntiCancerSupplements} |</Typography>
                            <Typography className="AllPoint-txt">{translation?.Cancerspecific} |</Typography>
                            <Typography className="AllPoint-txt">{translation?.Medicalcannabis}</Typography>
                        </Box>
                    </Box>
                    {/* <img src={AppImages.Founder} alt="Doctor" className="productsH-image" /> */}
                </Box>
            </TopHeading>
        </>
    );
}