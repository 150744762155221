import React from 'react'
import { Box, Typography, Button, Divider } from '@mui/material'
import { Fragment } from 'react'
import Exportcounsult from '../../Common-Component/CousultExpDoctor/exportconsult'
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../Home-Components/CousultExprt/consultexpert.css';
import './expertSession.css';
import { eventsPage } from '../../../values/paths';

export default function ExpertSession(Props: { doctorsList?: any, eventList?: any, translation: any }) {
  const expertcard = useMediaQuery('(min-width:501px)');
  return (
    <Fragment>
      {expertcard ?
        <Box className='Main-ExpertSession'>
          <Box className="CounsultSession-box">
            <Typography className="CounsultExpert-Medical-text">
              {Props?.translation?.Joinsessions}
            </Typography>
            <Typography className="CounsultExpert-Points">
              {/* <img src={AppImages.Check} alt="Point" className='tik-Icon' /> */}
              {Props?.translation?.Organizedevery} |  {Props?.translation?.Listenexperts}
            </Typography>
          </Box>
          <Box className="ExpertSession-Cards">
            <Exportcounsult translation={Props?.translation} doctorsList={Props.doctorsList} eventListdata={Props.eventList} />
          </Box>
          <Button disableRipple href={eventsPage} className="cancerfighting-button">
            <Typography className="CounsultExpert-btntext">{Props?.translation?.Viewallevents}</Typography>
          </Button></Box>
        :
        <Box className='Main-ExpertSession'>
          <Box className="CounsultSession-box">
            <Typography className="CounsultExpert-Medical-text">
              {Props?.translation?.Joinsessions}
            </Typography>
            <Box className="ExpertSession-Cards" >
              <Exportcounsult translation={Props?.translation} doctorsList={Props.doctorsList} eventListdata={Props.eventList} />
            </Box></Box></Box>}
      <Box className='ExpertSession-divider' >
        <Divider className='ExpertSession-dvd' light />
      </Box></Fragment>
  );
}