import React, { useState } from "react";
import "./mobileHeader.css";
import { Box, Drawer, IconButton, Link, Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import AppImage from "../../../images/AppImages";
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';
import { BadgeWhatsapBtn } from "../../Home-Components/360degree/talkBtns";
import { aboutPage, blogListPage, doctorListPage, eventsPage, homePage, productListPage, searchPage } from "../../../values/paths";
export default function MobileHeader(props: { loading: any, open: any, anchorEl: any, handleClose: any, handleMenuItemClick: any, CountryLaguage: any, language: any, handleClickListItem: any, translation: any, darkTheme: any; cartPage: any; handleCart: any, count: any }) {
    const [drawer, setDrawer] = useState(false);
    const openDrawer = () => {
        setDrawer(!drawer); console.log("drawer-=-=--->", drawer);
    }
    const closeDrawer = () => { setDrawer(false); }

    return (
        <React.Fragment>
            <Box className={props.darkTheme ? "Navbar-Box1" : "Navbar-Box"}>
                <Box>
                    <IconButton disableRipple onClick={openDrawer} aria-label="Drawer">
                        <SortIcon className={!props.darkTheme ? "Drawer-btnLight" : "Drawer-btnDark"} fontSize="large" />
                    </IconButton>
                </Box>
                <Link href={homePage} className="LogoPosition-Box" rel="preload">
                    <img src={!props.darkTheme ? AppImage.DarkLogoMobile : AppImage.FooterLogo} alt={props?.translation?.ZenOcoio} className="ZenOnco-Logo" />
                </Link>
                <Link href={searchPage} className="searchMobile-btn" rel="preload">
                    <img src={!props.darkTheme ? AppImage?.searchHead : AppImage.SearchIcon} alt={props?.translation?.Search} className="searchMobile-img" />
                </Link>
                <BadgeWhatsapBtn translation={props.translation} handleCart={props.handleCart} closeDrawer={closeDrawer} darkTheme={props.darkTheme} count={props.count}
                    loading={props.loading} />
            </Box>
            <Drawer
                open={drawer}
                onClose={closeDrawer}
                className="header-drawer">
                <Box className="main-drawerBox">
                    <Box className="logo-close-Box">
                        <Button className="drawer-closeBtn" onClick={closeDrawer}>
                            <CloseIcon />
                        </Button>
                        <Link href={homePage} className="drawer-logo-position" rel="preload">
                            <img src={AppImage.DarkLogoMobile} alt={props?.translation?.ZenOcoio} className="drawerZenOnco-Logo" />
                        </Link>
                        <BadgeWhatsapBtn drawerBtn={true} translation={props.translation} handleCart={props.handleCart} closeDrawer={closeDrawer} darkTheme={props.darkTheme} count={props.count}
                            loading={props.loading} />
                    </Box>
                    <>
                        <Button disableRipple className="textType-btn-Mobile" href={homePage} onClick={closeDrawer} >{props.translation?.Home}</Button>
                        <Divider className="DVD-width" />
                        <Button disableRipple className="textType-btn-Mobile" href={doctorListPage}>{props.translation?.Doctors}</Button>
                        <Divider className="DVD-width" />
                        <Button disableRipple className={"textType-btn-Mobile"} href={eventsPage}>{props.translation?.Events}</Button>
                        <Divider className="DVD-width" />
                        <Button disableRipple className="textType-btn-Mobile" href={aboutPage}>{props.translation?.About}</Button>
                        <Divider className="DVD-width" />
                        <Button disableRipple className="textType-btn-Mobile" href={blogListPage}>{props.translation?.Blogs}</Button>
                        <Divider className="DVD-width" />
                        <Button disableRipple className="textType-btn-Mobile" href={productListPage}>{props.translation?.Products}</Button>
                        <Divider className="DVD-width" />
                        <Button disableRipple className="textType-btn-Mobile" id="selectedLanguag" onClick={props.handleClickListItem}>
                            {props.translation?.Language}
                        </Button>
                        <Divider className="DVD-width" />
                    </>
                    <Menu
                        style={{ zIndex: 10000 }}
                        id="selectedLanguag"
                        anchorEl={props.anchorEl}
                        open={props.open}
                        onClose={props.handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'select Language',
                            role: 'listbox',
                        }}>
                        {props.CountryLaguage.map((option: any, index: any) => (
                            <MenuItem
                                key={option.code}
                                onClick={(event) => props.handleMenuItemClick(option.laguage)}>
                                <Box className="languageBox">
                                    <img width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt={option.code} />
                                    <Typography>{option.label}</Typography>
                                </Box>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Drawer>
        </React.Fragment>
    );
}
