import {HomeModel} from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialHomeState:HomeModel={
    status: 0,
    message: '',
    doctor_list: [],
    visit_center: [],
    our_services: [],
    event_data: [],
    home_page_video: [],
    news_partner: [],
    our_care_service: [],
    product_list: [],
    cam_doctor: [],
    award_data: [],
    metadatas: []
}

const homeSlice=createSlice({
    name:'home',
    initialState:initialHomeState,
    reducers:{
        setHomeData(state,action:PayloadAction<HomeModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.doctor_list = action.payload?.doctor_list;
            state.cam_doctor = action.payload?.cam_doctor;
            state.visit_center = action.payload?.visit_center;
            state.our_services = action.payload?.our_services;
            state.event_data = action.payload?.event_data;
            state.home_page_video = action.payload?.home_page_video;
            state.news_partner = action.payload?.news_partner;
            state.our_care_service = action.payload?.our_care_service;
            state.metadatas = action.payload?.metadatas;
            state.product_list = action.payload?.product_list;
            state.award_data = action.payload?.award_data;
        }
    }
})
export default homeSlice;