
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitiateRazorPayModel} from "../../models/product-models";

const initialStoryState: InitiateRazorPayModel = {
    status: 0,
    message: '',
    razorpay_config: [],
    data: {
        id: "",
        entity: "",
        amount:0,
        amount_paid:0,
        amount_due:0,
        currency: '',
        receipt:''
    },
}

const initiateRazorPaySlice = createSlice({
    name: 'initiateRazorPay',
    initialState: initialStoryState,
    reducers: {
        setInitiateRazorPayCart(state, action: PayloadAction<InitiateRazorPayModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.razorpay_config = action.payload?.razorpay_config;
            state.data = action.payload?.data;
        }
    }
})
export default initiateRazorPaySlice;