
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GETAddressModel, ProductAddressModel } from "../../models/product-models";

const initialStoryState: GETAddressModel = {
    status: 0,
    message: '',
    data: [],
}

const getAddressSlice = createSlice({
    name: 'editAddress',
    initialState: initialStoryState,
    reducers: {
        setGetAddress(state, action: PayloadAction<GETAddressModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default getAddressSlice;