import React, { useState, useEffect } from "react";
import "./aboutUsPage.css";
import { Box, } from "@mui/material";
import Header from "../../components/Common-Component/Header/header";
import HowitStarted from "../../components/AboutUs-Components/HowitStarted/howitStarted";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";
import { fetchAboutData } from '../../store/aboutUs/about-actions';
import AboutusHead from "../../components/AboutUs-Components/AboutHead/aboutUsHead";
import { fetchFeedback } from '../../store/home/home-actions';
import Seo from "../../components/Common-Component/SEO/seo";
import LoveHeals from "../../components/AboutUs-Components/LoveHeals/loveHeals";
import Advisory from "../../components/AboutUs-Components/Advisory/advisory";
import AwardsAndRecognition from "../../components/AboutUs-Components/AwardsandRecognition/awards";
import MostSearched from "../../components/Common-Component/MostSearched/mostSearched";
import BookConsultation from "../../components/Common-Component/BookConsultation/bookConsultation";
import Footer from "../../components/Common-Component/Footer/footer";
import FeedbackCards from "../../components/Common-Component/FeedBackCards/feedbackCards";

interface GlobalTranslat {
    translation: any
    // setLanguage: any
}
export default function AboutUs({ translation }: GlobalTranslat) {
    const dispatch = useAppDispatch();
    const allAboutData = useAppSelector(state => state?.about);
    const feedbackData = useAppSelector(state => state?.feedbackDetail);
    const [page, setPage] = useState(1);
    const [feedback, setFeedback] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    //NOTE: Life cycle Methods
    useEffect(() => {
        dispatch(fetchAboutData());
        setLoading(true);
    }, []);
    useEffect(() => {
        dispatch(fetchFeedback(page))
    }, [page]);
    useEffect(() => {
        const filteredData = feedbackData?.reviews_data.filter((value, i, arr) => { return (arr.findIndex((v) => v.id === value.id) === i) });
        setFeedback(filteredData);
    }, [feedbackData]);

    //NOTE: Custom Methods
    const onLoadMore = () => {
        setPage(page + 1);
    }

    //NOTE: UI Implementations
    return (
        <Box className="aboutus-Header">
            <Seo translation={translation} metaData={allAboutData.metadatas} />
            <Box className="TeamofCoaches-Top">
                <Box className="aboutus-Header">
                    <Header translation={translation} darkTheme={true} />
                </Box>
                <AboutusHead loading={loading} translation={translation} Video={allAboutData?.home_page_video} />
            </Box>
            <HowitStarted translation={translation} />
            <Advisory translation={translation} doctorsList={allAboutData.advisory_data} />
            <LoveHeals translation={translation} />
            <AwardsAndRecognition translation={translation} awardsList={allAboutData?.award_recognition} />
            <FeedbackCards translation={translation} Reviews={feedback} onLoadMore={onLoadMore} isLoadMore={feedbackData?.isLoadMore} />
            <BookConsultation translation={translation} />
            <Box className="AboutFooter">
                <Footer translation={translation} />
                <MostSearched translation={translation} />
                <br />
            </Box>
        </Box>
    );
}
