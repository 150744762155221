import React, { memo } from "react";
import { Box, Button, Divider, IconButton, Link, Typography, useMediaQuery } from "@mui/material";
import "./footer.css";
import AppImages from "../../../images/AppImages";
import { googlePlay, appStore, twitter, instagram, linkedin, youtube, facebook } from '../../../constants/AppConstants'
import MobileFooter from "../mobileFooter/mobileFooter";
import { aboutPage, blogListPage, doctorListPage, doctorTalkPage, privacyPolicyPage, queriesPage, storiesPage, termsConditionPage, zioperPage } from "../../../values/paths";

interface Translate {
    translation: any
}
function Footer({ translation }: Translate) {
    const mobileView = useMediaQuery("(max-width:815px)");
    return (
        <>
            {mobileView ? <MobileFooter translation={translation} />
                : <Box className="OfficeLocation-box">
                    <img src={AppImages.FooterLogo} alt={translation?.ZenOcoio} className="Footer-Logo" />
                    <Box className="locaions-btnsBox">
                        <Box className="Leftside-content-box">
                            <Box className="CareerRelated-numbers-box">
                                <Box className="For-career-career-related">
                                    {translation?.ForCancerCareRelated}<br />
                                    <span className="Number-Text">
                                        {translation?.PhoneNumber}
                                    </span>
                                    <Typography className="Carezenoncoio">{translation?.CancerCarezenocoio}</Typography>
                                </Box>
                                <Box className="For-career-career-related">
                                    {translation?.ForCareerRelated}<br />
                                    <span className="Number-Text">{translation?.CareerNo}</span>
                                    <Typography className="Carezenoncoio">{translation?.careerzenoncoio}</Typography>
                                </Box></Box>
                            <Box className="copyRight-box">
                                <Typography className="copyRight-txt">{translation?.CopyrightTxt}</Typography>
                                <Box className="Privacy-Terms">
                                    <Button disableRipple className="CareToFaqs-btn" href={termsConditionPage} target="_blank">
                                        <Typography className="Privacy-Termstxt" >{translation?.TermsConditions}</Typography>
                                    </Button>
                                    <Divider orientation="vertical" className="Privacy-TermsDvd" variant="middle" />
                                    <Button disableRipple className="CareToFaqs-btn" href={privacyPolicyPage} target="_blank">
                                        <Typography className="Privacy-Termstxt">{translation?.PrivacyPolicy}</Typography>
                                    </Button>
                                    <Divider orientation="vertical" className="Privacy-TermsDvd" variant="middle" />
                                    <Button disableRipple className="CareToFaqs-btn" href="/sitemap.xml" target="_blank">
                                        <Typography className="Privacy-Termstxt">{translation?.Sitemap}</Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="Rightside-content-box">
                            <Box className="FlexBox">
                                <Box className="Rowbtn-box">
                                    <Button disableRipple className="CareToFaqs-btn" href={doctorListPage} >
                                        <Typography className="CareToFaqs-Text">• {translation?.Doctors}</Typography>
                                    </Button>
                                    <Button disableRipple className="CareToFaqs-btn" href={aboutPage}>
                                        <Typography className="CareToFaqs-Text">•  {translation?.About}</Typography>
                                    </Button>
                                    <Button disableRipple className="CareToFaqs-btn" href={queriesPage}>
                                        <Typography className="CareToFaqs-Text">•  {translation?.FAQs}</Typography>
                                    </Button>
                                    <Button disableRipple className="Dr-btn" href={doctorTalkPage}>
                                        <Typography className="CareToFaqs-Text" >•  {translation?.DoctorsInterview}</Typography>
                                    </Button>
                                </Box>
                                <Box className="Rowbtn-box">
                                    <Button disableRipple className="CareToFaqs-btn" href={blogListPage}>
                                        <Typography className="CareToFaqs-Text">• {translation?.Blogs}</Typography>
                                    </Button>
                                    <Link target="_blank" rel="noreferrer" className="CareToFaqs-Link" href="https://www.naukri.com/zenheal-wellness-jobs-careers-5269768">
                                        <Typography className="CareToFaqs-Text">• {translation?.Career}</Typography>
                                    </Link>
                                    <Link className="CareToFaqs-Link" href={zioperPage}>
                                        <Typography className="CareToFaqs-Text">•  {translation?.Generatereport}</Typography>
                                    </Link>
                                    <Button disableRipple className="CareToFaqs-btn" href={storiesPage}>
                                        <Typography className="CareToFaqs-Text" >• {translation?.Stories}</Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <Box className="Footer-AppPlay-store">
                                <a href={googlePlay} target="_blank" rel="noreferrer" className="Footer-PlayStore-btn">
                                    <img src={AppImages.GooglePlaystore} alt={translation?.Playstore} className="Footer-Store-btns-box" /></a>
                                <a href={appStore} target="_blank" rel="noreferrer" className="Footer-AppStore-btn">
                                    <img src={AppImages.AppStore} alt={translation?.Appstore} className="Footer-Store-btns-box2" /></a>
                            </Box>
                            <Box className="Ngo_SocialMedia-Box">
                                <Box className="SocialMedia_Main-Box">
                                    <Typography className="SocialM_heading-txt">{translation?.StayConnected}</Typography>
                                    <Box className="SocialMedia-Box">
                                        <a href={youtube} target="_blank" rel="preload noreferrer">
                                            <IconButton disableRipple>
                                                <img src={AppImages.YouTube} alt="Youtube" className="Youtube-iconSize" />
                                            </IconButton></a>
                                        <a href={facebook} target="_blank" rel="preload noreferrer">
                                            <IconButton disableRipple>
                                                <img src={AppImages.FaceBookIcon} alt="FaceBook" className="Facebook-iconSize1" />
                                            </IconButton></a>
                                        <a href={instagram} target="_blank" rel="preload noreferrer">
                                            <IconButton disableRipple>
                                                <img src={AppImages.InstaIcon} alt="Instagram" className="Insta-iconSize" />
                                            </IconButton></a>
                                        <a href={linkedin} target="_blank" rel="preload noreferrer">
                                            <IconButton disableRipple>
                                                <img src={AppImages.LinkedinIcon} alt="LinkedIn" className="Insta-iconSize" />
                                            </IconButton></a>
                                        <a href={twitter} target="_blank" rel="preload noreferrer">
                                            <IconButton disableRipple>
                                                <img src={AppImages.TwitterIcon} alt="twitter" className="Youtube-iconSize" />
                                            </IconButton></a>
                                    </Box></Box>
                                <Box className="NGO-Box">
                                    <Typography className="SocialM_heading-txt">{translation?.NGO}</Typography>
                                    <Box className="SocialMedia-Box">
                                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                                            <IconButton disableRipple>
                                                <img src={AppImages.LoveHeals} alt="LoveHealsCancer" className="LoveHeals-iconSize" />
                                            </IconButton></a>
                                    </Box></Box></Box>
                        </Box></Box></Box>}
        </>);
}
export default memo(Footer);
