import React, { useEffect } from "react";
import NotFound from "../../components/notFoundPage-component/notFound";
import Seo from "../../components/Common-Component/SEO/seo";
import errorTags from "./erro404.json";
import { useAppDispatch, useAppSelector, } from "../../hooks/redux-hooks";

interface NotFoundInfo {
    setNotFound: any
}
export default function NotFoundPage({ setNotFound }: NotFoundInfo) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        setNotFound(true);
    }, []);



    return (
        <>
            <Seo metaData={errorTags.errorData} />
            <NotFound />
        </>
    )
}