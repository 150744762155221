
import Api from './Api';
import { DOCTOR_DETAIL_API, DOCTOR_SCHEDULE_API, DOCTOR_FILTER_LIST_API, THANKYOU_API, GETOTP, CONFIRMOTP, INITIATEPAY, USER_DEATIL, CUSTOM_LEADS, CREATE_APPOINTMENT, CANCER_FORM_LIST, UPDATE_USER_DETAIL } from '../constants/ApiName'
import Api2 from './Api2';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const tokenConfig = {    
    headers: {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
};

const tokenConfigCall = {    
    headers: {
        'Cookie': 'ci_session=g1hsakd4q70vuad32jgir4orfovjp83g',
        'Content-Type': 'text/plain'
    }
};

const config = {
    headers: {}
};

export default {
    async getDoctortInfo(DATA: any) {
        var response = await Api().post(DOCTOR_DETAIL_API, DATA ,config);
        console.log("Doctor Detail Response----", response)
        return response.data;
    },
    async getOTP(data: any) {
        var response = await Api2().post(GETOTP, data, config);
        console.log("OTP Response----", response)
        return response.data;
    },
    async confirmOTP(data: any) {
        var response = await Api2().post(CONFIRMOTP, data, config);
        console.log("OTP CONFIRM Response----", response)
        return response;
    },
    async initiatePayment(data: any, token?:any) {  
        const tokenConfigPayment = {    
            headers: {
                'Authorization': 'Bearer ' + cookies.get('token') || token
            }
        };
        var response = await Api2().post(INITIATEPAY, data, tokenConfigPayment);
        console.log("INITIATEPAY Response----", response)
        return response.data;
    },
    async userDeatils(data: any) {                
        var response = await Api().post(USER_DEATIL, data, config);
        console.log("payment user detail Response----", response)
        return response.data;
    },
    async customLeadsData(data: any) {
        var response = await Api().get(CUSTOM_LEADS + data.phone + '&name=' + data.name + '&scheduledAt=' + data.scheduledAt + '&call_mode='+ data.call_mode, config);
        console.log("Custom leads Response----", response)
        return response.data;
    },
    async fetchCancerFormList() {
        var response = await Api().get(CANCER_FORM_LIST, config);
        console.log("Cancer form list Response----", response)
        return response.data;
    },
    async updateUserDetail(data:any) {
        var response = await Api().put(UPDATE_USER_DETAIL,data, config);
        console.log("Update User Detail Response----", response)
        return response.data;
    },
    async getDoctortList(URL: any) {
        var response = await Api().get(URL, config);
        console.log("Doctor List Response----11111", response)
        return response.data;
    },
    async getDoctortSchedule(URL: any) {
        var response = await Api().get(URL, config);
        console.log("Doctor schedule Response----", response)
        return response.data;
    },
    async getDoctortFilterList() {
        var response = await Api().get(DOCTOR_FILTER_LIST_API, config);
        console.log("Doctor filter List Response----", response)
        return response.data;
    },
    async getDoctortThankYouPage() {
        var response = await Api().get(THANKYOU_API, config);
        console.log("Doctor Thank you List Response----", response)
        return response.data;
    },

    async createAppointmentData(data:any,token?: any) {
        const tokenConfigPayment = {    
            headers: {
                'Authorization': 'Bearer ' + cookies.get('token') || token
            }
        };
        var response = await Api2().post(CREATE_APPOINTMENT, data , tokenConfigPayment);
        console.log("Response createAppointmentData----", response.data)
        return response.data;
    },
}