import { INITIALPAYModel } from "../../models/doctor-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialPayState: INITIALPAYModel = {
        status:0,
        data: {
            amount: 0,
            amount_due: 0,
            amount_paid: 0,
            attempts: 0,
            created_at: 0,
            currency: "",
            entity: "",
            id: "",
            notes: [],
            offer_id: null,
            receipt: "",
            status: ""
        },
        razorpay_config: []
}

const confirmOtpSlice = createSlice({
    name: 'INITIALPAY',
    initialState: initialPayState,
    reducers: {
        setInitaialPayData(state, action: PayloadAction<INITIALPAYModel>) {
            state.status = action.payload?.status;
            state.data = action.payload?.data;
            state.razorpay_config = action.payload?.razorpay_config;
        }
    }
})
export default confirmOtpSlice;