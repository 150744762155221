
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeleteCartModel} from "../../models/product-models";

const initialStoryState: DeleteCartModel = {
    status: 0,
    message: '',
    data: [],
}

const deleteCartSlice = createSlice({
    name: 'deleteCart',
    initialState: initialStoryState,
    reducers: {
        setDeleteCart(state, action: PayloadAction<DeleteCartModel>) {           
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default deleteCartSlice;