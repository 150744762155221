import doctorSlice from './doctor-slice'
import doctorListSlice from './docotorList-slice'
import doctorScheduleSlice from './doctorSchedule-slice'
import doctorFilterSlice from './doctorFilterList-slice'
import thankyouSlice from './thankyou-slice'
import { AnyAction } from '@reduxjs/toolkit'
import { ThunkAction } from '@reduxjs/toolkit'
import {RootState} from '../index'
import {DcotorModel, DcotorListModel, DcotorScheduleModel, DcotorFilterModel, ThankYourModel} from "../../models/doctor-models";
import DoctorService from '../../service/doctorService';
import { createOrderModel } from '../../models/product-models'
import appointmentSlice from './createAppointment-slice'

export const doctorActions=doctorSlice.actions
export const doctorListActions=doctorListSlice.actions
export const doctorScheduleActions=doctorScheduleSlice.actions
export const doctorFilterActions=doctorFilterSlice.actions
export const thankyouActions=thankyouSlice.actions
export const appointmentActions=appointmentSlice.actions

export const fetchDoctortData=(DATA: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{  
                const response:DcotorModel=await DoctorService.getDoctortInfo(DATA);
                dispatch(doctorActions.setDoctorData(response))
    }
}

export const fetchDoctorListData=(URL: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{  
                const response:DcotorListModel=await DoctorService.getDoctortList(URL);
                dispatch(doctorListActions.setDoctorListData(response))
    }
}

export const fetchDoctorScheduleData=(URL: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{  
                const response:DcotorScheduleModel=await DoctorService.getDoctortSchedule(URL);
                dispatch(doctorScheduleActions.setDoctorScheduleData(response))
    }
}

export const fetchDoctorFilterData=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{  
                const response:DcotorFilterModel=await DoctorService.getDoctortFilterList();
                dispatch(doctorFilterActions.setDoctorFilterData(response))
    }
}

export const fetchThankYouData=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{  
                const response:ThankYourModel=await DoctorService.getDoctortThankYouPage();
                dispatch(thankyouActions.setThankYouData(response))
    }
}

export const createAppointment=(data: any,token?: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState)=>{  
                const response:createOrderModel=await DoctorService.createAppointmentData(data,token);
                dispatch(appointmentActions.createAppointment(response))
    }
}