import {GlobalSearchModel } from "../../models/home-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialGlobalSearchState:GlobalSearchModel={
    status: 0,
    message: '',
    data: {
        peopleData: [],
        postData : [],
        articleData : [],
        groupData : [],
        recipeData : [],
        wellnessData : [],
        productData : [],
        doctor_data : [],
        ask_doctor_data : [],
        Blog_data : [],
        events : [],
        news : [],
        eBook : [],
    },
}

const globalSearchSlice=createSlice({
    name:'globalSearch',
    initialState:initialGlobalSearchState,
    reducers:{
        setGlobalSearchData(state,action:PayloadAction<GlobalSearchModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.data = action.payload?.data;
        }
    }
})
export default globalSearchSlice;