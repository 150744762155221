import {AboutModel} from "../../models/about-models";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialAboutState:AboutModel={
    status: 0,
    message: '',
    home_page_video: [],
    award_recognition: [],
    advisory_data: [],
    metadatas: []
}

const aboutSlice=createSlice({
    name:'home',
    initialState:initialAboutState,
    reducers:{
        setAboutData(state,action:PayloadAction<AboutModel>){
            state.status = action.payload?.status;
            state.message = action.payload?.message;
            state.home_page_video = action.payload?.home_page_video;
            state.award_recognition = action.payload?.award_recognition;
            state.advisory_data = action.payload?.advisory_data;
            state.metadatas = action.payload?.metadatas;
        }
    }
})
export default aboutSlice;