import React from "react";
import "./downloadAppPage.css";
import DownloadAppComponent from "../../components/downloadAppPage/downloadApp";

interface Translate {
    translation: any
}

export default function DownloadAppPage({ translation }: Translate) {
    return (
        <React.Fragment>
            <DownloadAppComponent translation={translation} />
        </React.Fragment>
    );
}