import React from "react";
import { Box, Typography, Link } from "@mui/material";
import "./zioperInnerHead.css";
import TopHeading from "../../Common-Component/TopHeadingComponent/topHeading";
import AppImages from "../../../images/AppImages";


export default function ZioperInnerHead(prop: { translation: any, url: any }) {
    return (
        <TopHeading>
            <Box className="ZioperInner_main-Box">
                <img src={AppImages.AiDoctor} className="aiDrHead-img" alt="" />
                <Box className="ZioperInner-Box">
                    <Typography className="innerPageHead-txt">
                        {prop?.translation?.FreeTreatmentreport}
                    </Typography>
                    <Typography className="innerPageSub-txt">
                        {prop?.translation?.Detailedreport}
                    </Typography>
                    <Link href={prop?.url} target="_blank" rel="noreferrer" className="innerPageReport-btn">
                        <Typography className="innerPageReport-txt">
                            {prop?.translation?.ViewReport}
                        </Typography>
                    </Link>
                </Box>
                <img src={AppImages.reportIcon} className="TechnicalHead-img" alt="" />
            </Box>
        </TopHeading>
    )
}