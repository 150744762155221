import React, { useEffect, useState, memo } from "react";
import "./myReport.css";
import { Box, Typography, Button, useMediaQuery, Alert } from "@mui/material";
import AppImages from "../../../images/AppImages";
import { treatmentTypeModel } from "../../../models/faq-models";
import Cookies from "universal-cookie";
import TreatAndOtp from "../../Common-Component/Common-Modals/TreatModelwithOTP/TreatOtp";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { saveZioPar, setZioPar } from "../../../store/home/home-actions";
import ReportAutofilled from "../../Common-Component/reportAutoFilld/reportAutofilled";
import { ReportAutoFilled01 } from "../../Common-Component/reportAutoFilld/reportAutofilled";
import { useNavigate } from "react-router";
import { zioperInnerPage, zioperPage } from "../../../values/paths";

interface ApproachData {
    cancer_stage?: Array<treatmentTypeModel>
    cancer_type?: Array<treatmentTypeModel>
    disease?: Array<treatmentTypeModel>
    performance?: Array<treatmentTypeModel>
    treatment?: Array<treatmentTypeModel>
    symptoms?: Array<treatmentTypeModel>
    zioparPage?: boolean
    translation: any
    loading?: any
}

export default function MyReport({ loading, translation, cancer_stage, cancer_type, disease, performance, treatment, symptoms, zioparPage }: ApproachData) {
    const [userData, setUserData] = useState({
        cancerType: "",
        cancerStage: "",
        disease_type: [] as any,
        performance_: "",
        treatment_type: [] as any,
        symptoms_: [] as any
    });
    const mobileView = useMediaQuery("(max-width:600px)");
    const lessThan1000 = useMediaQuery("(max-width:1215px)");
    const dispatch = useAppDispatch();
    const GetZioParData = useAppSelector(state => state?.getZioPar);
    const [showError, setShowError] = useState(false);
    const [url, setUrl] = useState("");
    const cookies = new Cookies();
    const [modal, setModal] = useState(false);
    const [showAutoFilled, setShowAutoFilled] = useState(false);
    const handleChange = (name: any) => (e: any) => { setUserData({ ...userData, [name]: e.target.value }) }
    const Navigate = useNavigate()
    function hadleCloseModal() { setModal(false); }
    function generateReport() {
        if (cookies.get('token') === undefined) {
            setModal(true);
        } else {
            if (userData?.cancerType !== "" && userData?.cancerStage !== "" && userData?.performance_ !== "" &&
                userData?.disease_type?.length > 0 && userData?.treatment_type?.length > 0 && userData?.symptoms_?.length > 0) {
                let treatmentId = ""
                let diseaseId = ""
                let symptomsId = ""
                treatmentId = userData?.treatment_type?.map((items: any) => items?.id)
                diseaseId = userData?.disease_type?.map((items: any) => items?.id)
                symptomsId = userData?.symptoms_?.map((items: any) => items?.id)
                setUrl(`https://zenonco.io/view_report?cancer_type=${userData?.cancerType}&cancer_stage=${userData?.cancerStage}&performance_score=${userData?.performance_}&prior_treatment=${treatmentId}&other_disease=${diseaseId}&symptoms=${symptomsId}`)
                let data = {
                    url: url
                }
                let dataSet = {
                    cancer_type: userData?.cancerType,
                    cancer_stage: userData?.cancerStage,
                    performance_score: userData?.performance_,
                    prior_treatment: userData?.treatment_type?.map((items: any) => items?.id).join(','),
                    other_disease: userData?.disease_type?.map((items: any) => items?.id).join(','),
                    symptoms: userData?.symptoms_?.map((items: any) => items?.id).join(','),
                }
                dispatch(saveZioPar(data));
                dispatch(setZioPar(dataSet));
            } else {
                setShowError(true);
            }
        }
    }
    useEffect(() => {
        if (GetZioParData.status === 1) {
            Navigate(zioperInnerPage, { state: { GetZioParData, url } });
            window?.scrollTo(0, 0);
        }
    }, [GetZioParData]);
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setShowAutoFilled(true)
            }, 2000);
        } else if (loading && zioperPage) {
            setTimeout(() => {
                setShowAutoFilled(true)
            }, 100);
        }
    }, [loading]);
    return (
        <Box className={zioparPage ? "journeyMain-Box1" : "journeyMain-Box"}>
            <Box className={zioparPage ? "points_Info-Box1" : "points_Info-Box"}>
                {mobileView && !zioparPage ? null : <img src={!zioparPage ? AppImages?.reportMobileImg : AppImages?.AiDoctor} className={zioparPage ? "aiDR-img1" : "aiDR-img"} alt="" />}
                <Box className={zioparPage ? "cancerReport-Box1" : "cancerReport-Box"} >
                    {<Box className="ReportHeading-Box">
                        <Typography className="CancerReport-txt">
                            {translation?.DownloadMyReport}
                        </Typography>
                        <Typography className="path_to_treatment-txt">
                            {translation?.Learnaboutmedical}
                        </Typography></Box>}
                    <Box className="reportInputs_main-Box" >
                        <Box className={zioparPage ? "reportInputs-Box1" : "reportInputs-Box"}>
                            <Box className="report-errorBox">
                                {showAutoFilled && <ReportAutoFilled01 label={translation?.Cancertype} lengthType={userData?.cancerType} typeList={cancer_type} handleChange={handleChange("cancerType")} />}
                                {showError && userData?.cancerType === "" &&
                                    <><br /><Alert className="report-errorBox" severity="error">{translation?.FillCancertype}</Alert></>}
                            </Box>
                            <Box className="report-errorBox">
                                {showAutoFilled && <ReportAutoFilled01 label={translation?.CancerStage} lengthType={userData?.cancerStage} typeList={cancer_stage} handleChange={handleChange("cancerStage")} />}
                                {(showError && userData?.cancerStage === "") &&
                                    <><br /><Alert severity="error">{translation?.FillCancerstage}</Alert></>}
                            </Box>
                            <Box className="report-errorBox">
                                {showAutoFilled && <ReportAutoFilled01 label={translation?.PerformanceScore} lengthType={userData?.performance_} typeList={performance} handleChange={handleChange("performance_")} />}
                                {showError && userData?.performance_ === "" &&
                                    <><br /><Alert severity="error"> {translation?.Fillperformance}</Alert></>}
                            </Box>
                            <Box className="report-errorBox" style={{ marginTop: lessThan1000 ? "5px" : "" }}>
                                {showAutoFilled && <ReportAutofilled translation={translation} label={translation?.Treatmenttype} userData={userData} setUserData={setUserData} valueType={"treatment_type"} lengthType={userData?.treatment_type} typeList={treatment} />}
                                {showError && userData?.treatment_type?.length < 1 && <><br /> <Alert severity="error">{translation?.FillCancertreatment}</Alert></>}
                            </Box>
                            <Box className="report-errorBox">
                                {showAutoFilled && <ReportAutofilled translation={translation} label={translation?.Otherdiseases} userData={userData} setUserData={setUserData} valueType={"disease_type"} lengthType={userData?.disease_type} typeList={disease} />}
                                {showError && userData?.disease_type?.length < 1 && <><br /><Alert severity="error" >{translation?.Filldisease}</Alert></>}
                            </Box>
                            <Box className="report-errorBox">
                                {showAutoFilled && <ReportAutofilled translation={translation} label={translation?.Symptomssideeffects} userData={userData} setUserData={setUserData} valueType={"symptoms_"} lengthType={userData?.symptoms_} typeList={symptoms} />}
                                {showError && userData?.symptoms_?.length < 1 && <><br /><Alert severity="error">{translation?.FillCancersymptoms}</Alert></>}
                            </Box></Box></Box>
                    <Button disableRipple className="my_plan-Btn" onClick={generateReport}>
                        <img src={AppImages.MyReport} alt="" />
                        <Typography className="my_plan-txt">
                            {translation?.Generatereport}
                        </Typography>
                    </Button></Box>
                {zioparPage && <img src={AppImages?.reportIcon} className="reportIcon-img" alt="" />}
            </Box>
            {loading && <TreatAndOtp translation={translation} setModal={setModal} modal={modal} handleCloseModal={hadleCloseModal} />}
        </Box>
    );
}
